import image from "./heading4/subheading 4-5/4-5_photobox-1-1.jpg";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";
import GalleryComponent from "../../../Components/Gallery/Gallery";

import img1 from "./heading4/subheading 4-5/4-5_photobox-1-1.jpg";
import img2 from "./heading4/subheading 4-5/4-5_photobox-1-2.jpg";
import img3 from "./heading4/subheading 4-5/4-5_photobox-2-1.jpg";
import img4 from "./heading4/subheading 4-5/4-5_photobox-2-2.jpg";
import img5 from "./heading4/subheading 4-5/4-5_photobox-3.jpg";

const Preston_Monongye = () => {
  const title = "Preston Monongye";

  const details =
    `Another early modernist silversmith was Preston Monongye (1927–1991). Born in California to a Mission Indian mother and a Mexican father, he was adopted by a Hopi family and lived at Hotvela (Hotevilla), on Third Mesa, where he was initiated into Hopi society. He started out making overlay jewelry, having learned smithing from a relative, Gene Pooyouma. After a stint in the army, attendance at the Haskell Institute, and a career in law enforcement, Monongye returned to jewelry making in the 1960s. He abandoned overlay and began tufa casting, the technique for which he became world famous and is best remembered.

    Preston Monongye’s son, Jesse Monongya (Diné [Navajo]), is a world-famous jewelry artist, whose works made of gold and precious gemstones are in a category of their own.
`.split("\n");

  const imageDescription = "";

  const photos_one = [
    {
      title: `Cast-silver cuff bracelet with turquoise inlay, 1970
      Preston Monongye, Hopi/Mexican/Mission Indian`,
      src: img1,
      caption: `
      Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
      Gift of Tom Bahti, 1970
      W: 3.7 cm
      E-9377`,
    },
    {
      title: `Hallmark for cast-silver cuff bracelet with turquoise inlay, 1970
      Preston Monongye, Hopi/Mexican/Mission Indian`,
      src: img2,
      caption: `
      Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
      Gift of Tom Bahti, 1970
      W: 3.7 cm
      E-9377`,
    },
    {
      title: `Cast-silver and turquoise cuff bracelet, 1972
      Preston Monongye, Hopi/Mexican/Mission Indian`,
      src: img3,
      caption: `
      Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
      ASM purchase from Tom Bahti Indian Arts
      Arizona Archaeological and Historical Society Fund, 1972
      W: 3.5 cm
      E-9997`,
    },
    {
      title: `Hallmark for cast-silver and turquoise cuff bracelet, 1972
      Preston Monongye, Hopi/Mexican/Mission Indian`,
      src: img4,
      caption: `
      Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
      ASM purchase from Tom Bahti Indian Arts
      Arizona Archaeological and Historical Society Fund, 1972
      W: 3.5 cm
      E-9997`,
    },
    {
      title: `Preston Monongye`,
      src: img5,
      caption: `
      Unknown photographer, 1975`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={image}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Preston_Monongye;
