import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-1/subheading 2-1-1/2-1a_photobox-1.jpg";
import img2 from "./heading2/subheading 2-1/subheading 2-1-1/2-1a_photobox-2.jpg";
import img3 from "./heading2/subheading 2-1/subheading 2-1-1/2-1a_photobox-3.jpg";
import img4 from "./heading2/subheading 2-1/subheading 2-1-1/2-1a_photobox-4.jpg";
import img5 from "./heading2/subheading 2-1/subheading 2-1-1/2-1a_photobox-5.jpg";
import img6 from "./heading2/subheading 2-1/subheading 2-1-1/2-1a_photobox-6.jpg";

const Meaning_of_Turquoise = () => {
  const title = "Meaning of Turquoise";

  const details_one =
    `According to Diné (Navajo) reckoning, turquoise (doo tl' izh ii) is connected to the south, along with the sacred mountain Mount Taylor, home of Navajo deities Turquoise Boy and Turquoise Girl (see https://navajopeople.org/blog/the-navajo-four-sacred-colors/; Whitely 2012:148). In one account, the sun is carried daily across the sky by a Navajo boy on the back of a turquoise stallion.

    A mountain of turquoise rises in the sky-world of A:shiwi (Zuni) cosmology; its reflection gives the sky its color.
`.split("\n");

  const quote_one =
    `A lot of this [turquoise] symbolism takes a different level [of knowledge]...and as you go down further, within the layers of Zuni knowledge and Zuni culture, it represents more sensitive or esoteric information that’s meant for maybe medicinal groups or priesthoods.
`.split("\n");

  const quote_two =
    `With all the turquoise [worn during ceremonies]...that’s water, and they’re dancing with that, so water has to come meet water. So it rains....
`.split("\n");

  const quote_three =
    `Of course, being farmers, we are dependent on the moisture that comes from the various elements....So there’s a metaphor behind turquoise and blue-green materials....It’s a visual prayer in a sense...[for] bringing moisture to our dry environment here in the Southwest.
`.split("\n");

  const quote_four =
    `For Hopis, turquoise (tsorposi) represents the southwest, as do the mountain bluebird, blue clouds, and a host of other plants and animals.
`.split("\n");

  const details_two = `
  The turquoise stone and its color variations can reflect gender differences. For Hopis, turquoise represents males, whereas the squash blossom (and Spondylus shell) signifies females.
  
  The O’odham tell a story related to the Casa Grande, a Hohokam “great house” near the Gila River. Turquoise was found where a blue-tailed lizard had entered a hole in the ground. Chief Morning Green distributed the turquoise, and Sun learned about it and was covetous. He sent Parrot to obtain it. Parrot was fed turquoise "seeds,” which he regurgitated upon returning to the Sun. The Sun then shared the turquoise with people beyond the eastern mountains.
`.split("\n");

  const photos_one = [
    {
      title: `Turquoise Horse, c. 1930
      Olive Rush, American`,
      src: img1,
      caption: `
        Postcard from the Wheelwright Museum of the American Indian, c. 1970`,
    },
    {
      title: `A Zuni Doorway, c. 1903`,
      src: img2,
      caption: `
      Edward S. Curtis, photographer
      The North American Indian, no. 812, v. 17, p. 104
      Courtesy Library of Congress, LC-USZ62-102184.
      
      "We leave the turquoise offerings in our structures to strengthen the home, to strengthen the family unity...to bring prosperity, wealth, and longevity." - Ronnie Cachini, A:shiwi (Zuni; Hedquist 2017:238)`,
    },
    {
      title: `Tawa (Sun) katsina doll, c. 1990
      Loren Honyouti, Hopi`,
      src: img3,
      caption: `
        Paaqavi (Bacavi), Third Mesa, Hopi Indian Reservation, AZ
        Cottonwood, paint
        Gift of Howard and Elizabeth Marugg, 2008
        H: 32 cm
        2008-283-8.

        The katsina’s turquoise jewelry invites rain to grow crops, which sustains the Hopi way of life.`,
    },
    {
      title: `Mosairu (Bison) katsina doll, c. 1980
      Earl Yowytewa (1923–2006), Hopi`,
      src: img4,
      caption: `
        Third Mesa, Hopi Indian Reservation, AZ
        Cottonwood root, fur, leather, shell, string
        Gift of Howard and Elizabeth Marugg, 2008
        H: 32 cm
        2008-283-6.

        The katsina’s turquoise jewelry invites rain to grow crops, which sustains the Hopi way of life.`,
    },
    {
      title: `The Hopi Maiden, c. 1905`,
      src: img5,
      caption: `
        Edward S. Curtis, photographer
        The North American Indian, v. 12, pl. 412
        Courtesy Library of Congress, LC-USZC4-8848.
        
        This hairstyle, worn by unmarried Hopi women, represents squash blossoms. Along with spondylus shell, they are the female counterpart to the male blue of turquoise.`,
    },
    {
      title: `Casa Grande, AZ AA:2:14 (ASM), Coolidge, AZ`,
      src: img6,
      caption: `
        Photographer unknown, Wetherill Collection, c. 1900
        ASM 2004-1447-0008.
        Hohokam big house where an O’odham turquoise story is situated.`,
    },
  ];

  return (
    <Content
      title={title}
      clickableImage={img1}
      specialRender={
        <>
          {details_one.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <div className="quote">
            {quote_one.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <br></br>
            <span style={{ fontStyle: "normal" }}>
              (Curtis Quam, A:shiwi (Zuni; Hedquist 2017:235))
            </span>
            <br></br>
            <br></br>
            {quote_two.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <br></br>
            <span style={{ fontStyle: "normal" }}>
              (Jerry Honawa, Hopi (Hedquist 2017:254))
            </span>
            <br></br>
            <br></br>
            {quote_three.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <br></br>
            <span style={{ fontStyle: "normal" }}>
              (Lyle Balenquah, Hopi (Hedquist 2017:253))
            </span>
            <br></br>
            <br></br>
            {quote_four.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <br></br>
            <span style={{ fontStyle: "normal" }}>(Whiteley 2012: 147)</span>
          </div>
          {details_two.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </>
      }
      photos={photos_one}
    />
  );
};

export default Meaning_of_Turquoise;
