import React, { useState, useEffect } from "react";
import "./Homepage.css";
import { motion } from "framer-motion";
import Preview from "../../Components/Preview/Preview";
import InfoIcon from "@mui/icons-material/Info";

import img1 from "./Images/2000-186-004_fr_xl.jpg";
import img2 from "./Images/2-2_clickable.jpg";
import img3 from "./Images/heading 3 clickable.jpg";
import img4 from "./Images/heading 4 clickable.jpg";
import img5 from "./Images/5_photobox-07-1.jpg";

const Homepage = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const showTooltip = () => setIsTooltipVisible(true);
  const hideTooltip = () => setIsTooltipVisible(false);

  const previews1 = [
    { url: "/Wearing-Jewelry", text: "Wearing Jewelry", image: img1 },
    { url: "/Materials", text: "Materials", image: img2 },
    {
      url: "/Developments-1920s-1950s",
      text: "Developments 1920s-1950s",
      image: img3,
    },
  ];
  const previews2 = [
    {
      url: "/Early-Modernists",
      text: "Early Modernists",
      image: img4,
    },
    {
      url: "/Modern-Southwestern-Jewelry",
      text: "Modern Southwestern Jewelry",
      image: img5,
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.1 }}
      className="home-page-container">
      <div className="home-page-header">
        <div className="home-header-text">
          Ancient | Modern
          <div style={{ fontSize: "1rem" }}>
            Continuity and Innovation in Southwest Native Jewelry
          </div>
        </div>
      </div>
      <div className="home-text-container">
        <div className="home-text">
          <div style={{ position: "relative", display: "inline-block" }}>
            <h3 style={{ fontWeight: "bold" }}>
              Native American Jewelry in the U.S. Southwest
              <sup
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
                style={{ cursor: "pointer" }}>
                <InfoIcon style={{ fontSize: "16px" }} />
              </sup>
              : A Study in Continuity and Innovation
            </h3>
            {isTooltipVisible && (
              <div
                style={{
                  position: "absolute",
                  bottom: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #d3d3d3",
                  padding: "5px",
                  zIndex: 1,
                }}>
                For the ancient jewelry, this includes Northwest Mexico.
              </div>
            )}
          </div>
          <br />
          <br />
          Nothing better captures the spirit of the southwestern United States
          than jewelry of shell, silver, and turquoise crafted by the region's
          Indigenous artisans. People from around the world appreciate its
          beauty and allure. For many Native Americans it also holds deep,
          spiritual significance.
          <br />
          <br />
          The story of this cultural art form is one of innovation and
          continuity. ASM's outstanding collections, along with comments by
          contemporary creators, illustrate how the region's Indigenous peoples
          over the millennia have adorned themselves, engaged in trade, and
          expressed their identities, cultural beliefs, and values.
          <br />
          <br />
          <div style={{ fontStyle: "italic" }}>
            Note: None of the jewelry in this exhibit is known to have come from
            a funerary context.
          </div>
          <br />
          <br />
        </div>
      </div>
      <div>
        <div className="exhibit-header">
          <h2>Exhibit Sections</h2>
          <hr />
        </div>
        <div className="previewContainer">
          {previews1.map((preview, i) => (
            <Preview
              key={i}
              image={preview.image}
              url={preview.url}
              text={preview.text}
              customSize={"300px"}
            />
          ))}
        </div>
        <div className="previewContainer">
          {previews2.map((preview, i) => (
            <Preview
              key={i}
              image={preview.image}
              url={preview.url}
              text={preview.text}
              customSize={"300px"}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Homepage;
