import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-3/2-3_photobox-1-1.jpg";
import img2 from "./heading2/subheading 2-3/2-3_photobox-1-2.jpg";
import img3 from "./heading2/subheading 2-3/2-3_photobox-2.jpg";
import img4 from "./heading2/subheading 2-3/2-3_photobox-3.jpg";
import img5 from "./heading2/subheading 2-3/2-3_photobox-4.jpg";
import img6 from "./heading2/subheading 2-3/2-3_photobox-5-1.jpg";
import img7 from "./heading2/subheading 2-3/2-3_photobox-5-2.jpg";
import img8 from "./heading2/subheading 2-3/2-3_photobox-5-3.jpg";
import img9 from "./heading2/subheading 2-3/2-3_photobox-6-1.jpg";
import img10 from "./heading2/subheading 2-3/2-3_photobox-6-2.jpg";
import img11 from "./heading2/subheading 2-3/2-3_photobox-6-3.jpeg";
import img12 from "./heading2/subheading 2-3/2-3_photobox-6-4.jpg";
import img13 from "./heading2/subheading 2-3/2-3_photobox-7-1.jpg";
import img14 from "./heading2/subheading 2-3/2-3_photobox-7-2.jpg";
import img15 from "./heading2/subheading 2-3/2-3_photobox-7-3.jpg";

const Stone = () => {
  const title = "Stones Ancient—Modern";

  const details =
    `Ancient indigenous lapidarists (stone cutters) produced beads from turquoise and other soft stones, including argillite (also called pipestone or claystone) and steatite (also known as soapstone). The beads were formed by roughing out the shapes, perforating them with hard stone drills, and abrading them on rough stone surfaces. This technique survived, with minor changes, into the twentieth century, when the use of electric tools facilitated the process.

Although turquoise was, from the outset, the material most frequently set into silver jewelry, early Diné (Navajo) smiths embellished their bracelets, rings, and other adornments with a wide range of materials, particularly when the favored blue-green stone was not available. Garnets, peridots, jet, and a host of other gemstones gathered locally were set into roughly formed bezels, according to early accounts. Few examples of antique silver jewelry set with these alternative materials have survived.

In the 1930s and 1940s, petrified wood, obtained from traders pre-cut and polished, came into common use. Since then, the palette of available materials has expanded to such a degree that there are now literally unlimited options available for artists, if they have access to the sources and the funds with which to purchase them.

The Romo Cache: Stone beads and copper bells
In 1949 while out hunting, Tucsonan Ray Romo discovered a cache of beads and copper bells on National Forest land in a rocky overhang near the summit of an isolated hill north of Romero Canyon, in the Santa Catalina Mountains north of Tucson (Haury and Gifford 1959). The cache was in a Hohokam Tanque Verde Red-on-brown jar covered by a similarly decorated bowl. The nearest settlements contemporary to the cache (c. 1100-1150 CE) were roughly a half mile away at the base of the hill. 

The cache contained approximately 100,000 steatite (also called soapstone), argillite (also called pipestone and claystone), and turquoise disk-shaped stone beads, a few shell beads, and twenty-five to thirty spherical copper bells. The bells were most likely imported from western Mexico.

Most of the beads exhibited some wear, indicating the contents were not simply the product of a single craftsperson but may have been a ritual offering or possibly the merchandise of a traveling trader who had hidden the material with the intention of returning. Based on modern efforts to replicate the technology to make these beads, it is estimated that there are roughly 880 hours of manual labor represented in the cache (Curcija 2018).

Curcija, Zachary S.
2018 Reevaluating the Prehistoric Southwestern Disc Bead Industry. Kiva 84(1):27-45.

Haury, Emil W., and Carol A. Gifford
1959 A Thirteenth Century "Strongbox." The Kiva 24(4):1-11.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Rincon Red-on-brown jar and bowl lid, C. 1100 – 1150 CE
Hohokam, Tucson Province`,
      src: img1,
      caption: `
      Terra cotta, mineral paint
Romo Site, Catalina Mountains, Coronado National Forest, Pima Co., AZ
Romo Transfer, 1953
H: 20.5 cm (jar) (A-9071, A-9072)`,
    },
    {
      title: `Sample of stone and shell disk beads, C. 1100 – 1150 CE
      Hohokam, Tucson Province`,
      src: img2,
      caption: `
      Argillite (also called pipestone and claystone), steatite (also called soapstone), turquoise
      Romo Site, Catalina Mountains, Coronado National Forest, Pima Co., AZ
Romo Transfer, 1953
Bead diameter: 0.3-0.4 cm (A-9075)`,
    },
    {
      title: `Argillite bead necklace, C. 450 – 1300 CE
      Ancestral Pueblo, Kayenta Province`,
      src: img3,
      caption: `
      Sun Priest House (Chilchintaboko), Nitsie Canyon, northern AZ
      Argillite (also called pipestone, claystone)
      University Expedition, 1915
      L: 86 cm (457-X)`,
    },
    {
      title: `Argillite, turquoise, and shell necklace, 1998
      Dan Lovato, Kewa (Santo Domingo) Pueblo`,
      src: img4,
      caption: `
      Kewa Pueblo, NM
      Turquoise, argillite (also called pipestone, claystone ), jet, shell
      Gift of Grace Niemcziek, 2000
      L: 27 cm (2000-91-44)`,
    },
    {
      title: `Silver bracelet Set with Petrified Wood, c. 1950
      Diné (Navajo)`,
      src: img5,
      caption: `
      Gift of Jo Ann Nelson, 1988
      L: 6.7 cm
      88-68-1`,
    },
    {
      title: `Silver necklace and pendant with Brazilian druzy stone, c. 2007
      Bruce Hodgins (1959-2018), Diné (Navajo)`,
      src: img6,
      caption: `
      ASM purchase, B. C. Waddell Memorial Purchase Award for Jewelry
      ASM Southwest Indian Art Fair, 2007
      L: 35 cm.
      2007-125-1`,
    },
    {
      title: `Hallmark for silver necklace and pendant with Brazilian druzy stone, c. 2007
      Bruce Hodgins (1959-2018), Diné (Navajo)`,
      src: img7,
      caption: `
      ASM purchase, B. C. Waddell Memorial Purchase Award for Jewelry
      ASM Southwest Indian Art Fair, 2007
      L: 35 cm.
      2007-125-1`,
    },
    {
      title: `Bruce Hodgins, Diné (Navajo), with his award-winning necklace, Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ`,
      src: img8,
      caption: `
      Jannelle Weakly, photographer, 2007`,
    },
    {
      title: `Silver and agate perfume bottle with stopper, 2014
      Starlie Polacca, Hopi (Colorado River Indian Tribes)`,
      src: img9,
      caption: `
      Parker, AZ
      Friends of the ASM Collections Hartman Lomawaima Memorial Purchase Award
      ASM Southwest Indian Art Fair, 2014
      L: bottle, 12.5 cm; stopper, 13.5 cm
      2014-147-1 a and b

      In addition to jewelry, Native silver artisans through time have produced vessels and other forms, from canteens and powder chargers to plates and bookends. The perfume bottle by Starlie Polacca (below) is a recent example.`
    },
    {
      title: `Silver and agate perfume bottle with stopper, 2014
      Starlie Polacca, Hopi (Colorado River Indian Tribes)`,
      src: img10,
      caption: `
      Parker, AZ
      Friends of the ASM Collections Hartman Lomawaima Memorial Purchase Award
      ASM Southwest Indian Art Fair, 2014
      L: bottle, 12.5 cm; stopper, 13.5 cm
      2014-147-1 a and b

      The individual depicted on this perfumier is Tom Polacca (1853-1911), the artist’s great grandfather who was a Hopi-Tewa and brother of the potter Nampeyo. Polacca was banished from his home when he converted to Mormonism, according to the artist.`,
    },
    {
      title: `Hallmark for silver and agate perfume bottle with stopper, 2014
      Starlie Polacca, Hopi (Colorado River Indian Tribes)`,
      src: img11,
      caption: `
      Parker, AZ
      Friends of the ASM Collections Hartman Lomawaima Memorial Purchase Award
      ASM Southwest Indian Art Fair, 2014
      L: bottle, 12.5 cm; stopper, 13.5 cm
      2014-147-1 a and b`,
    },
    {
      title: `Starlie Polacca, Hopi (Colorado River Indian Tribes), Southwest Indian Art Fair, Arizona State`,
      src: img12,
      caption: `
      Museum, Tucson, AZ
      Jannelle Weakly, photographer, 2014`,
    },
    {
      title: `Eye of the Pascola, silver and sugilite cuff, c. 2018
      Na Na Ping (Michael Garcia), Pascua Yaqui Tribe`,
      src: img13,
      caption: `
      Santa Fe, NM
      ASM purchase, Friends of the ASM Collections funds, 2018
      W: 4.3 cm
      2019-6-2

      Well-known jewelry artist Na Na Ping (Michael Garcia) was born in Guadalupe, a Yaqui village south of Phoenix, Arizona. He has lived for many years at Nambe Pueblo, having married into the community. His name, Na Na Ping, was given to him by one of his wife’s grandfathers. Na Na Ping learned lapidary work from his uncles; he is particularly known for spectacular stone inlay jewelry.

      About this piece, Na Na Ping notes: "I enjoy using stones from all parts of the world. The sugilite in this bracelet is from Africa. The stone is of gem quality and is known as jelly sugilite. It is very rare and expensive, and a challenge to work with being so hard (about 7 on the 1-10 Mohs scale)."`,
    },
    {
      title: `Hallmark for Eye of the Pascola, silver and sugilite cuff, c. 2018
      Na Na Ping (Michael Garcia), Pascua Yaqui Tribe`,
      src: img14,
      caption: `
      Santa Fe, NM
      ASM purchase, Friends of the ASM Collections funds, 2018
      W: 4.3 cm
      2019-6-2`,
    },
    {
      title: `Na Na Ping (Michael Garcia), Yaqui (Yoeme) at the Santa Fe Indian Art Market, Santa Fe, NM`,
      src: img15,
      caption: `
      Susan Hamilton, photographer, 2019
      
      "My influences are both ancient and contemporary. I draw inspiration from the Anasazi stone houses to the ancient pyramids of the Maya of Mexico. Yaqui origin stories tell of Yaquis coming from the stars to live where they are today, so many of my works reference the stars and planets as well."`,
    },
  ];

  return <Content
    title={title}
    details={details}
    clickableImage={img1}
    photos={photos_one} />;
};

export default Stone;
