import image from "./heading3/subheading 3-1/3-1_clickable.jpg";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

const Hallmarks = () => {
  const title = "Hallmarks";

  const quote =
    ` It will probably never be known which silversmith was the first to hallmark their work. [Silversmith] Juan de Dios could easily have been the first Zuni to hallmark, but he probably wasn't the only smith signing in the 1920s. Navajo Fred Peshlakai or Hopi Grant Jenkins could have been signing that early, or possibly even Etsitty Tsosie (Navajo). Those who signed that early would have been working for traders or in curio shops.
`.split("\n");

  const details = `
    By the 1940s, many smiths were stamping or etching hallmarks onto their works, and the practice has become increasingly common. After centuries of anonymity, makers can now often be identified by such signatures. Many books published over the last decades have provided information for those interested in identifying specific jewelers (see References). Social-media interest groups, ever growing in number and membership, are another source of such information, although crowd-sourced identifications are not always reliable.
`.split("\n");


  const imageDescription = "";

  return (
    <Content
      title={title}
      specialRender={
        <>
          <div className="quote">
            <span style={{ fontStyle: "normal" }}>
              Authors Pat and Kim Messier, interviewed for this exhibit, have noted: 
            </span>
            {quote.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
          </div>
          {details.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </>
      }
      clickableImage={image}
      imageDescription={imageDescription}
    />
  );
};

export default Hallmarks;
