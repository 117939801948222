import React, { useState, useCallback, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import Gallery from "react-photo-gallery";
import "react-image-lightbox/style.css";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import "./Gallery.css";

function GalleryComponent({ images }) {
  const [isLoading, setIsLoading] = useState(true);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);

  const openModal = (index) => {
    setPhotoIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openLightBox = () => {
    setIsOpen(true);
  };

  const getImageDimensions = async (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = file;
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };
      img.onerror = reject;
    });
  };

  useEffect(() => {
    Promise.all(
      images.map((image) =>
        getImageDimensions(image.src).then((dims) => ({ ...image, ...dims }))
      )
    ).then((photos) => {
      setPhotos(photos);
      setIsLoading(false); // Set loading to false after images have been loaded
    });
  }, [images]);

  const imgStyle = {
    transition:
      "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
  };

  const cont = {
    backgroundColor: "#eee",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
  };

  const GalleryImage = ({ photo, margin, direction, top, left, index }) => {
    if (direction === "column") {
      cont.position = "absolute";
      cont.left = left;
      cont.top = top;
    }

    return (
      <div
        style={{
          margin,
          height: "auto",
          border: "0.01px solid rgb(200,200,200)",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          width: photo.width,
          // width: "24%",
          ...cont,
        }}
        onClick={() => {
          openModal(index);
        }}>

        {/* <img alt={photo.title} className="image" style={{ width: "100%"}} {...photo} /> */}
        <img alt={photo.title} style={imgStyle} {...photo} />
        <p className="image-caption">
          {photo.title.split("\n").map((line, i) => (
            <div key={i}>{line}</div>
          ))}
        </p>
      </div>
    );
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <GalleryImage
        key={key}
        margin={"2px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />
    ),
    []
  );

  const goNext = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  const goBack = () => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };

  return (
    <div className="image-gallery">
      <div className="image-gallery-header">
        <h2>Gallery</h2>
        <hr />
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "1rem",
          }}>
          <CircularProgress style={{ color: "#008080" }} />
        </div>
      ) : (
        <>
          <Gallery photos={photos} renderImage={imageRenderer} />

          <Modal
            open={isModalOpen}
            onClose={closeModal}
            closeAfterTransition
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 999,
            }}>
            <Fade in={isModalOpen}>
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  padding: "20px",
                  width: "80%",
                  height: "70%",
                  overflow: "auto",
                  position: "relative", // Add this to make the close button's positioning relative to this div
                }}>
                <ArrowLeft
                  color="black"
                  onClick={goBack}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    left: "1px",
                    transform: "translateY(-50%)",
                  }}
                />
                <CloseIcon
                  color="black"
                  onClick={closeModal}
                  style={{
                    cursor: "pointer",
                    position: "absolute", // This will take the close button out of the flow
                    top: "20px", // Adjust according to your preference
                    right: "20px", // Adjust according to your preference
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                  }}>
                  <div className="gallery-exhibit-image">
                    <ZoomInIcon
                      color="black"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                      }}
                    />
                    <img
                      src={images[photoIndex]?.src}
                      alt={images[photoIndex]?.title}
                      onClick={openLightBox}
                      className="gallery-modal-image"></img>
                  </div>
                  <div
                    style={{
                      marginLeft: "20px",
                      width: "40%",
                      overflowY: "auto",
                      padding: "1em",
                    }}>
                    <h3>
                      {images[photoIndex]?.title?.split("\n").map((line, i) => (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </h3>

                    {images[photoIndex]?.caption?.split("\n").map((line, i) => {
                      line = line.trim();
                      if (line.includes('"')) {
                        var quoteParagraph = line.split('"');
                        return (
                          <div className="quote">
                            {quoteParagraph.map((line2, i2) => {
                              if (i2 % 2 == 0) {
                                return (
                                  <span style={{ fontStyle: "normal" }}>
                                    {line2}
                                  </span>
                                );
                              } else {
                                return <span>{line2}</span>;
                              }
                            })}
                          </div>
                        );
                      } else {
                        return (
                          <span key={i}>
                            {line}
                            <br />
                          </span>
                        );
                      }
                    })}
                  </div>
                </div>
                <ArrowRight
                  color="black"
                  onClick={goNext}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    right: "1px",
                    transform: "translateY(-50%)",
                  }}
                />
              </div>
            </Fade>
          </Modal>

          {isOpen && (
            <Lightbox
              style={{ zIndex: 1000 }}
              mainSrc={images[photoIndex].src}
              nextSrc={images[(photoIndex + 1) % images.length].src}
              prevSrc={
                images[(photoIndex + images.length - 1) % images.length].src
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % images.length)
              }
            />
          )}
        </>
      )}
    </div>
  );
}

export default GalleryComponent;
