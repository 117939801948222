import image from "./heading4/subheading 4-3/4-3_photobox-1-1.jpg";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";
import GalleryComponent from "../../../Components/Gallery/Gallery";

import img1 from "./heading4/subheading 4-3/4-3_photobox-1-1.jpg";
import img2 from "./heading4/subheading 4-3/4-3_photobox-1-2.jpg";
import img3 from "./heading4/subheading 4-3/4-3_photobox-2.jpg";
import img4 from "./heading4/subheading 4-3/4-3_photobox-3.jpg";

const White_Hogan_Kenneth_Begay_and_Others = () => {
  const title = "White Hogan: Kenneth Begay and Others";

  const details_one =
    `A major center of mid-century modern Native design was the White Hogan shop in Scottsdale, Arizona. The owners, John and Virginia Bonnell, had initially managed Fred Wilson’s Indian Trading Post in Phoenix, and then moved to Flagstaff, where their first White Hogan shop was located. Teaming up with Diné (Navajo) silversmiths Kenneth Begay and Allen Kee, the Bonnells touted their store as the “Home of the World’s Most Distinctive Indian Silver.”

The Bonnells moved the White Hogan to Scottsdale in 1951 and operated it there until John’s death in 1973. Their son Jon continued to run the business until it closed in 2006.

Kenneth Begay (1913–1977) first learned silverwork from Diné silversmith Fred Peshlakai at the Fort Wingate Indian School in the 1930s. After a stint demonstrating at Babbitt Brothers store in Flagstaff, he, along with his cousin Allen Kee, partnered with John and Virginia Bonnell at the White Hogan.
`.split("\n");

  const quote =
    `White Hogan gave Begay the freedom to break out from the traditional designs practiced by most Navajo silversmiths at the time. His designs quickly became less cluttered and more sophisticated, and, by the 1950s, he was winning countless awards at fairs and exhibits in the Southwest.`.split(
      "\n"
    );

  const details_two =
    `After leaving the White Hogan shop in 1964, Begay taught silversmithing, worked for the Navajo Arts and Crafts Guild, and continued to make jewelry until his death in 1977.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos_one = [
    {
      title: `Silver ring, 1950s
      Kenneth Begay (1913–1977), Diné (Navajo)`,
      src: img1,
      caption: `
      White Hogan, Scottsdale, AZ
      Courtesy Pat and Kim Messier
      J-38`,
    },
    {
      title: `Hallmark for silver ring, 1950s
      Kenneth Begay (1913–1977), Diné (Navajo)`,
      src: img2,
      caption: `
      White Hogan, Scottsdale, AZ
      Courtesy Pat and Kim Messier
      J-38`,
    },
    {
      title: `Diné (Navajo) silversmiths Kenneth Begay, Allen Kee, and John Silver`,
      src: img3,
      caption: `
      Babbitt’s Indian Shop, Flagstaff, AZ
      Unknown photographer, 1945
      Northern Arizona University, Cline Library, NAU.PH.99.65`,
    },
    {
      title: `Silver ring, c. 1970
      Leroy Turquoise (a.k.a. Leroy Thomas, b. 1946), Diné (Navajo)`,
      src: img4,
      caption: `
      White Hogan, Scottsdale, AZ
      Courtesy Pat and Kim Messier
      J-181

      Leroy Turquoise (also known as Leroy Thomas; b. 1946) was another silversmith who worked at White Hogan beginning in the 1960s. He was born in Bitter Springs, Arizona.`,
    },
  ];

  return (
    <Content
      title={title}
      specialRender={
        <>
          {details_one.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <div className="quote">
            {quote.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <br></br>
            <span style={{ fontStyle: "normal" }}>
              (Messier and Messier (2004:106))
            </span>
            <br></br>
            <br></br>
          </div>
          {details_two.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </>
      }
      clickableImage={image}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default White_Hogan_Kenneth_Begay_and_Others;
