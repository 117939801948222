import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-1.jpg";
import img2 from "./heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-2.jpg";
import img3 from "./heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-3.jpg";
import img4 from "./heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-4.jpg";
import img5 from "./heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-5.jpg";
import img6 from "./heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-6.jpg";
import img7 from "./heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-7-1.jpg";
import img8 from "./heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-7-2.jpg";

const Spondylus_Shell = () => {
  const title = "Spondylus Shell";

  const details =
    `Spondylus is the name given to a group of related species of mollusks also known as spiny oysters. They are found in coastal waters, from the Sea of Cortez south to Peru, and at relatively shallow depths (intertidal to nine feet, depending on the species) as well as at depths exceeding 100 feet. Their shells were highly sought after by Native populations, which led to an extensive trade network that relied on ocean-going canoes plying the coasts from Ecuador and Peru to the west coast of Mexico.

    While Spondylus shells are occasionally found as beach drift after storms, in some areas off Central and South America, Native divers would harvest the mollusks from the ocean bottom. This likely would not have been done by the average person and, thus, was probably the work of specialists.

    Spondylus is associated with the southeast, according to Hopi worldview, as are the color red, squash, and the scarlet macaw, among other plants and animals. Many Native peoples in the U.S. Southwest think of Spondylus as existing in opposition to turquoise. To the Hopi, for example, turquoise (tsorposi, literally “bluebird seed”) represents the southwest, as do the mountain bluebird, beans, and other animals and plants.
    
    Spondylus shell continues to be incorporated into Native Southwestern jewelry today, strung on necklaces, cut and set into inlay, and made into pendants. Artisans obtain the shell—which ranges in color from orange to red to purple—from traders John Hornbek and his wife, Navajo jeweler Sylvia Adakai, who operate their store, Red Shell Jewelry, in Gallup, New Mexico.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Spondylus pendants from necklace, C. 354–652 CE
      Ancestral Pueblo, Prayer Rock Province`,
      src: img1,
      caption: `
        Spondylus crassisquama shell
        Broken Flute Cave, Atahonez Wash, Apache Co., AZ
        Carnegie Institute transfer, 1957
        L: 9.5 cm
        A-14727`,
    },
    {
      title: `Spondylus necklace, before 1500 CE
      Casas Grandes vicinity, Northern Sierra Province`,
      src: img2,
      caption: `
      Spondylus crassisquama shell, serpentine(?)
      Casas Grandes vicinity, Chihuahua, Mexico
      Cummings purchase, Edward H. Ledwidge, 1934
      L: 25.5 cm
      20710`,
    },
    {
      title: `Irregular Spondylus-bead pendant necklace, C. 1000–1100 CE
      Hohokam, Tucson Province`,
      src: img3,
      caption: `
        Spondylus crassisquama shell
        Sunset Mesa Site, Tucson Basin, Pima Co. AZ
        L: 14 cm
        2001-27-92`,
    },
    {
      title: `Spondylus geometric pendants, C. 500–1400 CE
      Hohokam, Southern Arizona`,
      src: img4,
      caption: `
        Spondylus crassisquama shell
        Cummings Collection, before 1938
        L: 5.8 cm
        9349, 9350`,
    },
    {
      title: `Shell, white-heart glass bead, and turquoise necklace, c. 1910
      Probably Rio Grande Pueblo, NM`,
      src: img5,
      caption: `
        Shell, including Spondylus, turquoise, glass
        Gift of J. S. Buckbee, Kayenta, AZ, 1920
        L: 35 cm
        12053
        
        ASM Director Byron Cummings likely acquiring this necklace in 1920 while conducting a summer archaeological field school in northern Arizona for the University of Arizona. Cataloged as Navajo, it may have belonged to a Navajo individual yet was probably made by an artisan from one of the Pueblos in New Mexico.

        One Julien E. Buckbee was the postmaster for and ran a trading post in Tuba City, Arizona, in the early twentieth century. He is likely the donor, with his middle initial erroneously recorded.

        According to Native jewelry scholar Jonathan Batkin, "These white-heart beads [red beads with white centers] are unusual. I have seen white-heart beads with shell necklaces or other strung objects, including some at the Wheelwright Museum. I was suspicious and thought they were made-up objects. But seeing these on a necklace collected this early is convincing."`,
    },
    {
      title: `Red Shell Jewelry, Gallup, NM`,
      src: img6,
      caption: `
        Jannelle Weakly, photographer, 2007
        ASM 2021-234-image26`,
    },
    {
      title: `Spondylus shell-and-silver necklace, 2005
      Cliffton Lamar Aguilar (b. 1978), Kewa (Santo Domingo)`,
      src: img7,
      caption: `
        Kewa Pueblo, NM
        Spondylus shell, turquoise, silver
        B. C. Waddell Memorial Purchase Award
        ASM Southwest Indian Art Fair, 2005
        L: 40 cm
        2005-336-1`,
    },
    {
      title: `Cliffton Aguilar, Kewa (Santo Domingo), with his award-winning spiny oyster shell (Spondylus) necklace, Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ`,
      src: img8,
      caption: `
        Jannelle Weakly, photographer, 2005

        Cliffton Aguilar was born at Kewa Pueblo, attended high school in Bernalillo, New Mexico, and went on to receive an associate’s degree at Universal Technical Institute in Phoenix, Arizona.

        After graduating, he returned home and has since pursued jewelry making. He credits his parents, Laura Aguilar and Matthew Coriz, with teaching him to make jewelry. When he was much younger, he began helping them by cutting and drilling stones and stringing finished beads.

        The judges awarded Cliffton Aguilar the B. C. Waddell Memorial Purchase Award at the 2005 ASM Southwest Indian Art Fair.`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Spondylus_Shell;
