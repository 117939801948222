import React from "react";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox-1-1.jpg";
import img2 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox1-2.jpg";
import img3 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox1-3.jpg";
import img4 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox-2.jpg";
import img5 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox-3.jpg";
import img6 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox-4.jpg";
import img7 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox-5.jpg";
import img8 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox-6.jpg";
import img9 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox-7.jpg";
import img10 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox-8.jpg";

const Turquoise_Beads_Pendants = () => {
  const title = "Turquoise Beads and Pendants";

  const details =
    `Beads are the oldest form of jewelry known to humankind, with some whole-shell beads recovered from caves in Morocco dating back more than 140,000 years. In the U.S. Southwest, there are beads dating to at least 1200 BCE that were crafted from stone, shell, and bone. Their makers also created geometric pendants fashioned from these materials. This tradition continued throughout the precontact era, with additional jewelry types (such as bilobate beads and zoomorphic pendants) added to their repertoire over time.

    In precontact times, most turquoise was extracted from small segments of veins, a result of the technology that miners employed, which involved thermal (heat) fracture of the parent stone and then breaking the matrix (host rock) with stone mauls. Early lapidarists shaped the turquoise from these thin veins into flat tabular and round beads, using grinding stones and other abrasive surfaces.

    The most common beads were disks, which jewelry makers strung on necklaces and wrist bands. The strung turquoise beads were sometimes mixed with beads of white and red shell or another colored stone. Stone workers ground the flat tabular pendants into a variety of geometric shapes, including ovals and rectangles, and often incorporated them into the strands of round beads.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos_one = [
    {
      title: `Turquoise-and-shell cache, ASM A-24171`,
      src: img1,
      caption: `
          Douglas Lindsay, photographer, 1965
          ASM C-5207
          
          In 1965, a Tucson girl, Tani Bahti, found a spectacular example of ancient turquoise jewelry near Huerfano Butte in the Santa Rita Mountains southeast of Tucson while picnicking with her family. The turquoise, along with shell objects, was enclosed in a small, plain-brown pottery jar covered by an inverted bowl, both of the Hohokam tradition.

          The cache included 1,212 turquoise disk beads and 240 tabular pendants, along with several other turquoise pieces. Three shell bird-shaped pendants and thirty-six whole-shell bead/pendants were fashioned from small Glycymeris valves. Based on the style of the jar, the cache is thought to date to around 1100 CE (Bahti 1970).`,
    },
    {
      title: `Hypothetical reconstruction of turquoise necklace (disk beads and tabular pendants), c. 950–1150 CE
      Hohokam, Tucson Province`,
      src: img2,
      caption: `
          Huerfano Butte Cache, Pima Co., AZ
          Received from the Bahti Family, 1965
          L: 38 cm
          A-24171-C`,
    },
    {
      title: `Tani Bahti holds the jar and bowl and wears a necklace strung with the turquoise beads and pendants she found.`,
      src: img3,
      caption: `
            Douglas Lindsay, photographer, 1965
            ASM 10330`,
    },
    {
      title: `Necklace of tabular turquoise pendants, c. 1150–1450 CE
      Casas Grandes, Northern Sierra Province`,
      src: img4,
      caption: `
            Rancho Corralitos, Río Casas Grandes, Chihuahua, Mexico
            Gift of Gila Pueblo Foundation, 1950
            Ledwidge Collection
            L: 15 cm
            GP-4185`,
    },
    {
      title: `Assortment of turquoise tab pendants, before 1450 CE
      Hohokam, Salt-Gila Province`,
      src: img5,
      caption: `
            La Ciudad, Salt River Valley, Phoenix, Maricopa Co., AZ
            Gift of Gila Pueblo Foundation, 1950
            L: 0.5–2.6 cm
            GP-53262`,
    },
    {
      title: `Turquoise tabular pendants, c. 1050–1200 CE
      Prehistoric, Prescott Province`,
      src: img6,
      caption: `
            Jerome:4:1(GP), Porter Ranch, Granite Creek, Yavapai Co., AZ
            Gift of Gila Pueblo Foundation, 1950
            Simmons purchase, 1933
            L: 2.4 cm (longest)
            GP-39362`,
    },
    {
      title: `Turquoise necklace, Unknown date
      U.S. Southwest`,
      src: img7,
      caption: `
            Gift of Gila Pueblo Foundation, 1950
            L: 14.3 cm
            GP-53144`,
    },
    {
      title: `Turquoise-nugget-and-heishi necklace with jaclas, before 1970
      Rio Grande Pueblo or A:shiwi (Zuni), NM`,
      src: img8,
      caption: `
            Turquoise, shell
            Robert Tierney and Eino Wiiretjarvi Collection
            Gift of the University of Arizona Foundation, 1979
            L: 50 cm
            1980-47-275/AT-1980-56-101

            This style of turquoise necklace with attached jaclas (loops at bottom, first used as earrings) has been worn by Pueblo and Navajo people for well over 100 years. Primarily made at Kewa (Santo Domingo) Pueblo and the Pueblo of Zuni, it has been a major item of trade during this period.

            It is not certain whether this necklace and the jaclas originally went together.`,
    },
    {
      title: `Turquoise-nugget necklace, c. 1950
      Rio Grande Pueblo or A:shiwi (Zuni), NM`,
      src: img9,
      caption: `
            Gift of the Estate of Oskar Grunow, 1979
            L: 40 cm
            1979-87-3

            Oskar Grunow (1899–1978) was a member of the German Language faculty at the University of Arizona. He loaned a fine collection of mid-twentieth-century jewelry to ASM in 1964, which we then received as a bequest in 1979.`,
    },
    {
      title: `Three-strand turquoise heishi necklace, c. 1998
      Joseph Pacheco, Kewa (Santo Domingo) Pueblo`,
      src: img10,
      caption: `
            Kewa Pueblo, NM
            Gift of Catherine Sease, 2000
            L: 32 cm
            2020-426-5

            The turquoise in this piece is identified as having come from the Sleeping Beauty Mine, near Globe, Arizona. Sleeping Beauty turquoise is known for having small amounts of matrix, which makes it popular among heishi-bead makers. The Sleeping Beauty Mine is no longer in operation.`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img2}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Turquoise_Beads_Pendants;
