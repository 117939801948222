import image from "./heading4/subheading 4-6/4-6_photobox-1-1.jpg";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading4/subheading 4-6/4-6_photobox-1-1.jpg";
import img2 from "./heading4/subheading 4-6/4-6_photobox-1-2.jpg";
import img3 from "./heading4/subheading 4-6/4-6_photobox-2-1.jpg";
import img4 from "./heading4/subheading 4-6/4-6_photobox-2-2.jpg";
import img5 from "./heading4/subheading 4-6/4-6_photobox-3-1.jpg";
import img6 from "./heading4/subheading 4-6/4-6_photobox-3-2.jpg";

const Preston_Monongye = () => {
  const title = "Thomas Burnsides";

  const details =
    `A necklace in the ASM collection may well be one of a kind. Made of silver by Diné (Navajo) smith Tom Burnsides in 1956, it was inspired by a necklace made of Conus and Glycymeris shells in the collection of the Museum of New Mexico (no. 42063, Mimbres, Cameron Creek, New Mexico), pictured in a January 16, 1956 Life magazine article.
`.split("\n");

  const imageDescription = "";

  const photos_one = [
    {
      title: `Silver necklace inspired by Conus and Glycymeris shells, c. 1956`,
      src: img1,
      caption: `
      Thomas Burnsides, Diné (Navajo)
      Pine Springs, AZ
      Purchased from Clay Lockett, 1956
      L: 38 cm
      E-3231`,
    },
    {
      title: `Tom Burnsides, Diné (Navajo)`,
      src: img2,
      caption: `
      Adair Collection, c. 1940
      Wheelwright Museum of the American Indian, Santa Fe, NM`,
    },
    {
      title: `Stamped silver-and-turquoise belt buckle
        Diné (Navajo)`,
      src: img3,
      caption: `
      Illustration showing shell necklace Tom Burnsides replicated in silver, from “America’s Arts and Skills, Part VII: The timeless Southwest—a blending of old cultures has shaped its style. Photographed for Life by Eliot Elisofon,” 1956, p. 54.`,
    },
    {
      title: `Cover of Life magazine cover`,
      src: img4,
      caption: `
      Life magazine cover, January 16, 1956 that contains photo of shell necklace replicated by Tom Burnsides.`,
    },
    {
      title: `Necklace with Glycymeris shell bracelet/pendant similar to one on which Burnsides modeled the pendant on his silver necklace`,
      src: img5,
      caption: `
      Unknown culture, date, and locality
      Olivella dama shell and Glycymeris gigantea shell; stone inlay of turquoise, argillite, and jet
      ASM purchase from Nelle A. Dermont, 1919
      L: 40 cm
      8257`,
    },
    {
      title: `Strand of Conus-shells, the type that Burnsides rendered in silver, C. 1150–1450 CE
      Casas Grandes, Northern Sierra Province`,
      src: img6,
      caption: `
      Conus sp.
      Rancho Corralitos, Río Casas Grandes, Chihuahua, Mexico
      Gift of Gila Pueblo Foundation, 1950
      Ledwidge purchase, 1926
      L: 75 cm
      GP-4207`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={image}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Preston_Monongye;
