import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-2/subheading 2-2-7/2-2g_photobox-1.jpg";
import img2 from "./heading2/subheading 2-2/subheading 2-2-7/2-2g_photobox-2.jpg";
import img3 from "./heading2/subheading 2-2/subheading 2-2-7/2-2g_photobox-3-1.jpg";
import img4 from "./heading2/subheading 2-2/subheading 2-2-7/2-2g_photobox-3-2.jpg";

const Coral = () => {
  const title = "Coral";

  const details =
    `Coral, a material composed of calcified skeletons of sea invertebrates, is found in the Pacific Ocean and Sea of Cortez but was not widely used in ancient times as a raw material for jewelry.

    The presence of red coral from the Mediterranean, a source that had been exploited for millennia, has been documented in the U.S. Southwest as early as the mid-1700s. By the late nineteenth century, Pueblo artisans were stringing necklaces of coral beads, which they obtained through traders, incorporating them with beads of shell and turquoise. Coral may have served as a replacement for red argillite, Spondylus shell, or other red materials. As with turquoise, it was the color, rather than the source, that was significant.

    In the late 1920s, C. S. Wallace, trader at Zuni Pueblo, imported Italian coral branches for A:shiwi (Zuni) artisans to incorporate into their mosaic work. These artists greatly preferred deep-red coral over the lighter pink color that was also made available to them. Today, Colbaugh Processing, in Golden Valley, outside Kingman, Arizona, stabilizes lower-grade coral in a process similar to its treatment of turquoise.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Necklace of coral, silver, and turquoise beads, c. 1900
      Diné (Navajo) or Pueblo, AZ, NM, or UT`,
      src: img1,
      caption: `
        Coral, silver, turquoise
        ASM purchase, Nelle A. Dermont Collection, 1919
        L: 35 cm
        8248

        This style of necklace, from the early 1900s, was made of coral beads imported from Italy and was frequently customized. This example has small silver beads and two turquoise beads incorporated into the strands.`,
    },
    {
      title: `Coral inlay pin
      A:shiwi (Zuni)`,
      src: img2,
      caption: `
        Pueblo of Zuni, NM
        Silver, coral
        Purchase from Tom Bahti Indian Arts, Arizona Archaeological and Historical Society Discretionary Fund,
        1960
        L: 3.7 cm
        E-4394`,
    },
    {
      title: `Stabilized coral strand, c. 2007`,
      src: img3,
      caption: `
        Made and purchased at Colbaugh Processing, Inc., Golden Valley, AZ
        L: 40 cm
        2007-487-39`,
    },
    {
      title: `Stabilized coral at Colbaugh Processing, Inc., Golden Valley, AZ`,
      src: img4,
      caption: `
        Arthur Vokes, photographer, 2007
        ASM 2021-229-image92`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img2}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Coral;
