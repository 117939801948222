import React from "react";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";

import img1 from "./heading5/5-Photobox_01/5_photobox-01-1.jpg";
import img2 from "./heading5/5-Photobox_01/5_photobox-01-2.jpeg";
import img3 from "./heading5/5-Photobox_01/5_photobox-01-3.jpg";
import img4 from "./heading5/5-Photobox_02/5_photobox-02-1.jpg";
import img5 from "./heading5/5-Photobox_02/5_photobox-02-2.jpeg";
import img6 from "./heading5/5-Photobox_02/5_photobox-02-3.jpg";
import img7 from "./heading5/5-Photobox_02/5_photobox-02-4.jpg";
import img8 from "./heading5/5-Photobox_02/5_photobox-02-5.jpg";
import img9 from "./heading5/5-Photobox_03/5_photobox-03-1.jpg";
import img10 from "./heading5/5-Photobox_03/5_photobox-03-2.jpeg";
import img11 from "./heading5/5-Photobox_03/5_photobox-03-3.JPG";
import img12 from "./heading5/5-Photobox_03/5_photobox-03-4.jpg";
import img13 from "./heading5/5-Photobox_04/5_photobox-04-1.jpg";
import img14 from "./heading5/5-Photobox_04/5_photobox-04-2.jpg";
import img15 from "./heading5/5-Photobox_04/5_photobox-04-3.jpg";
import img16 from "./heading5/5-Photobox_05/5_photobox-05-1.jpg";
import img17 from "./heading5/5-Photobox_05/5_photobox-05-2.jpg";
import img18 from "./heading5/5-Photobox_05/5_photobox-05-3.jpg";
import img19 from "./heading5/5-Photobox_06/5_photobox-06-1.jpg";
import img20 from "./heading5/5-Photobox_06/5_photobox-06-2.jpg";
import img21 from "./heading5/5-Photobox_06/5_photobox-06-3.jpg";
import img22 from "./heading5/5-Photobox_07/5_photobox-07-1.jpg";
import img23 from "./heading5/5-Photobox_07/5_photobox-07-2.jpeg";
import img24 from "./heading5/5-Photobox_07/5_photobox-07-3.jpg";
import img25 from "./heading5/5-Photobox_08/5_photobox-08-1.jpg";
import img26 from "./heading5/5-Photobox_08/5_photobox-08-2.jpg";
import img27 from "./heading5/5-Photobox_08/5_photobox-08-3.jpg";
import img28 from "./heading5/5-Photobox_09/5_photobox-09-1.jpg";
import img29 from "./heading5/5-Photobox_09/5_photobox-09-2.jpg";
import img30 from "./heading5/5-Photobox_09/5_photobox-09-3.jpg";
import img31 from "./heading5/5-Photobox_10/5_photobox-10-1.jpg";
import img32 from "./heading5/5-Photobox_10/5_photobox-10-2.jpg";
import img33 from "./heading5/5-Photobox_10/5_photobox-10-3.jpg";
import img34 from "./heading5/5-Photobox_11/5_photobox-11-1.jpg";
import img35 from "./heading5/5-Photobox_11/5_photobox-11-2.jpg";
import img36 from "./heading5/5-Photobox_11/5_photobox-11-3.jpg";
import img37 from "./heading5/5-Photobox_12/5_photobox-12-1.jpg";
import img38 from "./heading5/5-Photobox_12/5_photobox-12-2.jpeg";
import img39 from "./heading5/5-Photobox_12/5_photobox-12-3.jpg";
import img40 from "./heading5/5-Photobox_13/5_photobox-13-1.jpg";
import img41 from "./heading5/5-Photobox_13/5_photobox-13-2.jpg";
import img42 from "./heading5/5-Photobox_13/5_photobox-13-3.jpg";
import img43 from "./heading5/5-Photobox_13/Beshligaii_and-Denali_p02.jpg";
import img44 from "./heading5/5-Photobox_14/5_photobox-14-1.jpg";
import img45 from "./heading5/5-Photobox_14/5_photobox-14-2.jpeg";
import img46 from "./heading5/5-Photobox_14/5_photobox-14-3.JPG";
import img47 from "./heading5/5-Photobox_15/5_photobox-15-1.jpg";
import img48 from "./heading5/5-Photobox_15/5_photobox-15-2.jpg";
import img49 from "./heading5/5-Photobox_15/5_photobox-15-3.jpg";
import img50 from "./heading5/5-Photobox_16/5_photobox-16-1.jpg";
import img51 from "./heading5/5-Photobox_16/5_photobox-16-2.jpg";
import img52 from "./heading5/5-Photobox_16/5_photobox-16-3.jpg";
import img53 from "./heading5/5-Photobox_16/5_photobox-16-4.jpg";
import img54 from "./heading5/5-Photobox_16/5_photobox-16-5.jpg";
import Content from "../../../Components/Content/Content";

const details =
  `The 1970s ushered in an era of extreme interest in Southwest Native American jewelry. This interest has continued, ebbing and flowing according to worldwide economic and fashion trends. Some contemporary artisans delight in creating works within the canon of defined tribal styles, some have forged new directions of their own, and others follow in the footsteps of mentors or family members. In the last 50 years, the palette of available materials has expanded to such a degree that artists have literally unlimited options. 
  
  Over the last several decades, ASM has acquired examples of modern jewelry to complement its ancient and historic collections, ever mindful that what is contemporary today becomes vintage the next day and then historic the day after that! ASM curators have sought to acquire jewelry representing a range of styles, from traditional to avant-garde. 
  
  Many of the acquisitions were winners of purchase awards at ASM’s Southwest Indian Art Fair, held from 1993 to 2015. Others were purchased with funds raised by the Friends of the ASM Collections, a membership-based support group created in 2005. 
  
  Our goal has been to allow ASM—Arizona’s public museum of anthropology—to acquire and share collections that document the enduring as well as ever-changing world of Native art, including jewelry, in our state and our region.`.split(
    "\n"
  );

const HeadingFive = () => {
  const photos_one = [
    {
      title: `Stamped silver-and-turquoise belt buckle, 2004
        Diné (Navajo)`,
      src: img1,
      caption: `
        Leonard Gene (b. 1960)
        Born in Fort Defiance, AZ; lives in Indian Wells, AZ, and Henderson, NV
        B. C. Waddell Memorial Award for Excellence in Jewelry
        ASM Southwest Indian Art Fair, 2004
        2004-350-1`,
    },
    {
      title: `Hallmark for stamped silver-and-turquoise belt buckle, 2004
      Leonard Gene (b. 1960), Diné (Navajo)`,
      src: img2,
      caption: `
      Born in Fort Defiance, AZ; lives in Indian Wells, AZ, and Henderson, NV
      B. C. Waddell Memorial Award for Excellence in Jewelry
      ASM Southwest Indian Art Fair, 2004
      2004-350-1`,
    },
    {
      title: `Leonard Gene, Diné (Navajo)`,
      src: img3,
      caption: `
      Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ
      Jannelle Weakly, photographer, 2004

      A steel fabricator as well as an accomplished silversmith, Leonard Gene splits his time between Henderson, Nevada, and Indian Wells, Arizona. Gene learned the basics of silversmithing from his brothers, but his fine stampwork was self-taught. Like jewelers of the past, he makes his own stamps.

        "Silversmithing brings out the patience in a person," Gene notes. "Deep down in my heart, I am thankful for the masters who came before us. They paved the way for us and showed us what craftsmanship is all about." He adds, "Silversmithing is a way to maintain my Native heritage, and I enjoy sharing it with others."

        "I work in gold as well as silver, and sometimes add precious gems to my pieces. I like to think this newer style will evolve over time." - Leonard Gene
      `,
    },
    {
      title: `Silver bola tie with Peruvian blue opal, c. 2006
      Diné (Navajo)`,
      src: img4,
      caption: `
      L. Bruce Hodgins (1959–2018)
        ASM purchase, B. C. Waddell Memorial Purchase Award for Jewelry
        Southwest Indian Art Fair 2006
        L: 5.5 cm (ornament)
        2006-162-1

      "My ideas come from many places," states Bruce. "Often I combine traditional with contemporary styles and mix techniques in a subtle way. Our Mother Earth provides many colored stones to use and enjoy, and I take advantage of those differences in much of my work" 
      
      (https://www.indiancraftshop.com/ARTIST_bios/artist_bio_Bruce-Hodgins.htm). For two consecutive years, Hodgins won the B. C. Waddell Memorial Purchase Award for Jewelry at ASM’s Southwest Indian Art Fair.`,
    },
    {
      title: `Hallmark for silver bola tie with Peruvian blue opal, c. 2006
      L. Bruce Hodgins (1959–2018), Diné (Navajo)`,
      src: img5,
      caption: `
      ASM purchase, B. C. Waddell Memorial Purchase Award for Jewelry
      Southwest Indian Art Fair 2006
      L: 5.5 cm (ornament)
      2006-162-1`,
    },
    {
      title: `Silver necklace and pendant with Brazilian druzy stone, c. 2007
      Diné (Navajo)`,
      src: img6,
      caption: `
        L. Bruce Hodgins (1959–2018)
        ASM purchase, B. C. Waddell Memorial Purchase Award for Jewelry
        ASM Southwest Indian Art Fair, 2007
        L: 35 cm
        2007-125-1`,
    },
    {
      title: `Hallmark for silver necklace and pendant with Brazilian druzy stone, c. 2007
      Diné (Navajo)`,
      src: img7,
      caption: `
        L. Bruce Hodgins (1959–2018)
        ASM purchase, B. C. Waddell Memorial Purchase Award for Jewelry
        ASM Southwest Indian Art Fair, 2007
        L: 35 cm
        2007-125-1`,
    },
    {
      title: `L. Bruce Hodgins, Diné (Navajo)`,
      src: img8,
      caption: `
      Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ
        Jannelle Weakly, photographer, 2007
        
        L. Bruce Hodgins grew up on Second Mesa, on the Hopi Indian Reservation, where his father was a school principal. He learned silver working from Hopi smith Sidney Secakuku. Hodgins was known for incorporating gemstones and other materials from around the world into his finely rendered jewelry.`,
    },
    {
      title: `Silver pendant with jade, opal, and zebra stone on silver collar, 2007 
      Hopi/Kawaika (Laguna)`,
      src: img9,
      caption: `
        Duane Maktima (b. 1954)
        Glorieta, NM
        ASM purchase, Kate Jennings Trust funds, 2007
        Collar was a gift to ASM from the artist
        L: 20 cm (including collar)
        2007-872-1/2007-871-1

        "The silver collar (2007-871-1) was a gift to ASM from the artist. Being a contemporary Native American and, as well, making a career at being a Native craftsman in the jewelry arts, I have to say without a doubt that what we are doing today is nothing short of what we have always done in the past. All that’s changed contemporarily is the materials and venues in which we trade or sell our work. Human nature almost always allows us to seek innovation as times evolve. In our case, new craftsmanship and materials have allowed us to create jewelry pieces in our time for the next millennia." - Duane Maktima`,
    },
    {
      title: `Hallmark for silver pendant with jade, opal, and zebra stone on silver collar, 2007
      Duane Maktima (b. 1954), Hopi/Kawaika (Laguna)`,
      src: img10,
      caption: `
      Glorieta, NM
      ASM purchase, Kate Jennings Trust funds, 2007
      Collar was a gift to ASM from the artist
      L: 20 cm (including collar)
      2007-872-1/2007-871-1`,
    },
    {
      title: `Duane Maktima, Hopi/Kawaika (Laguna)`,
      src: img11,
      caption: `
      Duane Maktima, while an artist-in-residence at the Museum of Northern Arizona,
        Flagstaff, AZ
        Unknown photographer, 1974
        Courtesy Duane Maktima`,
    },
    {
      title: `Duane Maktima, Hopi/Kawaika (Laguna)`,
      src: img12,
      caption: `
      Glorieta, NM
        Jannelle Weakly, photographer, 2007

        Duane Maktima’s introduction to Native crafts consisted of learning from his Hopi uncle how to carve katsina dolls. He graduated from Northern Arizona University’s College of Creative Arts, specializing in jewelry and metalsmithing, and over the last several years has become a highly regarded, prize-winning contemporary jewelry artist. 
        
        "My academic education set forth a personal awareness through research and studies of what was done in the past and to prepare and create for the future." - Duane Maktima`,
    },
    {
      title: `Silver spider pin with amber and pearl settings, 2007
        Hopi/Kawaika (Laguna)`,
      src: img13,
      caption: `
      Howard Sice
        Tucson, AZ
        ASM purchase from the artist, 2007
        L: 11.7 cm
        2007-814-1

        The Tucson Gem and Mineral Show is a veritable wonderland of gemstones for Native jewelry artists, such as Tucson’s Howard Sice, who purchased the amber and pearl settings for his spider pin at this annual February event.`,
    },
    {
      title: `Hallmark for silver spider pin with amber and pearl settings, 2007
      Howard Sice, Hopi/Kawaika (Laguna)`,
      src: img14,
      caption: `
      Tucson, AZ
      ASM purchase from the artist, 2007
      L: 11.7 cm
      2007-814-1`,
    },
    {
      title: `Howard Sice Hopi/Kawaika (Laguna)`,
      src: img15,
      caption: `
      Santa Fe Indian Art Market, Santa Fe, NM
        Jannelle Weakly, photographer, 2007

        Howard Sice grew up at Laguna Pueblo, and spent time with his Hopi relatives where he was exposed to the range of local cultural arts. He served in the Air Force, and in 1972 while still in the armed forces he began to experiment with jewelry making. Over the last fifty years, Sice has created jewelry and sculptures using a variety of metals and techniques. Sice currently lives in Tucson.`,
    },
    {
      title: `Silver squash blossom necklace, 2007
      Diné (Navajo)`,
      src: img16,
      caption: `
        Mary Tom (b. 1949)
        Chimney Butte, Navajo Nation, AZ
        ASM purchase, Kate Jennings Trust funds, 2007
        L: 33 cm
        2007-873-1`,
    },
    {
      title: `Hallmark for silver squash blossom necklace, 2007
      Mary Tom (b. 1949), Diné (Navajo)`,
      src: img17,
      caption: `
      Chimney Butte, Navajo Nation, AZ
      ASM purchase, Kate Jennings Trust funds, 2007
      L: 33 cm
      2007-873-1`,
    },
    {
      title: `Mary Tom, Diné (Navajo)`,
      src: img18,
      caption: `
      Santa Fe Indian Market, Santa Fe, NM
        Jannelle Weakly, photographer, 2021

        Mary Tom was born in Winslow, Arizona, and today resides and maintains her work studio in Chimney Butte, on the Navajo Nation. First collaborating with her former husband, Jack, she has since developed her own style of distinctive, clean, modern interpretations of traditional forms, such as this squash blossom necklace.

        The influence of traditional elements, nature, stones, techniques, and her unique themes are all imperative to her eventual success. As an important key player for Southwest jewelry, Mary Tom's elegant and extremely unique work is recognized by many awards locally, regionally, and nationally.

        Mary Tom, Facebook page`,
    },
    {
      title: `Celestial Wings, link bracelet, 2008
      Kawaika (Laguna)/Choctaw`,
      src: img19,
      caption: `
        Franklin Carrillo
        Pueblo of Laguna, NM

        Silver, sugilite, lapis, turquoise, red and pink coral, sierra black, spiny oyster, New Lander chalcosiderite, azurite, boulder opal, chrysocolla, opal
        ASM purchase, Friends of the ASM Collections Acquisition Award
        ASM Southwest Indian Art Fair, 2008
        L: 19.7 cm
        2008-151-1`,
    },
    {
      title: `Hallmark for Celestial Wings, link bracelet, 2008
      Franklin Carrillo, Kawaika (Laguna)/Choctaw`,
      src: img20,
      caption: `
      Pueblo of Laguna, NM
      Silver, sugilite, lapis, turquoise, red and pink coral, sierra black, spiny oyster, New Lander chalcosiderite, azurite, boulder opal, chrysocolla, opal
      ASM purchase, Friends of the ASM Collections Acquisition Award
      ASM Southwest Indian Art Fair, 2008
      L: 19.7 cm
      2008-151-1`,
    },
    {
      title: `Franklin Carrillo
      Kawaika (Laguna)/Choctaw`,
      src: img21,
      caption: `
      Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ
        Jannelle Weakly, photographer, 2008

        Franklin Carrillo describes himself as an artist who “combines the cultural influences and designs of New Mexico with contemporary techniques to produce innovative jewelry.” Pottery and petroglyph designs, along with ancient architecture, are particularly referenced in his work.

        ASM Curator Andrew Higgins recorded the background on this bracelet from the artist: "The celestial wings design is an elaboration of another bracelet I make called the Chaco Canyon design. The Chaco bracelet pays tribute to the architecture and builders of those great monumental structures. Whereas the Chaco design uses many straight lines and angles, the celestial wings bracelet is dominated by wing and bird-head elements, which invoke the feeling of flight or movement. The feathers of certain birds are spiritually connected to the heavens or the “celestial.” Many or all of Chaco’s buildings are aligned to the solstice and lunar events, which were closely watched by its people. This is what I had in mind."
        
        Franklin Carrillo, 2008`,
    },
    {
      title: `Tufa-cast mosaic belt buckle, 2008
      Hopi/Akimel O’odham (Pima)`,
      src: img22,
      caption: `
        Bennard Dallasvuyaoma (b. 1950)
        Albuquerque, NM
        Silver, turquoise, coral, jet, palm wood, lapis, carnelian, coral, orange unakite, jasper, eudialite, purple jade, chrysoprase
        ASM purchase, UA Vice President for Research Leslie Tolbert Acquisitions Award
        ASM Southwest Indian Art Fair, 2008
        W: 5.6 cm
        2008-150-1`,
    },
    {
      title: `Hallmark for Tufa-cast mosaic belt buckle, 2008
      Bennard Dallasvuyaoma (b. 1950), Hopi/Akimel O’odham (Pima)`,
      src: img23,
      caption: `
      Albuquerque, NM
      Silver, turquoise, coral, jet, palm wood, lapis, carnelian, coral, orange unakite, jasper, eudialite, purple jade, chrysoprase
      ASM purchase, UA Vice President for Research Leslie Tolbert Acquisitions Award
      ASM Southwest Indian Art Fair, 2008
      W: 5.6 cm
      2008-150-1`,
    },
    {
      title: `Bennard Dallasvuyaoma
      Hopi/Akimel O’odham (Pima)`,
      src: img24,
      caption: `
      Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ
        Marnie Sharp, photographer, 2009

        Bennard Dallasvuyaoma was born in Hotvela (Hotevilla), on Third Mesa, on the Hopi Indian Reservation. After a career as an educator, he turned to jewelry making in 1993, working with his wife, Frances. Dallasvuyaoma’s style of mosaic work recalls that of his well-known uncle, Charles Loloma. When ASM purchased this buckle, the artist described it as a landscape of the Hopi Mesas. He has likened the rows of mosaic in his works to stacked ears of corn, also referencing the mosaic traditions of the ancient Hohokam and Hisatsinom (Ancestral Hopi). Dallasvuyaoma collects the stones he uses to cast silver from around Hotvela.`,
    },
    {
      title: `Turquoise-and-Spondylus shell earrings, 2008
       A:shiwi (Zuni)`,
      src: img25,
      caption: `
        Jennie and Ed Vicenti
        Pueblo of Zuni, NM
        Gift of the artists, 2008
        L: 4.0 cm
        2008-142-1 a and b`,
    },
    {
      title: `Hallmark for turquoise-and-Spondylus shell earrings, 2008
      A:shiwi (Zuni)`,
      src: img26,
      caption: `
        Jennie and Ed Vicenti
        Pueblo of Zuni, NM
        Gift of the artists, 2008
        L: 4.0 cm
        2008-142-1 a and b`,
    },
    {
      title: `Jennie and Ed Vicenti
      A:shiwi (Zuni),`,
      src: img27,
      caption: `
      Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ
        Jannelle Weakly, photographer, 2008`,
    },
    {
      title: `Side one of two-sided turquoise-and-Spondylus-shell mosaic necklace, 2009`,
      src: img28,
      caption: `
        Charlene Reano, Katishtya (San Felipe), and Frank Reano
        Kewa Pueblo, NM
        Friends of the ASM Collections Hartman Lomawaima Memorial Purchase Award
        ASM Southwest Indian Art Fair, 2009
        2009-128-1`,
    },
    {
      title: `Side two of two-sided turquoise-and-Spondylus-shell mosaic necklace, 2009`,
      src: img29,
      caption: `
        Charlene Reano, Katishtya (San Felipe), and Frank Reano
        Kewa Pueblo, NM
        Friends of the ASM Collections Hartman Lomawaima Memorial Purchase Award
        ASM Southwest Indian Art Fair, 2009
        2009-128-1`,
    },
    {
      title: `Charlene Reano, Katishtya (San Felipe)`,
      src: img30,
      caption: `
      Charlene Reano with her and her husband Frank’s award-winning necklace, Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ
        Jannelle Weakly, photographer, 2009`,
    },
    {
      title: `Silver bola tie with Wild Horse magnesite setting
      Diné (Navajo)`,
      src: img31,
      caption: `
        Toney Mitchell
        Flagstaff, AZ
        ASM purchase, Kate Jennings Trust funds
        L: 8.5 cm (ornament)
        2010-200-1
        
        This bola tie won the Judge’s Choice in Jewelry first place award at ASM’s 2010 Southwest Indian Art Fair, an award sponsored by Arch and Laura Brown, and Roland and Pam Shack.`,
    },
    {
      title: `Hallmark for silver bola tie with Wild Horse magnesite setting
      Toney Mitchell, Diné (Navajo)`,
      src: img32,
      caption: `
      Flagstaff, AZ
      ASM purchase, Kate Jennings Trust funds
      L: 8.5 cm (ornament)
      2010-200-1`,
    },
    {
      title: `Toney Mitchell, Diné (Navajo)`,
      src: img33,
      caption: `
      Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ
        Emily Kleinkauf, photographer, 2008

        "I am Tachiinii (Red Running into the Water), born for Ashii’hi (Salt people). My maternal grandparents are Ta’baahi (Edge Water people) and my paternal grandparents are Tl’izii Lani (Many Goats). These are my Dineh clans.

        "My late brother, Leo Mitchell, introduced me to metalwork and silversmithing. From then on, through trial and error, I developed the skills necessary to hone my craft. I believe my innate artistic talent comes from my mother, who is a traditional weaver of exquisite Navajo rugs. The geometric patterns and designs she uses I also use in my silverwork. My parents’ traditional oral teaching and my own beliefs continuously remind me of who I am and the artist I am." - Toney Mitchell, 2021`,
    },
    {
      title: `Arizona Highways, silver overlay cuff
      Hopi`,
      src: img34,
      caption: `
      Watson Honanie (b. 1953)
        Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
        ASM purchase, Friends of the ASM Collections funds, 2019
        W: 5.2 cm
        2019-6-1

        This cuff bears designs that reference ancient Pueblo pottery, a major source of the artist’s creative vocabulary.`,
    },
    {
      title: `Hallmark for Arizona Highways, silver overlay cuff
      Watson Honanie (b. 1953), Hopi`,
      src: img35,
      caption: `
      Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
      ASM purchase, Friends of the ASM Collections funds, 2019
      W: 5.2 cm
      2019-6-1`,
    },
    {
      title: `Watson Honanie, Hopi`,
      src: img36,
      caption: `
      Santa Fe Indian Art Market, Santa Fe, NM
        Susan Hamilton, photographer, 2019

        Watson Honanie was tutored in jewelry making by two uncles, Fred Kabotie and Porter Timeche.`,
    },
    {
      title: `Silver cuff bracelet with floral designs, 2019
      Bennett Kagenveama (b. 1964) Hopi`,
      src: img37,
      caption: `
        Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
        ASM purchase from the artist, Friends of the ASM Collections funds, 2019
        W: 4.5 cm
        2019-233-1`,
    },
    {
      title: `Hallmarka for silver cuff bracelet with floral designs, 2019
      Bennett Kagenveama (b. 1964), Hopi`,
      src: img38,
      caption: `
      Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
      ASM purchase from the artist, Friends of the ASM Collections funds, 2019
      W: 4.5 cm
      2019-233-1`,
    },
    {
      title: `Bennett Kagenveama, Hopi`,
      src: img39,
      caption: `
      Heard Museum Guild Indian Fair and Market, Phoenix, AZ
        Susan Hamilton, photographer, 2020

        Bennett Kagenveama was born in Songòopavi (Shungopavi), on Second Mesa, on the Hopi Indian Reservation. His parents, Richard and Gladys Kagenveama, were both artists. His father, a silversmith, was a member of the Hopi Silvercraft Cooperative Guild. His mother was a highly respected coiled-basket maker. Kagenveama works in the Hopi overlay technique, yet defines his own style through the use of intricate designs, such as those found on this cuff, with its floral/butterfly motifs.`,
    },
    {
      title: `Silver-and-coral necklace in honor of missing and murdered Indigenous women
      Diné (Navajo)`,
      src: img40,
      caption: `
        Alex Beeshligaii,  and daughter Denali Beeshligaii (Diné/Swedish)
        Tucson, AZ
        ASM purchase from the artist, 2021
        2021-498-1`,
    },
    {
      title: `Pendant for silver-and-coral necklace in honor of missing and murdered Indigenous women
      Diné (Navajo)`,
      src: img41,
      caption: `
      Alex Beeshligaii, and daughter Denali Beeshligaii (Diné/Swedish)
        Tucson, AZ
        ASM purchase from the artist, 2021
        2021-498-1`,
    },
    {
      title: `Hallmark for silver-and-coral necklace in honor of missing and murdered Indigenous women
       Diné (Navajo)`,
      src: img42,
      caption: `
      Alex Beeshligaii,and daughter Denali Beeshligaii (Diné/Swedish)
        Tucson, AZ
        ASM purchase from the artist, 2021
        2021-498-1

        Alex Beeshligaii, Diné (Navajo), lives in Tucson. He collaborated with his daughter, Denali, on this necklace. She was moved to design pendants on the theme of missing and murdered Indigenous women in response to hearing that a female relative had been killed on the Navajo Nation. For this pendant, Alex chose the Hopi maiden with her hair in whorls.

        "I have known many Hopi people; on my mom’s side, I am part Tewa. This is a dedication to those women. Our jewelry honoring missing and murdered Indigenous women is also symbolic of Navajo, and also Apache, men who historically disfigured women as a way of control, to have dominion over females." - Alex Beeshligaii, 2022`,
    },
    {
      title: `Denali Beeshligaii (Diné (Navajo)/Swedish) and Alex Beeshligaii, Diné (Navajo), Tucson, AZ`,
      src: img43,
      caption: `
      Jannelle Weakly, photographer, 2004`,
    },
    {
      title: `Aspen Forest, silver cuff with turquoise and black-lipped mother-of-pearl shell
      A:shiwi (Zuni)/Diné (Navajo)`,
      src: img44,
      caption: `
        Colin Coonsis (b. 1981)
        Pueblo of Zuni, NM
        ASM purchase, Friends of the ASM Collections funds
        W: 4.5 cm
        2021-151-1`,
    },
    {
      title: `Hallmark for Aspen Forest, silver cuff with turquoise and black-lipped mother-of-pearl shell
      Colin Coonsis (b. 1981), A:shiwi (Zuni)/Diné (Navajo)`,
      src: img45,
      caption: `
      Pueblo of Zuni, NM
      ASM purchase, Friends of the ASM Collections funds
      W: 4.5 cm
      2021-151-1`,
    },
    {
      title: `Colin Coonsis, A:shiwi (Zuni)/Diné (Navajo)`,
      src: img46,
      caption: `
        Unknown photographer, c. 2021
        Photo courtesy of the artist

        "As a child, I watched and learned from my mother her jewelry making and inlay techniques that I would someday translate into my own vision."
        
        Colin Coonsis grew up at Zuni, a son of jewelry artists Rolanda Haloo and Harlan Coonsis. His work is highly contemporary yet can reflect aspects of older A:shiwi (Zuni) styles. This cuff displays a novel approach to A:shiwi channel work, known for its linear, ordered arrangements.

        "My lifestyle and my experiences traveling the world [have] influenced my talent and my work ethic. I am forward-thinking, creative, and always looking for an innovative approach to anything I am challenged with." - Colin Coonsis`,
    },
    {
      title: `Hot Rod Sting Ray buckle, 2020
      Kawaika (Laguna)/Nde (Chiricahua Apache)`,
      src: img47,
      caption: `
        Pat Pruitt
        Pueblo of Laguna, NM
        Stainless steel, stingray leather
        ASM purchase, Friends of the ASM Collections funds, 2021
        L: 9.7 cm
        2021-92-1

        "I couldn’t tell you about the inspiration for the hot rod flames, it’s just been something I have always liked...I'll also be clear, it’s not just any hot rod flame, as there are hundreds of styles, it’s a very specific 1950s-esq, bold and fat flame. As for the texture, well, that’s just the texture of the stingray skin that is used for the inlay." - Pat Pruitt

        Pat Pruitt’s background in both jewelry making and industrial metal production gives his work a unique look that places it in a category by itself. While he was recovering from a bike accident, Pruitt learned silversmithing from Greg Lewis of Laguna Pueblo. He further developed his skills working with another Laguna smith, Charlie Bird. Pruitt attended Southern Methodist University, studying mechanical engineering and interning in a machine shop.

        Pruitt’s work in steel is bold and modern in approach and materials. At first it was misunderstood by those who could not envision it as “Native American” art. He has since won numerous awards at the Heard Museum in Phoenix and at Santa Fe’s Indian Market, including the latter's Best of Show in 2018 (see https://www.youtube.com/watch?v=Ak5GFHHh9V0).`,
    },
    {
      title: `Hallmark for Hot Rod Sting Ray buckle, 2020
      Pat Pruitt, Kawaika (Laguna)/Nde (Chiricahua Apache)`,
      src: img48,
      caption: `
      Pueblo of Laguna, NM
      Stainless steel, stingray leather
      ASM purchase, Friends of the ASM Collections funds, 2021
      L: 9.7 cm
      2021-92-1`,
    },
    {
      title: `Pat Pruitt, Kawaika (Laguna)/Nde (Chiricahua Apache)`,
      src: img49,
      caption: `
      Santa Fe Indian Art Market, Santa Fe, NM
        Jannelle Weakly, photographer, 2021

        Pat Pruitt’s background in both jewelry making and industrial metal production gives his work a unique look that places it in a category by itself. While he was recovering from a bike accident, Pruitt learned silversmithing from Greg Lewis of Laguna Pueblo. He further developed his skills working with another Laguna smith, Charlie Bird. Pruitt attended Southern Methodist University, studying mechanical engineering and interning in a machine shop.

        Pruitt’s work in steel is bold and modern in approach and materials. At first it was misunderstood by those who could not envision it as “Native American” art. He has since won numerous awards at the Heard Museum in Phoenix and at Santa Fe’s Indian Market, including the latter's Best of Show in 2018 (see https://www.youtube.com/watch?v=Ak5GFHHh9V0).`,
    },
    {
      title: `Pendant and handmade chain necklace
      Yavapai Apache`,
      src: img50,
      caption: `
        Matagi Sorensen
        Camp Verde, AZ
        Oxidized silver, diamonds
        ASM purchase, Friends of the ASM Collections funds, 2021
        2021-497-2

        "I fondly remember waking up outdoors when I was young, and seeing the morning frost glistening on the plants. This pendant, with small diamond settings, is meant to recreate this memory." - Matagi Sorensen`,
    },
    {
      title: `Pendant
      Yavapai Apache`,
      src: img51,
      caption: `
        Matagi Sorensen
        Camp Verde, AZ
        Oxidized silver, diamonds
        ASM purchase, Friends of the ASM Collections funds, 2021
        2021-497-2

        "I fondly remember waking up outdoors when I was young, and seeing the morning frost glistening on the plants. This pendant, with small diamond settings, is meant to recreate this memory." - Matagi Sorensen`,
    },
    {
      title: `Hallmark for pendant and necklace
      Yavapai Apache`,
      src: img52,
      caption: `
      Matagi Sorensen
        Camp Verde, AZ
        Oxidized silver, diamonds
        ASM purchase, Friends of the ASM Collections funds, 2021
        2021-497-2`,
    },
    {
      title: `Matagi Sorensen, Yavapai Apache`,
      src: img53,
      caption: `
      Santa Fe Indian Market, Santa Fe, NM
        Jannelle Weakly, photographer, 2021

        "I spent a lot of time outdoors when I was young, working with my father, who knew how to find materials, like rocks and plants, and make things with them. I also watched my great-grandmother Virginia Newton weaving her baskets using twigs and devil’s claw. In my jewelry, I pull ideas from plants and then then alter them with new materials, thereby creating my own thing." - Matagi Sorensen

        Matagi Sorensen (Yavapai Apache) is a contemporary artist who creates hand-fabricated and lost-wax-cast jewelry. A recent graduate of the MFA program at Arizona State University, he  enjoyed the freedom there to experiment with different materials and techniques.`,
    },
    {
      title: `Sculptural ring
      Yavapai Apache`,
      src: img54,
      caption: `
        Matagi Sorensen
        Camp Verde, AZ
        Silver, devil’s claw
        ASM purchase, Friends of the ASM Collections funds, 2021
        2021-497-1

        "My great-grandmother used devil’s claw for the designs in her basketry. The jewelry I make with the devil’s claw hooks is a way I honor her legacy."- Matagi Sorensen`,
    },
  ];

  return (
    <Content
      title={"Modern Southwestern Jewelry Showcase"}
      photos={photos_one}
      details={details}
      clickableImage={img22}
    />
  );
};

export default HeadingFive;
