import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "./nav-images/AZ-State-Museum-Logo_PRIMARY_REVERSE.png";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
} from "mdb-react-ui-kit";
import { motion } from "framer-motion";

const sites = [
  { name: "Wearing Jewelry", path: "/Wearing-Jewelry" },
  { name: "Materials", path: "/Materials" },
  { name: "Developments 1920s-1950s", path: "/Developments-1920s-1950s" },
  { name: "Early Modernists", path: "/Early-Modernists" },
  { name: "Modern Southwestern Jewelry", path: "/Modern-Southwestern-Jewelry" },
  { name: "About & Readings", path: "/About" },
];

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);

  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://matomo.aws.coh.arizona.edu/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '67']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();

  console.log("test")

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}>
      <MDBNavbar
        className="navbar"
        expand="lg"
        style={{ backgroundColor: "#008080", borderBottom: "3px solid white" }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href="/">
            <img
              src={logo}
              alt="Logo"
              className="navbar__logo"
              style={{ width: "275px" }}
            />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNav(!showNav)}>
            <MDBIcon icon="bars" fas style={{ color: "#fff" }} />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showNav}>
            <MDBNavbarNav
              className="mr-auto mb-2 mb-lg-0"
              style={{ justifyContent: "flex-end", gap: "1.875rem" }}>
              {sites.map((site, index) => (
                <div key={index}>
                  <MDBNavbarLink
                    href={site.path}
                    className="navbar-link"
                    style={{ color: "white", fontSize: "1rem" }}>
                    {site.name}
                  </MDBNavbarLink>
                </div>
              ))}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </motion.div>
  );
};

export default Navbar;
