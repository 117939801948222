import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4d/3-4d photobox-1.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4d/3-4d photobox-2.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4d/3-4d photobox-3.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4d/3-4d photobox-4.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4d/3-4d photobox-5-1.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4d/3-4d photobox-5-2.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4d/3-4d photobox-6.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4d/3-4d photobox-7.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Kewa_Santo_Domingo_Jewelry = () => {
  const title = "Kewa Santo Domingo Jewelry";

  const details =
    `Pueblo jewelers, following ancestral tradition, make disk-bead necklaces that rival or exceed the fineness of ancient beadwork. Heishi, the Kewa word for shell, has become the commonly used term for this type of jewelry.
 
Producing heishi is an exacting, time-consuming process, made much easier since the introduction of power tools, such as drills and sanders. Previously, an artisan would use a pump drill to create holes in the bead blanks one by one, string them onto cords, and then roll the strands on sandstone, resulting in smooth, symmetrical beads.
 
A number of bead makers from Kewa Pueblo specialize in delicately thin heishi, strung in single or multiple strands. Others string thicker beads that can contain a wide array of traditional and new materials. Mosaic pendants and earrings that are produced in large quantities affirm the vitality of this tradition, which is interpreted in innovative ways. `.split(
      "\n"
    );

  const imageDescription = "";

  const photos = [
    {
      title: `Kewa (Santo Domingo) artists making shell beads`,
      src: img1,
      caption: `
      Gallup, NM
Burton Frasher, photographer, 1937
Frashers Fotos and Pomona Public Library, B6451`,
    },
    {
      title: `Fine olive-shell heishi necklace, 1964
      Kewa (Santo Domingo)`,
      src: img2,
      caption: `
Ray Rosetta (1929–?) and Mary Rosetta (1930–2005) 
Kewa Pueblo, NM
First Prize, 1964 Gallup Inter-Tribal Indian Ceremonial
Pinshell
Gift of Tom Bahti, 1970
L: 33.5 cm
E-8731

The donor purchased this necklace for resale but opted instead to donate it to ASM as an example of the fine heishi that was being produced by the Rosettas and other artisans from Kewa (Santo Domingo) Pueblo.`,
    },
    {
      title: `Turquoise heishi, 1975
      Kewa (Santo Domingo)`,
      src: img3,
      caption: `
      Kewa Pueblo, NM
Gift of Grace Niemcziek, 2000
L: 28 cm
2000-91-42`,
    },
    {
      title: `Shell, turquoise, and jet mosaic necklace, c. 1920
      Kewa (Santo Domingo)`,
      src: img4,
      caption: `
      Kewa Pueblo, NM
Purchased from Mrs. L’Ameroux, 1923
L: 36 cm
6498`,
    },
    {
      title: `Shell, turquoise, and plastic thunderbird necklace and earrings, c. 1940
      Kewa (Santo Domingo)`,
      src: img5,
      caption: `
      Kewa Pueblo, NM
Gift of Jo Ann Nelson, 1987
L: 38.5 cm. (necklace), 4.3 cm (earrings)
1987-79-1-a,b,c`,
    },
    {
      title: `Tableta dancers Sammi Gonzales (left) and Raeita Gonzales (right)`,
      src: img6,
      caption: `
      Wearing matching Santo Domingo necklaces, with Raeita’s son 
      Po-Who-Geh-Owingeh (San Ildefonso Pueblo), NM
Harold Kellogg, photographer, 1955
Palace of the Governors Photo Archives, 077486`,
    },
    {
      title: `Turquoise-and-shell mosaic barrette, c. 2001
       Katishtya (San Felipe)`,
      src: img7,
      caption: `
Charlotte Reano
Kewa Pueblo, NM
Gift of Mark Bahti, 2001
L: 6 cm
2001-190-14

Charlotte and Percy Reano are a husband-and-wife team of highly skilled jewelry artists from Santo Domingo Pueblo. Percy is a member of the famed Reano family, who revived the tradition of inlay jewelry in Santo Domingo. Charlotte is originally from San Felipe Pueblo. The pair often collaborates to make vivid one-of-a-kind inlay designs and very small multi-strand heishi jewelry. Charlotte does most of the drilling and stringing of the heishi beads and helps cut the stones for the mosaics. Percy focuses on cutting and inlaying their impressive mosaic pieces (https://www.indianpueblostore.com/pages/percy-and-charlotte-reano)`,
    },
    {
      title: `Mosaic necklace and earrings, c. 1990
      Kewa (Santo Domingo)/Choctaw`,
      src: img8,
      caption: `
      Richard Aguilar
Kewa Pueblo, NM
Ironwood beads, mother-of-pearl, turquoise, coral
Gift of Neil Matthew, 2005
L: 38.5 cm.
2005-820-24 a–c

Richard Aguilar’s father was from Kewa Pueblo and his mother was Choctaw. He learned heishi making from his father: 

"I’m a second generation heishi maker, taught the traditional way using a hand-pump drill." 

(https://raguilar.artspan.com/home)`,
    },
  ];
  return (
    <Content
      title={title}
      specialRender={
        <>
          {details.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <br />
          <span>
            {`Using the same materials—shell and stone—Kewa (Santo Domingo) artisans have continued the millennia-old tradition of mosaic inlay. However, during the Great Depression, mosaic jewelry makers were forced to find inexpensive replacements for turquoise, jet, and shell. They began using materials such as plastic from batteries, record albums, flatware, and other sources. This trend continued into the 1960s. In the last decade, these necklaces have become extremely popular vintage purchases, and a few artists have created revival versions. See: `}
            <a href="https://www.doi.gov/iacb/act">link.</a>
          </span>
        </>
      }
      clickableImage={img4}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Kewa_Santo_Domingo_Jewelry;
