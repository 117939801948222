import React from "react";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-1/subheading 2-1-5/2-1e_photobox-1-1.jpg";
import img2 from "./heading2/subheading 2-1/subheading 2-1-5/2-1e_photobox-1-2.jpg";
import img3 from "./heading2/subheading 2-1/subheading 2-1-5/2-1e_photobox-1-3.jpg";
import img4 from "./heading2/subheading 2-1/subheading 2-1-5/2-1e_photobox-1-4.jpg";
import img5 from "./heading2/subheading 2-1/subheading 2-1-5/2-1e_photobox-1-5.jpg";
import img6 from "./heading2/subheading 2-1/subheading 2-1-5/2-1e_photobox-1-6.jpg";
import img7 from "./heading2/subheading 2-1/subheading 2-1-5/2-1e_photobox-2.jpg";
import img8 from "./heading2/subheading 2-1/subheading 2-1-5/2-1e_photobox-3.jpg";

const Turquoise_Substitutes = () => {
  const title = "Turquoise Substitutes";

  const details =
    `There have been times, over at least the past century, when turquoise was difficult to obtain or became particularly costly. Before 1900, only a limited number of American turquoise mines were being worked. At one point in the 1920s, trader Lorenzo Hubbell imported turquoise from Persia (now Iran). During the Depression of the 1930s and in the subsequent World War II years, sources of turquoise for many artisans dried up.

    Glass Substitutes
    In the 1920s, to alleviate periodic shortages and provide less-expensive alternatives to turquoise, traders, including Lorenzo Hubbell, supplied artists with blue-glass beads and cabochons from Italy and Czechoslovakia. These became known as Hubbell beads. Diné (Navajo) artisans embraced the use of Hubbell beads in jewelry intended for local use as well as to sell. The color of turquoise, rather than its mineralogical nature, seems to have been of primary interest.
    
    A Future of Jewelry in Plastic?
    Some turquoise-colored material used in jewelry is actually pure plastic, misleadingly called “block turquoise.” This is true for some other raw materials as well, including malachite and azurite. Often, whole settings are prefabricated to look like mosaic work. Buyers must take great care, especially when they make purchases online from unknown vendors, to ensure that they are buying real turquoise.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos_one = [
    {
      title: `Hubbell-glass, turquoise, and shell necklace, c. 1940?
      Probably Diné (Navajo), AZ, NM, or UT`,
      src: img1,
      caption: `
          Glass beads, turquoise, shell
          Gift of Mike and Mimi Haggerty, 2011
          Formerly in the collection of C. Richard LeRoy
          L: 35.4 cm
          2011-274-1

          This necklace was likely not made to sell, but to be worn. It features arrowhead-shaped Hubbell-glass beads; two round blue-glass beads; white-glass cylindrical beads; a flat turquoise bead; two olive shells; and a single Spondylus-shell bead. Diné (Navajos) consider projectile points, as well as turquoise, to have protective properties.`,
    },
    {
      title: `Lorenzo Hubbell with Diné (Navajos) and wagon, Navajo Nation, AZ`,
      src: img2,
      caption: `
          Charles M. Wood, photographer, c. 1925. ASM 1175`,
    },
    {
      title: `Arizona Highways magazine cover, July 1971.`,
      src: img3,
      caption: ``,
    },
    {
      title: `An illustration from an article in the Arizona Highways July 1971 issue, “The Enduring Intrigue of
      the Glass Trade Bead,” by Cloyd Sorensen and C. Richard LeRoy, that focused on glass trade
      beads, with illustrations from LeRoy’s collection. The Hubbell-glass necklace to the left appears at
      the far right of the picture.`,
      src: img4,
      caption: ``,
    },
    {
      title: `Hubbell-glass beads, date unknown`,
      src: img5,
      caption: `
              ASM purchase from Michael and Mimi Haggerty, 2007
              L: 2.7 cm (pear-shaped bead)
              2007-541-2`,
    },
    {
      title: `Hubbell-glass nuggets, date unknown`,
      src: img6,
      caption: `
              ASM purchase from Michael and Mimi Haggerty, 2007
              L: 2.0 cm; R: 2.2 cm
              2007-541-3-x-1 and x-2`,
    },
    {
      title: `Block turquoise`,
      src: img7,
      caption: `
            ASM purchase, Tucson Gem and Mineral Show, 2000
            2000-97`,
    },
    {
      title: `Plastic earrings simulating mosaic, 2000`,
      src: img8,
      caption: `
            Pueblo style, commercially manufactured
            Gift of Diane Dittemore, 2000
            L: 5.5 cm
            2000-168-1a, b
            
            ASM Associate Curator Diane Dittemore purchased these earrings from a vendor in Santa Fe, NM. Such plastic versions, rarely so identified, have infiltrated the Native jewelry market extensively over the past few decades.`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Turquoise_Substitutes;
