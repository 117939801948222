import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-2/subheading 2-2-3/2-2c_photobox1-1.jpg";
import img2 from "./heading2/subheading 2-2/subheading 2-2-3/2-2c_photobox-1-2.jpg";
import img3 from "./heading2/subheading 2-2/subheading 2-2-3/2-2c_photobox-2.jpg";
import img4 from "./heading2/subheading 2-2/subheading 2-2-3/2-2c_photobox-3.jpg";
import img5 from "./heading2/subheading 2-2/subheading 2-2-3/2-2c_photobox-4.jpg";
import img6 from "./heading2/subheading 2-2/subheading 2-2-3/2-2c_photobox-5.jpg";

const Mogollon_Shell = () => {
  const title = "Mogollon Shell";

  const details =
    `“Mogollon” is a term that archaeologists use to refer to the ancient peoples inhabiting the mountains of Arizona and New Mexico and the Chihuahuan Desert from about 200 to 1450 CE. Some use “Mogollon Pueblo” as a name for these groups beginning about 1250 CE, when many moved into large, multi-room stone pueblos such as Kinishba and Grasshopper Pueblo. The artisans in these communities were best known for fashioning animal figures and elaborate hairpins from various types of stone and animal bone.
    
    Prior to about 1000 CE, there was relatively little shell in the Mogollon area. The quantity and diversity of shell increased considerably after that time, particularly in the Mimbres area of southwestern New Mexico and far east-central Arizona. Before about 1200 CE, the most likely sources of marine shell found in Mogollon communities were trade networks tied to the Hohokam (the groups who inhabited the desert regions of southern Arizona prior to the arrival of Europeans). Most shell found at Mogollon sites has been recovered from mortuary or ritual deposits.
    
    Many shell objects, including raw material for making jewelry and finished artifacts, have been recovered from the large, late Mogollon (or Mogollon Pueblo) communities of the mountains of Arizona. These include Kinishba, Grasshopper Pueblo, and Point of Pines Pueblo. The shell found at these villages was obtained through exchange networks connected to the Hohokam and/or Paquimé (a very large and complex late prehispanic community in northwestern Chihuahua, Mexico).`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Frog-effigy shell pendant, C. 1345–1362 CE
      Highland Mogollon`,
      src: img1,
      caption: `
        Glycymeris gigantea shell
        Red Rock House, Oak Creek, Fort Apache Indian Reservation, AZ
        UA Laboratory of Tree-Ring Research Project, 1964
        W: 5.5 cm
        A-23646`,
    },
    {
      title: `Frog-effigy shell pendant, C. 1345–1362 CE
      Highland Mogollon`,
      src: img2,
      caption: `
      Glycymeris gigantea shell
      Red Rock House, Oak Creek, Fort Apache Indian Reservation, AZ
      UA Laboratory of Tree-Ring Research Project, 1964
      W: 5.5 cm
      A-23646`,
    },
    {
      title: `Glycymeris-shell pendant, C. 1275–1375 CE
      Highland Mogollon`,
      src: img3,
      caption: `
        Glycymeris shell
        Grasshopper Pueblo, Cibecue vicinity, Fort Apache Indian Reservation, AZ
        UA Archaeological Field School, 1964
        L: 4.5 cm
        A-23944`,
    },
    {
      title: `Haliotis (abalone)-shell bead/pendant, C. 1275–1375 CE
      Highland Mogollon`,
      src: img4,
      caption: `
        Haliotis cracherodii (black abalone) shell
        Grasshopper Pueblo, Cibecue vicinity, Fort Apache Indian Reservation, AZ
        UA Archaeological Field School, 1967
        L: 3.3 cm
        A-29225`,
    },
    {
      title: `Frog-effigy mosaic pendant, C. 1225–1425
      Highland Mogollon`,
      src: img5,
      caption: `
        Glycymeris shell, turquoise
        Kinishba, Whiteriver, Fort Apache Indian Reservation, AZ
        University Expedition, before 1945
        L: 4 cm
        26365`,
    },
    {
      title: `Frog-effigy mosaic pendant, C. 1200–1400 CE
      Highland Mogollon`,
      src: img6,
      caption: `
        Glycymeris shell, turquoise
        Kinishba, Whiteriver, Fort Apache Indian Reservation, AZ
        University Expedition, before 1945
        L: 8.4 cm
        6748`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img6}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Mogollon_Shell;
