import React from "react";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-1/subheading 2-1-6/2-1f_photobox-1-1.jpg";
import img2 from "./heading2/subheading 2-1/subheading 2-1-6/2-1f_photobox-1-2.jpg";
import img3 from "./heading2/subheading 2-1/subheading 2-1-6/2-1f_photobox-1-3.jpg";
import img4 from "./heading2/subheading 2-1/subheading 2-1-6/2-1f_photobox-2-1.jpg";
import img5 from "./heading2/subheading 2-1/subheading 2-1-6/2-1f_photobox-2-2.jpg";
import img6 from "./heading2/subheading 2-1/subheading 2-1-6/2-1f_photobox-2-3.jpg";
import img7 from "./heading2/subheading 2-1/subheading 2-1-6/2-1f_photobox-1-4.jpg";

const Stabilized_Turquoise = () => {
  const title = "Stabilized Turquoise";

  const details =
    `Another approach to addressing limited supplies of good-quality turquoise is to treat lighter-colored, softer stones, which comprise up to 85 percent of the mined turquoise. This can include simply soaking the stone in oil or dyeing to enhance its color.

    In 1948, Colbaugh Processing, Inc., of Golden Valley outside of Kingman, Arizona, developed a means of hardening and darkening turquoise by infusing the stone with polymers. This process produces stabilized turquoise.

    An estimated 3 percent of the turquoise mined today is considered high grade. Stabilization produces a larger supply of turquoise for everything from jewelry to bathroom tiles. This process has especially facilitated the making of fine turquoise heishi beads.

    ASM curators visited the Colbaugh Processing company in Golden Valley while conducting research for the 2007 exhibit Set in Stone. They obtained photographs, raw materials, and examples of turquoise strands dyed in assorted colors.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos_one = [
    {
      title: `Colbaugh Processing, Inc., Golden Valley, AZ`,
      src: img1,
      caption: `
          Jannelle Weakly, photographer, 2007
          ASM 2021-229-image38`,
    },
    {
      title: `The mining operation at Turquoise Mountain, Golden Valley, AZ`,
      src: img2,
      caption: `
          Jannelle Weakly, photographer, 2007
          ASM 2021-229-image10`,
    },
    {
      title: `Unstabilized (raw) turquoise from Turquoise Mountain, Colbaugh Processing, Inc., Golden`,
      src: img3,
      caption: `
            Valley, AZ
            Arthur Vokes, photographer, 2007
            ASM 2021-229-image83`,
    },
    {
      title: `Strands of stabilized coral, turquoise, and other stones at Colbaugh Processing, Inc., Golden Valley, AZ`,
      src: img4,
      caption: `
              Arthur Vokes, photographer, 2007
              ASM 2021-229-image84`,
    },
    {
      title: `Stabilized/dyed Kingman turquoise disk bead strand, 2007`,
      src: img5,
      caption: `
            Colbaugh Processing, Inc., Kingman, AZ
            ASM purchase, 2007
            L: 19 cm
            2007-487-38)`,
    },
    {
      title: `“Mohave Green” stabilized/dyed turquoise beads`,
      src: img6,
      caption: `
            Colbaugh Processing, Inc., Kingman, AZ
            ASM purchase, 2007
            L: 16 cm
            2007-487-40`,
    },
    {
      title: `Stabilized/dyed Kingman purple turquoise strand, 2007`,
      src: img7,
      caption: `
            Colbaugh Processing, Inc., Kingman, AZ
            ASM purchase, 2007
            L: 19.5 cm
            2007-487-42`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img4}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Stabilized_Turquoise;
