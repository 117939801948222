import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img2 from "./heading1/c-4920_scan1.jpg";
import img3 from "./heading1/2000-186-004_fr_xl.jpg";
import img4 from "./heading1/2000-186-023_fr_xl.jpg";
import img5 from "./heading1/28793_scan3-adj.jpg";
import img6 from "./heading1/95-128-142_stone-bowl_comp1.jpg";
import img7 from "./heading1/2000-186-132_fr_xl.jpg";
import img8 from "./heading1/2006-905-72_scan1-adj.jpg";
import img9 from "./heading1/46940_scan1_adj.jpg";
import img10 from "./heading1/2000-186-159_fr_xl.jpg";
import img11 from "./heading1/2004-1447-1070_scan001.jpg";
import img12 from "./heading1/4099_p02.jpg";
import img13 from "./heading1/2004-1447-1081_scan001-crp.jpg";
import img14 from "./heading1/Messier-Hopi-0007.jpg";
import img15 from "./heading1/2000-186-018_fr_xl.jpg";
import img16 from "./heading1/AP-2011-250-147-x16_P01.jpg";

const Wearing_Jewelry = () => {
  const title = "Wearing Jewelry";

  const details =
    `There are few ways to determine how jewelry was worn or otherwise used in the ancient past. Archaeologists seek clues based on burial practices and painted murals, such as those sometimes found in kivas (subterranean ceremonial structures). Ceramic and stone human effigies also may be helpful indicators. Descendant communities who choose to share their knowledge, handed down through the generations, can provide information that may support or be at odds with archaeological inferences.

Beginning in the late 1800s, ethnographic research and photography documented the traditions of jewelry wearing among the Native peoples of the U.S. Southwest. When Native easel painters emerged after 1900, many rendered ceremonial and everyday scenes showing people adorned with jewelry appropriate for the occasion.
`.split("\n");

  const imageDescription = "";

  const photos = [
    {
      title: `Ramos Polychrome human-effigy jar with possible bracelets on wrists, C. 1300–1500 CE`,
      src: img8,
      caption: `
Terra cotta, mineral paints
Casas Grandes vicinity, Northern Sierra Province
Casas Grandes vicinity, Río Casa Grandes, Chihuahua, Mexico
Cummings purchase, Edward H. Ledwidge, 1934
H: 18.7 cm 
20676
`,
    },
    {
      title: `Carved censer, male and female figures wearing arm bracelets, C. 850–1000 CE`,
      src: img6,
      caption: `
      Sandstone
Hohokam, Gila Bend Province
Nichols Ranch Site, southern AZ
Gift of Norton and Ethel Allen, 1995
H: 15.5 cm 
1995-128-142
`,
    },
    {
      title: `Polacca Polychrome canteen, c. 1900
      Nampeyo, Hopi-Tewa`,
      src: img12,
      caption: `
Palhikwmana (Water-Drinking Maiden) wears turquoise mosaic earrings
First Mesa, Hopi Indian Reservation, AZ
Terra cotta, mineral paints
Dr. Joshua A. Miller Collection
Gift of Arizona Archaeological and Historical Society, 1917
H: 21.4 cm 
4099
`,
    },
    {
      title: `Images from kiva murals at the Tiwa pueblo of Kuaua depicting shell jewelry, c. 1300–1700 CE`,
      src: img16,
      caption: `
      (Dutton 1963:Plates 16 and 17).`,
    },
    {
      title: `Diné (Navajo) girl wearing turquoise-and-shell necklace with jacla (turquoise bead loop) pendants`,
      src: img11,
      caption: `
      Twenty-sixth Annual Tribal Fair, Window Rock, Navajo Nation, AZ
Helga Teiwes, photographer, 1972
ASM C-4920`,
    },
    {
      title: `Diné (Navajo) girl wearing blouse with silver buttons and a coral necklace`,
      src: img2,
      caption: `
      Navajo Nation, AZ
Unknown photographer, Wetherill Collection, c. 1920-1930
ASM 2004-1447-1081
`,
    },
    {
      title: `Mrs. Joe, John Yazzie, and Wolfkiller, Diné (Navajo)`,
      src: img13,
      caption: `
      Hacienda de la Osa, Sasabe, AZ
Unknown photographer, Wetherill Collection, c. 1924
ASM 2004-1447-1070

Note the assorted necklaces, concha belt, and bandolier or pouch strap.
`,
    },
    {
      title: `Margaret Tafoya, Kha'po Owingeh (Santa Clara) Pueblo`,
      src: img5,
      caption: `
      Wearing squash blossom necklace, Santa Fe Indian Art Market, Santa Fe, NM
Helga Teiwes, photographer, 1970
ASM 28793`,
    },
    {
      title: `Tom Awelagte, A:shiwi (Zuni)`,
      src: img9,
      caption: `
      Pueblo of Zuni, NM
Helga Teiwes, photographer, 1978
ASM 46940
`,
    },
    {
      title: `Hopi girls in costume for butterfly dance at 1940 All-Indian Pow-Wow`,
      src: img14,
      caption: `
      Flagstaff, AZ
Unknown photographer, 1940
Santa Fe Railway photograph, courtesy of Pat and Kim Messier
`,
    },
    {
      title: `Buffalo Dancer, 1967
      San Ildefonso Pueblo`,
      src: img3,
      caption: `
Gilbert Atencio (Wah-Peen)
Pueblo of San Ildefonso, NM
Watercolor on paper
Gift of Marjorie Pierce Avery, 2000
H: 51.8 cm (2000-186-4)

Note the silver-and-turquoise ketoh (bow guard).
`,
    },
    {
      title: `Hopi Mixed Dance, c. 1970
      Ray Naha, Hopi`,
      src: img10,
      caption: `
Hopi Indian Reservation, AZ
Watercolor on paperboard
Gift of Marjorie Pierce Avery, 2000
H: 61.4 cm 
2000-186-159

Note the dancers wearing turquoise-and-shell necklaces and ketohs (bow guards).
`,
    },
    {
      title: `Butterfly Prayer, 1991
      Acoma/Zia/A:shiwi (Zuni)`,
      src: img7,
      caption: `
Angelina Medina
Pueblo of Acoma, NM
Acrylic on canvas
Gift of Marjorie Pierce Avery, 2000
H: 35.4 cm 
2000-186-132
`,
    },
    {
      title: `Corn Grinding, 2000
      Diné (Navajo)`,
      src: img15,
      caption: `
Harrison Begay
Born in White Cone, AZ
Watercolor on paper
Gift of Marjorie Pierce Avery, 2000
H: 38.1 cm 
2000-186-18
`,
    },
    {
      title: `Navajo Power Plant, 1987
      Diné (Navajo)`,
      src: img4,
      caption: `
Shonto Begay
Shonto, Navajo Nation, AZ
Acrylic on canvas
Gift of Marjorie Pierce Avery, 2000
H: 80.5 cm 
2000-186-23
`,
    },
  ];
  return (
    <Content
      title={title}
      details={details}
      clickableImage={img3}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Wearing_Jewelry;
