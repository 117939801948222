import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img23 from "./heading3/subheading 3-3/subheading 3-3-1/3-3 photobox 1-1.jpg";
import image from "./heading3/subheading 3-3/subheading 3-3-1/subheading 3-3-2/3-3 photobox 1-2.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Authenticity_Challenges = () => {
  const title = "Authenticity Challenges";

  const imageDescription = "";

  const photos = [
    {
      title: `Silver-and-turquoise spoon, c. 1950
       Diné (Navajo)`,
      src: img23,
      caption: `
            UITA stamp for silver-and-turquoise spoon, c. 1950
            ASM purchase, Friends of the ASM Collections funds, 2020
            L: 20.1 cm
            2020-414-1`,
    },
    {
      title: `Silver-and-turquoise spoon, c. 1950
       Diné (Navajo)`,
      src: image,
      caption: `
      UITA stamp for silver-and-turquoise spoon, c. 1950
            ASM purchase, Friends of the ASM Collections funds, 2020
            L: 20.1 cm
            2020-414-1`,
    },
  ];
  return (
    <Content
      title={title}
      specialRender={
        <>
          <span>
            {
              "`The popularity of Native jewelry in the 1930s led some companies to mass-produce jewelry using machines that sped up the process. These companies did not always employ Native Americans to operate the machinery. Seeing this as deception and unfair competition, the United Indian Traders Association (UITA), founded in 1931, attempted to address this issue by doing battle over the definition of “Indian-made jewelry.” In 1946, the UITA started a program of stamping jewelry with the maker’s tribal affiliation and a unique code for the trading post from which the jewelry originated. The organization disbanded in 1998. For more information, press "
            }
            <a href="http://library.nau.edu/speccoll/exhibits/traders/uita/index.html">
              here.
            </a>
            <br />
            <br />
            <span>
              {`In response to an increase in the amount of jewelry and other crafts being made by non-Indians but sold as Native American- made, in 1990 Congress passed the American Indian Arts and Crafts Act, “a truth-in-advertising law that prohibits misrepresentation in marketing of Indian arts and crafts products within the United States.” Native artists and craftspeople have a strong interest in guarding their rich artistic traditions, and dealers and other buyers deserve no less than to trust that works sold as authentic truly are. For more information about the Act, follow this `}
              <a href="https://www.doi.gov/iacb/act">link.</a>
            </span>
            <br />
          </span>
        </>
      }
      clickableImage={image}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Authenticity_Challenges;
