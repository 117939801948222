import React from "react";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-1.jpg";
import img2 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-2.jpg";
import img3 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-3-1.jpg";
import img4 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-3-2.jpg";
import img5 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-3-3.jpg";
import img6 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-4.jpg";
import img7 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-5-1.jpg";
import img8 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-5-2.jpg";
import img9 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-5-3.jpg";
import img10 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-6.jpg";
import img11 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-7.jpg";
import img12 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-8.jpg";

const Turquoise_Mosaics = () => {
  const title = "Turquoise Mosaics";

  const details =
    `Less common than beads in the ancient record are turquoise mosaics, composite artifacts made of small, flat tiles (tesserae) that are affixed to a backing such as shell, wood, or basketry. The tesserae, which are mostly turquoise but can be made of shell or other materials, are arranged to create an interlocking, plated surface. Mosaic ornaments found in the U.S. Southwest and Northern Mexico were derived from the traditions of Central Mexico.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos_one = [
    {
      title: `Turquoise-and-shell mosaic pendant, c. 1200–1400 CE
      Highland Mogollon`,
      src: img1,
      caption: `
      Turquoise, Spondylus shell, and Dosinia ponderosa shell (backing)
          Kinishba, Whiteriver, Fort Apache Indian Reservation, AZ
          University Expedition, 1933
          L: 8.7 cm
          6748
          
          "This [red center] represents the heart, we call it ikenane, and this frog also had a heart. So even [this] turquoise [mosaic] would [be considered] a very significant entity—it has a heart ... and whoever adorned this also had that spirit." - Ronnie Cachini, A:shiwi (Zuni; Hedquist 2017:230–231)

          "Frogs [paavakwt] are the life of the clouds and the rain that is put on earth. Especially after thunderstorms, they come out. Hopis say it rains frogs. So [frogs] are always associated with clans that have water as their totem." - Leigh J. Kuwanwisiwma, Hopi (Hedquist 2017:257)`,
    },
    {
      title: `Turquoise mosaic pendant on shell, 1960
      “Mrs. Stevens,” A:shiwi (Zuni)`,
      src: img2,
      caption: `
          Pueblo of Zuni, NM
          Nevada turquoise, shell
          ASM purchase from Tom Bahti Indian Arts, 1960
          L: 14.3 cm
          E-4411
          
          Pueblo ceremonial dancers continue to wear such large, turquoise mosaic pendants.

          A longtime trader at Zuni, Micky Vander Wagen, provided this recollection when asked by Tom Bahti’s son, Mark, if he knew this object’s maker: "A Zuni [lapidarist] who went by Stevens married...a Pincion. [I] think either she or her daughter was the mother of Rose Pincion, one of the best inlayers ever—she taught Jack Livingston the inlay craft and many others."`,
    },
    {
      title: `One side of two-sided turquoise-and-shell mosaic necklace, 2009
      Charlene Reano, Katishtya (San Felipe), and Frank Reano, Kewa (Santo Domingo)`,
      src: img3,
      caption: `
            Kewa Pueblo, NM
            Turquoise, Spondylus sp. shell
            Friends of the ASM Collections Hartman Lomawaima Memorial Purchase Award
            Southwest Indian Art Fair, 2009
            L: 18 cm
            2009-128-1`,
    },
    {
      title: `One side of two-sided turquoise-and-shell mosaic necklace, 2009
      Charlene Reano, Katishtya (San Felipe), and Frank Reano, Kewa (Santo Domingo)`,
      src: img4,
      caption: `
            Kewa Pueblo, NM
            Turquoise, Spondylus sp. shell
            Friends of the ASM Collections Hartman Lomawaima Memorial Purchase Award
            Southwest Indian Art Fair, 2009
            L: 18 cm
            2009-128-1`,
    },
    {
      title: `Charlene Reano, Katishtya (San Felipe), holding the award-winning necklace that she made in collaboration with her husband Frank Reano, at the Southwest Indian Art Fair, Arizona State`,
      src: img5,
      caption: `
            Museum, Tucson, AZ
            Jannelle Weakly, photographer, 2009

            Charlene and her late husband, Frank Reano, who died in 2021, rank among the best contemporary mosaic jewelry artists. Charlene, from Katishtya Pueblo (San Felipe), and Frank, from Kewa Pueblo (Santo Domingo), collaborated on exquisitely patterned mosaic pieces that were both highly contemporary and grounded in centuries-old traditions.

            "My inspiration stems from the jewelry worn during the traditional dances. I take pride in my work and strive to continue to grow in my designs and within my own spirit." - Charlene Reano, Katishtya (San Felipe).

            Frank and Charlene specialized in two-sided, reversible pieces. This show-stopping example was purchased by ASM at the 2009 Southwest Indian Art Fair. Since Frank’s death, Charlene has continued to make jewelry, partnering with younger relatives.`,
    },
    {
      title: `Turquoise mosaic shell necklace, c. 1990
      Charlene Reano, Katishtya (San Felipe), and Frank Reano, Kewa (Santo Domingo)`,
      src: img6,
      caption: `
            Kewa Pueblo, NM
            Turquoise, Spondylus sp. shell, abalone shell, jet
            Gift of Grace Niemcziek, 2000
            L: 40 cm
            2000-91-68`,
    },
    {
      title: `Turquoise and shell side of two-sided mosaic pendant, 2020
      Mary Lovato, Kewa (Santo Domingo) Pueblo`,
      src: img7,
      caption: `
            Kewa Pueblo, NM
            Turquoise, Spondylus shell, jet, silver
            ASM purchase, Friends of the ASM Collections funds, 2021
            L: 7.3 cm
            2021-91-1`,
    },
    {
      title: `Dragonfly side of two-sided shell mosaic pendant, 2020
      Mary Lovato, Kewa (Santo Domingo) Pueblo`,
      src: img8,
      caption: `
            Kewa Pueblo, NM
            Turquoise, Spondylus shell, jet, silver
            ASM purchase, Friends of the ASM Collections funds, 2021
            L: 7.3 cm
            2021-91-1`,
    },
    {
      title: `Mary C. Lovato, Kewa (Santo Domingo) Pueblo, Santa Fe Indian Market, Santa Fe, NM`,
      src: img9,
      caption: `
            Jannelle Weakly, photographer, 2021

            "My forte is inlaid shell earrings and pendants, a blend of the contemporary and the traditional.

            "Her work has become the standard by which shell inlaid jewelry is measured." - Artist statement, August 2021

            Mary Lovato (b. 1936) is an acclaimed shell mosaic artisan from Kewa (Santo Domingo) Pueblo. She frequently borders the contrasting center tiles of her mosaics in silver.`,
    },
    {
      title: `Turquoise mosaic earrings on wood, c. 1900
      Hopi`,
      src: img10,
      caption: `
            Hopi Indian Reservation, AZ
            Turquoise, abalone shell, wood, adhesive
            ASM purchase, Nelle A. Dermont Collection, 1919
            L: 4.3 cm
            3249 a and b
            Note that some of the mosaic pieces consist of broken perforated beads`,
    },
    {
      title: `Turquoise mosaic earrings on wood, 2007
      Ava Marie Coriz (1948–2011), Kewa (Santo Domingo)`,
      src: img11,
      caption: `
            Kewa Pueblo, NM
            Turquoise, Spondylus sp. shell, wood
            ASM purchase from Medicine Man Gallery, Tucson, 2007
            L: 3.3 cm
            2007-815-1
            
            These are revivals of traditional Pueblo turquoise mosaic earrings backed with wood.`,
    },
    {
      title: `Turquoise-and-lapis-lazuli mosaic earrings, 2021
      Lyle Balenquah, Hopi`,
      src: img12,
      caption: `
            Hopi Indian Reservation, AZ
            Turquoise, lapis lazuli, cottonwood root
            ASM purchase, Friends of the ASM Collections funds, 2021
            L: 4 cm
            2021-242-1 a and b
            
            "I draw inspiration for my jewelry directly from my experience working as an archaeologist in the American Southwest. Through this work, I am able to see firsthand how my ancestors used natural materials to express themselves through their personal adornment." - Lyle Balenquah, Hopi`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Turquoise_Mosaics;
