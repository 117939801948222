import image from "./heading4/subheading 4-4/4-4_photobox-1-2.jpg";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img2 from "./heading4/subheading 4-4/4-4_photobox-1-2.jpg";
import img3 from "./heading4/subheading 4-4/4-4_photobox-1-3.jpg";
import img4 from "./heading4/subheading 4-4/4-4_photobox-2.jpg";
import img5 from "./heading4/subheading 4-4/4-4_photobox-3-1.jpg";
import img6 from "./heading4/subheading 4-4/4-4_photobox-3-2.jpg";
import img7 from "./heading4/subheading 4-4/4-4_photobox-4-1.jpg";
import img8 from "./heading4/subheading 4-4/4-4_photobox-4-2.jpg";
import img9 from "./heading4/subheading 4-4/4-4_photobox-5-1.jpg";
import img10 from "./heading4/subheading 4-4/4-4_photobox-5-2.jpg";
import img11 from "./heading4/subheading 4-4/4-4_photobox-6-1.jpg";
import img12 from "./heading4/subheading 4-4/4-4_photobox-6-2.jpg";

const White_Hogan_Kenneth_Begay_and_Others = () => {
  const title =
    "The Patania Family and the Thunderbird Shop in Santa Fe and Tucson";

  const details =
    `Frank Patania Jr. (b. 1932) is a renowned jewelry designer whose father established the Thunderbird Shop in Santa Fe, New Mexico, and Tucson, Arizona. Frank Patania Sr. (1899–1964) had apprenticed to a goldsmith while still living in the family’s native Palermo, Italy. He emigrated to the U.S. in 1909 and spent time in New York before moving to Santa Fe for his health in 1923. He opened the first Thunderbird Shop in Santa Fe in 1927, and another shop in Tucson in 1937. Frank Jr. grew up in the trade. The Patanias employed Native jewelers as demonstrators and workshop smiths at both shops. In Tucson, during the 1970s, two of these Native artisans were Jimmie Herald (Diné [Navajo]) and Daniel Enos (Tohono O’odham). The jewelry all bore the stamped hallmark “FP.”`.split(
      "\n"
    );

  const imageDescription = "";

  const photos_one = [
    {
      title: `Sam and Frank Patania`,
      src: img2,
      caption: `
      Brochure from the Thunderbird Shop, Tucson, AZ, c. 1980
      Patania Family Archive, scan courtesy Pat and Kim Messier`,
    },
    {
      title: `Frank Patania Jr., Tucson, AZ`,
      src: img3,
      caption: `
      Unknown photographer, c. 1980
      Patania Family Archive, scan courtesy Pat and Kim Messier`,
    },
    {
      title: `Jimmie Herald, Diné (Navajo)`,
      src: img4,
      caption: `
      Jimmie Herald, Diné (Navajo), who worked for Frank Patania, Jr. at the Thunderbird Shop in Tucson, demonstrates at Arizona State Museum Open House, Tucson, AZ.
      Helga Teiwes, photographer, c. 1969
      ASM 22644`,
    },
    {
      title: `Turquoise ring with Bisbee turquoise, c. 1970
      Frank Patania, Italian American/Jimmie Herald, Diné (Navajo)`,
      src: img5,
      caption: `
      Robert Tierney Collection
      Gift of the University of Arizona Foundation, 1980
      D: 2.6 cm
      1980-47-188`,
    },
    {
      title: `Hallmark for turquoise ring with Bisbee turquoise, c. 1970
      Frank Patania, Italian American/Jimmie Herald, Diné (Navajo)`,
      src: img6,
      caption: `
      Robert Tierney Collection
      Gift of the University of Arizona Foundation, 1980
      D: 2.6 cm
      1980-47-188`,
    },
    {
      title: `Belt buckle with Number Eight turquoise, c. 1976
      Frank Patania, Italian American/Jimmie Herald, Diné (Navajo)`,
      src: img7,
      caption: `
      Robert Tierney Collection
      Gift of the University of Arizona Foundation, 1980
      W: 7.6 cm
      1980-47-262`,
    },
    {
      title: `Hallmark for belt buckle with Number Eight turquoise, c. 1976
      Frank Patania, Italian American/Jimmie Herald, Diné (Navajo)`,
      src: img8,
      caption: `
      Robert Tierney Collection
      Gift of the University of Arizona Foundation, 1980
      W: 7.6 cm
      1980-47-262`,
    },
    {
      title: `Shadowbox silver cuff bracelet, c. 1970
      Frank Patania, Italian American`,
      src: img9,
      caption: `
      W: 1.1 cm
      2020-426-4`,
    },
    {
      title: `Hallmark for shadowbox silver cuff bracelet, c. 1970
      Frank Patania, Italian American`,
      src: img10,
      caption: `
      W: 1.1 cm
      2020-426-4
      
      This modern-design cuff is signed “FP” and could have been made by a Native artist working for Frank Patania, such as Jimmie Herald (Diné [Navajo]) or Daniel Enos (Tohono O’odham).`,
    },
    {
      title: `Silver bola tie, Tucson Sunshine Climate Club, date unknown
      Frank Patania Jr., (b. 1932), Italian American`,
      src: img11,
      caption: `
      Thunderbird Shop, Tucson, AZ
      Courtesy Pat and Kim Messier`,
    },
    {
      title: `Hallmark for silver bola tie, Tucson Sunshine Climate Club, date unknown
      Frank Patania Jr., (b. 1932), Italian American`,
      src: img12,
      caption: `
      Thunderbird Shop, Tucson, AZ
      Courtesy Pat and Kim Messier
      The Tucson Sunshine Climate Club, a tourism promotion group, was founded in 1922.`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={image}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default White_Hogan_Kenneth_Begay_and_Others;
