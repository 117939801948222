import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-6/subheading 2-6-2/2-b_photobox-2.jpg";
import img2 from "./heading2/subheading 2-6/subheading 2-6-2/2-6b_photobox-2.jpg";
import img3 from "./heading2/subheading 2-6/subheading 2-6-2/2-6b_photobox-1-1.jpg";
import img4 from "./heading2/subheading 2-6/subheading 2-6-2/2-6b_photobox-3-2.jpg";
import img5 from "./heading2/subheading 2-6/subheading 2-6-2/206b_photobox-3-3.jpg";
import img6 from "./heading2/subheading 2-6/subheading 2-6-2/2-6b_photobox-3-4.jpg";

const Copper_and_Brass = () => {
  const title = "Copper and Brass";

  const details =
    `Copper, in the form of bells, was most likely imported into the U.S. Southwest and Northern Mexico from the area known as West Mexico: the modern states of Nayarit, Jalisco, Colima, Michoacan, and Guerrero. The earliest-known copper bells appeared in the region around 1000 CE. Initially, these were simple globular forms fashioned from native copper, but around 1250 CE, West Mexican craftspeople developed the ability to create alloys of copper, which allowed them to produce more-elaborate styles of bells.

    There is no evidence that the trade in copper bells from Mexico continued into the historic period. Different groups at different times obtained other metal items, including jewelry, through trade from Mexico and from the Plains. Some of it was acquired as war booty.

    Brass, an alloy of copper and zinc, did not appear in the region until the historic period, when wire of brass (as well as of copper) became a valued trade item.

    Diné (Navajo), Ute, and some Pueblo craftspeople first made simple metal jewelry from brass and copper wire they procured at trading posts. To create bracelets, they would twist lengths of thin wire together or use thicker wire, which was then frequently embellished with simple engraved designs.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Copper bells, C. 1100–1150 CE
      Hohokam, Tucson Province`,
      src: img1,
      caption: `
        Native copper
        Romo Site, Catalina Mountains, Coronado National Forest, Pima Co., AZ
        Romo transfer, 1953
        H: 2.2–2.4 cm
        A-9073
        
        The Romo Cache (see Stone section) consisted of a covered ceramic jar containing copper bells along with stone and shell beads.`,
    },
    {
      title: `Copper-wire and brass-wire bracelets, c. 1890
      Ute or Diné (Navajo)`,
      src: img2,
      caption: `
        Whiterocks, UT
        ASM purchase from Elizabeth Stanley, 1918
        L: 7.5–8.2 cm
        12188, 12189, 26565, 26566`,
    },
    {
      title: `Copper, brass, and silver Hopi clown pin, 2006
      Clarence Lee (1952–2011) and Russell Lee (b. 1976), Diné (Navajo)`,
      src: img3,
      caption: `
        Twin Lakes, NM
        ASM purchase, Wilma Kaemlein Memorial Purchase Award
        ASM Southwest Indian Art Fair, 2006
        L: 8.1 cm
        2006-163-1

        Russell Lee has commented about this pin: "This piece is hand drawn, hand sawed out with a jeweler’s coping saw. Details are made with a hammer and various different stamps. Inspiration comes from my Navajo background, our family, dreams and my imagination."`,
    },
    {
      title: `Hallmark for copper, brass, and silver Hopi clown pin, 2006
      Clarence Lee (1952–2011) and Russell Lee (b. 1976), Diné (Navajo)`,
      src: img4,
      caption: `
        Twin Lakes, NM
        ASM purchase, Wilma Kaemlein Memorial Purchase Award
        ASM Southwest Indian Art Fair, 2006
        L: 8.1 cm
        2006-163-1`,
    },
    {
      title: `Clarence Lee, Diné (Navajo), with his award-winning Hopi clown pin, Southwest Indian Art Fair`,
      src: img5,
      caption: `
          Arizona State Museum, Tucson, AZ
          Jannelle Weakly, photographer, 2006

          The father-son duo of Clarence and Russell Lee attended ASM’s Southwest Indian Art Fair for many years. They were known for what has come to be called storyteller jewelry; themes depicted include workaday slices of Navajo reservation life as well as ceremonial figures of neighboring Hopis, rendered in a mixture of metals and stones. Clarence Lee was born in Ft. Defiance, AZ, and raised in Twin Lakes, NM. He learned silverwork from his father, trading post operator Tom Lee`,
    },
    {
      title: `Russell Lee, Diné (Navajo), Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ`,
      src: img6,
      caption: `
          Emily Kleinkauf, photographer, 2008

          Clarence Lee’s son Russell Lee was born in 1976 and worked alongside his father in the family jewelry business. Since Clarence’s passing, Russell has continued to create jewelry with the same spirit.`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img3}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Copper_and_Brass;
