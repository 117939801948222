import React from "react";
import Preview from "../../../Components/Preview/Preview";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-1/subheading 2-1-1/2-1a_photobox-1.jpg";
import img2 from "./heading2/subheading 2-1/subheading 2-1-2/2-1b_photobox1-2.jpg";
import img3 from "./heading2/subheading 2-1/subheading 2-1-3/2-1c_photobox-1.jpg";
import img4 from "./heading2/subheading 2-1/subheading 2-1-4/2-1d_photobox-2-1.jpg";
import img5 from "./heading2/subheading 2-1/subheading 2-1-5/2-1e_photobox-1-1.jpg";
import img6 from "./heading2/subheading 2-1/subheading 2-1-6/2-1f_photobox-2-1.jpg";
import img7 from "./heading2/subheading 2-1/subheading 2-1-7/2-1g_photobox-4.jpg";
import img8 from "./heading2/subheading 2-1/91-33-1.jpg";

const Turquoise = () => {
  const title = "Turquoise: Blues of Water and Sky";

  const details =
    `Turquoise has been used in jewelry and rituals for millennia. There are numerous sources of this blue stone within the U.S. Southwest and Northern Mexico as well as in California and Nevada. However, people in many parts of the region would not have had direct access to this stone and instead would have relied on trade networks to obtain it. Turquoise was a highly valued trade commodity in Mesoamerica due to its rarity and its important symbolic associations.
`.split("\n");

  const imageDescription = "";

  const previews = [
    {
      image: img1,
      url: "/Meaning-of-Turquoise",
      text: "Meaning of Turquoise",
    },
    {
      image: img2,
      url: "/Turquoise-Beads-Pendants",
      text: "Turquoise Beads and Pendants",
    },
    {
      image: img3,
      url: "/Turquoise-Mosaics",
      text: "Turquoise Mosaics",
    },
    {
      image: img4,
      url: "/Turquoise-Meets-Silver",
      text: "Turquoise Meets Silver",
    },
    {
      image: img5,
      url: "/Turquoise-Substitutes",
      text: "Turquoise Substitutes",
    },
    {
      image: img6,
      url: "/Stabilized-Turquoise",
      text: "Stabilized Turquoise",
    },
    {
      image: img7,
      url: "/Sources-of-Turquoise",
      text: "Sources of Turquoise",
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img8}
      imageDescription={imageDescription}
      previews={previews}
    />
  );
};

export default Turquoise;
