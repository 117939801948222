import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-2/subheading 2-2-5/2-2e_photobox-1.jpg";
import img2 from "./heading2/subheading 2-2/subheading 2-2-5/2-2e_photobox-2.jpg";
import img3 from "./heading2/subheading 2-2/subheading 2-2-5/2-2e_photobox-3.jpg";

const Ancestral_Pueblo_Shell = () => {
  const title = "Ancestral Pueblo Shell";

  const details =
    `Archaeologists use the term “Ancestral Pueblo” to refer to the ancient peoples of the Four Corners region of the Colorado Plateau, encompassing southern Utah, northern Arizona, north- central and northwestern New Mexico, and southwestern Colorado, from around 1500 BCE to 1300 CE. Geographical terms, such as Kayenta, Mesa Verde, Chaco, and Cibola, are used as names for the Ancestral Pueblo people who inhabited different parts of this region.

    Before about 1200 CE, the most likely sources of marine shell found in Mogollon communities were trade networks tied to the Hohokam (the groups who inhabited the desert regions of southern Arizona prior to the arrival of Europeans). The shell found at post-1200 CE Ancestral Pueblo villages was obtained through exchange networks connected to the Hohokam and/or Paquimé (a very large and complex late prehispanic community in northwestern Chihuahua, Mexico).

    A few forms dominated shell artifacts found in Ancestral Pueblo sites. Olivella beads, made by removing the spire (pointy tip) of the shell, occurred throughout the sequence and were widely distributed. The vast majority of these were fashioned out of Olivella dama valves, a Gulf of California species, but a few examples of the Pacific coast Olivella biplicata are present. Cut-shell pendants, most often in geometric forms, were also found throughout the sequence.

    Glycymeris-shell bracelets or armlets were fairly common in the eastern portion of the Ancestral Pueblo region before about 1150 CE. It is likely that most of these bracelets came from the Hohokam via exchange networks involving the regional system centered on Chaco Canyon in northwestern New Mexico. Once that network declined, the source of these artifacts was essentially eliminated.

    Shell “tinklers,” manufactured from Conus and Oliva shells, did not appear in the Ancestral Pueblo area until about 1150 CE, but once they did, they spread quickly across the region. By 1300 CE, they were present at virtually all Ancestral Pueblo sites that had shell. We know from ethnographic sources that, in recent times, dancers have worn these shells in clusters on their bodies, and on their clothing, so that the shells rattle against each other. Tinklers are worn especially during ceremonial dances.

    ASM archaeologist E. Charles Adams (1991) has suggested that there was an increase in public ceremonialism during this period associated with the spread of the Katsina religion, and the rising popularity of tinklers may well have been related. Interestingly, this temporal pattern is paralleled in the Hohokam area.

    As is the case for the Mogollon, the ancient peoples of the mountains of Arizona and New Mexico and the Chihuahuan Desert (c. 200–1450 CE), most shell found at Ancestral Pueblo sites has been recovered from mortuary or ritual deposits.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Shell-and-stone necklace, C. 450–1300 CE
      Ancestral Pueblo, Kayenta Province`,
      src: img1,
      caption: `
        Whole-shell (Olivella), bilobate (unknown white marine shell), and disk (Chama echinata or Spondylus limbatus and stone) beads
        Sun Priest House (Chilchintaboko), Nitsie Canyon, northern AZ
        University Expedition, 1915
        L: 210 cm (total length)
        433-X`,
    },
    {
      title: `Haliotis (abalone) pendants, Before 1300 CE
      Ancestral Pueblo, Kayenta Province`,
      src: img2,
      caption: `
        Haliotis shell
        Tsegi-ot-sosi Canyon, near Kayenta, Navajo Co., AZ
        University Expedition, 1917
        L: 4.0 cm (largest)
        3217`,
    },
    {
      title: `Turquoise-and-Spondylus ear bobs, C. 450–1300 CE
      Ancestral Pueblo, Kayenta Province`,
      src: img3,
      caption: `
        Turquoise, Spondylus crassisquama shell
        Sun Priest House (Chilchintaboko), Nitsie Canyon, northern AZ
        University Expedition, 1915
        L: 3.3 cm
        431-a, b`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Ancestral_Pueblo_Shell;
