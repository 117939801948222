import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-1-1.jpg";
import img2 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-1-2.jpg";
import img3 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-2.jpg";
import img4 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-3-1.jpg";
import img5 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-3-2.jpg";
import img6 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-4.jpg";
import img7 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-5.jpg";
import img8 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-6-1.jpg";
import img9 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-6-2.jpg";
import img10 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-7.jpg";
import img11 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-8-1.jpg";
import img12 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_photobox-8-2.jpg";
import img13 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_clickable.jpg";

const Ancient_Shell = () => {
  const title = "Ancient Shell";

  const details =
    `Trade networks provided both unworked and finished shell artifacts to prehispanic populations in the U.S. Southwest. The Hohokam (c. 500–1400 CE) of southern Arizona were the principal suppliers of shell to other ancient peoples of the region, with the inhabitants of Paquimé—a very large and complex late prehispanic community in northwestern Chihuahua, Mexico—later taking over some of this role (after c. 1200 CE).

    Indigenous peoples in the U.S. Southwest had four distinct sources of shell available to them. Three of these were marine sources: the Pacific Ocean (off the California coast), the Gulf of California, and the Gulf of Mexico. The fourth source consisted of freshwater mollusks found in the region. By far, most of the shell employed in the manufacture of ornaments and other objects came from the sea.

    Although there is some overlap in species, the fauna from each of the three marine source areas tend to be distinctive. In some cases, particular species are restricted to only one source. For example, Haliotis (abalone) and Callianax biplicata (purple dwarf olive) occur only in the colder waters off the Pacific coast. In contrast, Spondylus (thorny oyster), all of the large conch shells that were used as trumpets (e.g., Melongena, Muricanthus, and Strombus), and many of the smaller univalves (single-shelled organisms, such as snails; e.g., Columbella major, Nassarius moestus, and Olivella dama) are restricted to the warm tropical waters of the Gulf of California, although some have related forms native to the Gulf of Mexico.

    The earliest shellwork in the U.S. Southwest is from Early Agricultural period (c. 1200 BCE–50 CE) settlements in southern Arizona. Most identified species originated in the Gulf of California, but a significant number were from the Pacific, primarily abalone but also olive shells (Callianax biplicata) and other California coastal univalves. The most common jewelry components found at these sites are small, whole, univalve shell beads and small univalve beads made by removing the pointy tip of the shell, known as the spire.

    Pendants, both whole shells and those carved into geometric shapes, are the second most common kind of artifact present. A distinctive feature of the Early Agricultural period is the virtual absence of bracelets made from Glycymeris shells or the shells of other bivalves (two-shelled organisms, such as clams, oysters, and scallops).

    Most of the nonlocal shell that came into the region during prehispanic times dates to the period between about 500 CE and 1450 CE. In southern Arizona, this is the Hohokam era, when the most ubiquitous shell artifact was the plain bracelet made of Glycymeris.

    There are different patterns of shell-species procurement and shell-artifact manufacture and use associated with other prehispanic groups, such as those referred to as Mogollon (the ancient peoples of the mountains of Arizona and New Mexico and the Chihuahuan Desert) andAncestral Pueblo (the ancient peoples of the Four Corners region of the Colorado Plateau). Archaeologists infer, however, that all marine-shell raw material and most finished objects of shell jewelry made their way to early Mogollon and Ancestral Pueblo communities through exchange networks linked to the Hohokam. Archaeologists believe that late Mogollon and Ancestral Pueblo communities obtained these goods as a result of trade involving the Hohokam and/or the residents of Paquimé.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Exterior of unmodified Pacific thorny oyster bivalve shell`,
      src: img1,
      caption: `
        Spondylus crassisquama
        Purchased from Red Shell Jewelry, Gallup, NM
        ASM purchase, 2007
        L: 12.5 cm
        2007-760-1-a`,
    },
    {
      title: `Interior of unmodified Pacific thorny oyster bivalve shell`,
      src: img2,
      caption: `
      Spondylus crassisquama
      Purchased from Red Shell Jewelry, Gallup, NM
      ASM purchase, 2007
      L: 12.5 cm
      2007-760-1-a`,
    },
    {
      title: `Polished Pacific thorny oyster bivalve shell`,
      src: img3,
      caption: `
        Spondylus crassisquama
        Purchased from Red Shell Jewelry, Gallup, NM
        ASM purchase, 2007
        L: 9.8 cm
        2007-760-3`,
    },
    {
      title: `Exterior of unmodified donkey thorn oyster bivalve shell`,
      src: img4,
      caption: `
        Spondylus limbatus
        Purchased from Red Shell Jewelry, Gallup, NM
        ASM purchase, 2007
        L: 13.5 cm
        2007-760-2-b`,
    },
    {
      title: `Interior of unmodified donkey thorn oyster bivalve shell`,
      src: img5,
      caption: `
        Spondylus limbatus
        Purchased from Red Shell Jewelry, Gallup, NM
        ASM purchase, 2007
        L: 13.5 cm
        2007-760-2-b`,
    },
    {
      title: `Regular cone univalve shell`,
      src: img6,
      caption: `
        Conus regularis
        Range: Gulf of California to Peru
        ASM purchase, Comparative Collection, 1966
        L: 4.8 cm
        C-47-X-17`,
    },
    {
      title: `Giant olive univalve shell`,
      src: img7,
      caption: `
        Oliva incrassata
        Range: Gulf of California to Peru
        Comparative Collection, source and date unknown
        L: 5 cm
        C-183`,
    },
    {
      title: `Exterior of Giant Pacific cockle bivalve shell`,
      src: img8,
      caption: `
        Laevicardium elatum
        Range: San Pedro, California, to Panama
        Comparative Collection, source and date unknown
        L: 13.7 cm
        C-189`,
    },
    {
      title: `Interior of Giant Pacific cockle bivalve shell`,
      src: img9,
      caption: `
        Laevicardium elatum
        Range: San Pedro, California, to Panama
        Comparative Collection, source and date unknown
        L: 13.7 cm
        C-189`,
    },
    {
      title: `Green abalone univalve shell`,
      src: img10,
      caption: `
        Haliotis fulgens
        Range: Point Conception, California, to northern Baja California
        Comparative Collection, source and date unknown
        W: 15 cm
        C-304`,
    },
    {
      title: `Exterior of American bittersweet bivalve shell`,
      src: img11,
      caption: `
        Glycymeris gigantea
        Range: Gulf of California to Acapulco, Mexico
        Comparative Collection, source and date unknown
        L: 7.6 cm
        1494`,
    },
    {
      title: `Interior of American bittersweet bivalve shell`,
      src: img12,
      caption: `
        Glycymeris gigantea
        Range: Gulf of California to Acapulco, Mexico
        Comparative Collection, source and date unknown
        L: 7.6 cm
        1494`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img13}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Ancient_Shell;
