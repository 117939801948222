import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img25 from "./heading3/subheading 3-2/subheading 3-2-1/3-2_photobox-1.jpg";
import img26 from "./heading3/subheading 3-2/subheading 3-2-2/3-2_photobox-2.jpg";
import img27 from "./heading3/subheading 3-2/subheading 3-2-3/3-2_photobox-3.jpg";
import img28 from "./heading3/subheading 3-2/subheading 3-2-4/3-2_photobox-4.jpg";
import image from "./heading3/subheading 3-2/subheading 3-2-3/3-2_photobox-3.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const ArtFairs = () => {
  const title = "Art Fairs";

  const details =
    `Native art fairs and competitions have significantly impacted Indigenous jewelry in the U.S. Southwest. They include Santa Fe’s Southwest Indian Fair (now the Santa Fe Indian Market) and the Gallup Inter-Tribal Indian Ceremonial, both of which premiered in 1922. The Museum of Northern Arizona followed in 1933 with its Hopi Show and has since expanded to host Diné (Navajo) and A:shiwi (Zuni) artisans. 
    
    The Heard Museum Guild hosted its first Indian Fair and Market in 1958, and the event continues to thrive. ASM sponsored the Southwest Indian Art Fair from 1998 to 2015. Through the generosity of Gene Waddell, who underwrote an acquisition award in memory of his father, B. C. Waddell, ASM was able to acquire works by some of the best jewelry artists who participated. 
    
    In addition to promoting quality craftsmanship, these events provide artists with opportunities to network with galley owners and other buyers, socialize, be inspired by each other’s work, and bask in the appreciation of the many attendees.
`.split("\n");

  const imageDescription = "";

  const photos = [
    {
      title: `Jewelry and other crafts displayed at the Gallup Inter-Tribal Indian Ceremonial, 1928`,
      src: img25,

      caption: `
      Arizona Historical Society, PC 144
        Van Valkenburgh Navajo Photograph Collection Box 1, Folder 13, 12437`,
    },
    {
      title: `Santa Fe Indian Market, 1977`,
      src: img26,

      caption: `
      Santa Fe, NM 
      Helga Teiwes, photographer
      ASM 46152`,
    },
    {
      title: `Crowds looking at jewelry, Southwest Indian Art Fair`,
      src: img27,

      caption: `
      Arizona State Museum
        Tucson, AZ
        Jannelle Weakly, photographer, 2004`,
    },
    {
      title: `Heard Museum Guild Indian Fair and Market`,
      src: img28,

      caption: `
      Phoenix, AZ 
      Susan Hamilton,  photographer, 2020`,
    },
  ];
  return (
    <Content
      title={title}
      details={details}
      clickableImage={image}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default ArtFairs;
