import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4e/3-4e photobox-1-1.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4e/3-4e photobox-1-2.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4e/3-4e photobox-2-1.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4e/3-4e photobox-2-2.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4e/3-4e photobox-3-1.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4e/3-4e photobox-3-2.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4e/3-4e photobox-4.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4e/3-4e photobox-5.jpg";
import Content from "../../../Components/Content/Content";

import GalleryComponent from "../../../Components/Gallery/Gallery";

const Cross_Dragonfly_Jewelry = () => {
  const title = "Cross Dragonfly Jewelry";

  const quote =
    `[Pueblo Indians] are exceedingly fond of pretty reliquaries, medals, crosses and rosaries, but this does not arise from Christian devoutness (except in a few cases) but from love of adornment.`.split(
      "\n"
    );

  const details = `
Franciscan missionary Fray Francisco Antanasio Dominguez, 1776 (Bird (1992:7). 

Cross motifs have been made in the U.S. Southwest since ancient times, predating the coming of Christianity. A cross might have signified the sun, stars, or the four directions; it also may have had a meaning that will never be known. Christian crosses, typically longer than wide, arrived in the region with the earliest Spanish missionaries. Rosaries with pendant crosses were common gifts to Indigenous peoples, particularly when they embraced the new religion, either in fact or superficially to avoid persecution while retaining their Native beliefs.

Pueblo silversmiths, especially those from the Pueblos of Laguna and Isleta, made silver cross necklaces beginning in the late 1800s. Frequently they created double-bar crosses, a variation that may have Spanish or French roots. That this type of cross is similar to traditional representations of the dragonfly may demonstrate religious syncretism, or melding of beliefs, among the Native peoples of the region. 

The dragonfly is associated with moisture, and dragonfly motifs are found on pottery, in jewelry, and in rock art of the region from ancient times to the present. 

Native silversmiths have continued to produce jewelry incorporating crosses, including double-bar varieties that may or may not have this dual significance.Realistically rendered dragonfly motifs find favor among many jewelry artisans as well.  
`.split("\n");

  const imageDescription = "";

  const photos = [
    {
      title: `Pendants`,
      src: img1,
      caption: `
      L: Dragonfly-effigy pendant 
Laevicardium elatum shell 
Hohokam, Gila Basin Province 
C. 1150–1450 CE 
Los Rectangulos Site, Santa Cruz Flats, Eloy vicinity, Pinal Co., AZ 
Gift of Charles F. B. Price, 1959 
L: 4.0 cm 
A-14852-X-1 
 
R: Cruciform (cross-shaped) mosaic pendant
Wood, turquoise 
Salado, Tonto-Roosevelt Province 
C. 1350–1450 CE 
Lower Ruin, Tonto Cliff Dwellings, Tonto Basin, Gila Co., AZ 
Gift of Fred Hawley, 1954 
L: 3.3 cm 
A-8899 

This cruciform turquoise mosaic pendant is one of many sources of evidence that crosses held significance for Native peoples in the U.S. Southwest prior to the arrival of Christianity.`,
    },
    {
      title: `Dragonfly petroglyph`,
      src: img2,
      caption: `
      Gila National Forest, NM
Diane Dittemore, photographer, 2021 
`,
    },
    {
      title: `Coral, silver, and steel cross necklace, before 1940
      Pueblo or Diné (Navajo)`,
      src: img3,
      caption: `
      AZ, NM, or UT 
Gift of Mary Cabot Wheelwright, 1942 
L: 81 cm 
E-1186 

According to donor Mary Cabot Wheelwright, this necklace was reputedly old and the work of Diné (Navajo). Wheelwright believed that this was a type of necklace given to the Diné by missionaries. It is likely Pueblo made, however, and the date of manufacture is undetermined. The round beads are machine-made steel. 
`,
    },
    {
      title: `An Acoma Woman, c. 1905`,
      src: img4,
      caption: `
      Edward S. Curtis, photographer
The North American Indian, Vol. 16, plate 572 `,
    },
    {
      title: `Silver cross necklace, before 1960`,
      src: img5,
      caption: `
      Rio Grande Pueblo, NM 
Gift of the Estate of Oskar Grunow, 1979
L: 42 cm 
1979-87-4`,
    },
    {
      title: `Nesjaja Hatali—Navaho, c. 1904`,
      src: img6,
      caption: `
      Wearing cross necklace along with shell necklaces 
Edward S. Curtis, photographer
The North American Indian, Vol. 1, plate 31`,
    },
    {
      title: `Silver dragonfly pendant, c. 2003
      Diné (Navajo)`,
      src: img7,
      caption: `
      Linda Marble (b. 1949)
Gift of Bobette Levy, 2003
L: 7.1 cm 
2003-967-1
`,
    },
    {
      title: `Cast-silver bola tie with dragonfly-and-basket design, 2009 
      Diné (Navajo)`,
      src: img8,
      caption: `
      Alex Beeshligaii
Tucson, AZ 
ASM purchase from the artist, 2009 
D: 5.4 cm (ornament)
2009-897-1 `,
    },
  ];

  return (
    <Content
      title={title}
      specialRender={
        <>
          <div className="quote">
            {quote.map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
          </div>
          {details.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </>
      }
      clickableImage={img5}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Cross_Dragonfly_Jewelry;
