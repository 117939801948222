import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-2/subheading 2-2-4/2-2d_photobox-1.jpg";
import img2 from "./heading2/subheading 2-2/subheading 2-2-4/2-2d photobox-2.jpg";
import img3 from "./heading2/subheading 2-2/subheading 2-2-4/2-2d_photobox-3.jpg";

const Paquime_Shell = () => {
  const title = "Paquime Shell";

  const details =
    `Paquimé (also known as Casas Grandes) is a southern Mogollon village in northwestern Chihuahua, Mexico. The shell artifacts associated with what archaeologists have called the early Viejo period at Paquimé (c. 700–1200 CE) are unremarkable. Beads account for more than 98 percent of the items, with the remainder being pendants of various forms and plain bracelets. There is virtually no evidence to indicate local production of shell artifacts, leading archaeologists to suggest that the inhabitants obtained them as finished ornaments through trade.

    This contrasts sharply with the following Medio period (c. 1200–1425 CE), when shell was remarkably abundant (nearly four million pieces were recovered) and many different kinds of shell artifacts were present. Beads were, by far, the most numerous, with one form—the Nassarius whole-shell bead—accounting for more than 95 percent of the artifacts found. The Nassarius shells probably came from the central part of the Gulf of California coast, near present-day Guaymas, Sonora.

    Also present are a wide variety of other artifacts, ranging from plain bracelets to elaborately carved bracelets and pendants, and substantial evidence for local production in the form of unmodified shells, unfinished pieces, and manufacturing debris. For example, in addition to the massive number of beads, more than 21,000 unmodified Nassarius shells were recovered. Relatively little shell material, less than 1 percent, was recovered from mortuary deposits or other ritual contexts.

    This suggests that much of the shell found at Paquimé was not produced for local consumption but, instead, was destined for trade or distribution elsewhere. Archaeologists infer that after about 1200 CE, the people of Paquimé began to supply shell to some Mogollon (the ancient peoples of the mountains of Arizona and New Mexico and the Chihuahuan Desert) and Ancestral Pueblo (the ancient peoples of the Four Corners region of the Colorado Plateau) groups.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Paquimé, CH D:9:1 (ASM), Casas Grandes, Chihuahua, Mexico`,
      src: img1,
      caption: `
        Emil W. Haury, photographer, 1959
        ASM C-14005`,
    },
    {
      title: `Nassarius-bead necklace, C. 1150–1450 CE
      Casas Grandes, Northern Sierra Province`,
      src: img2,
      caption: `
        Nassarius iodes and unidentified white marine shell, turquoise, serpentine
        Rancho Corralitos, Río Casas Grandes, Chihuahua, Mexico
        Gift of Gila Pueblo Foundation, 1950
        Ledwidge Collection
        GP-4229`,
    },
    {
      title: `Irregular Spondylus-bead pendant strand, C. 1150–1450 CE
      Casas Grandes, Northern Sierra Province`,
      src: img3,
      caption: `
        Spondylus crassisquama/Chama echinata shell
        Rancho Corralitos, Río Casas Grandes, Chihuahua, Mexico
        Gift of Gila Pueblo Foundation, 1950
        Ledwidge Collection
        L: 21 cm
        GP-4243`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img2}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Paquime_Shell;
