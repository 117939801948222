import React from "react";
import "./Preview.css";

const Preview = ({ image, url, text, customSize }) => {
  const defaultSize = "250px"; // Set this to your desired default size
  const size = customSize || defaultSize;
  return (
    <div className="preview">
      <a href={url} rel="noopener noreferrer">
        <div className="image-container" style={{ width: size, height: size }}>
          <img src={image} alt={text} />
          <div className="overlay-text">{text}</div>
        </div>
      </a>
    </div>
  );
};

export default Preview;
