import React from "react";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

//import img1 from "./heading2/subheading 2-1/subheading 2-1-7/2-1g_photobox-1-1.pdf";
import img2 from "./heading2/subheading 2-1/subheading 2-1-7/2-1g_photobox-1-2.jpg";
import img3 from "./heading2/subheading 2-1/subheading 2-1-7/2-1g_photobox-2.jpg";
import img4 from "./heading2/subheading 2-1/subheading 2-1-7/2-1g_photobox-3.jpg";
import img5 from "./heading2/subheading 2-1/subheading 2-1-7/2-1g_photobox-4.jpg";

const Sources_of_Turquoise = () => {
  const title = "Sources of Turquoise";

  const details = `Prehispanic Turquoise Sources
    
    In North America, most known sources of turquoise are in the southwestern U.S. and in northwestern Mexico. Evidence of prehistoric turquoise mining, in the form of excavated deposits and associated stone tools, is found across a broad area, including sites in Arizona, western California, Colorado, Nevada, New Mexico, and Utah. In Mexico, the sources are mostly located in the modern state of Sonora. While this distribution is widespread, these deposits are not evenly distributed across the landscape. There were undoubtedly additional sources that have been obscured by time and the impact of modern mining of associated copper deposits.

    The larger mining complexes are at Cerrillos, Azure/Tyrone, and Old Hachita in New Mexico; Mineral Peak and Canyon Creek in Arizona; and in the Halloran Springs area of California. Simple surface excavations and adit (shallow-excavation) mines are reported from a number of other locations across the Southwest.

    The largest concentration of known sources is in an area that includes southeastern Arizona, northern Sonora, and southwestern New Mexico. The remaining sources form a sparse linear pattern that follows the Rio Grande rift valley of New Mexico up into Colorado and across and down into southern Utah, central Nevada, and western California. The latter is part of a small western cluster that incorporates northwestern Arizona, southeastern California, and southern Nevada. There are additional sites in west-central and central Nevada.

    The turquoise trade was part of the extended exchange system that linked the southwestern U.S. and northwestern Mexico with central Mexico—where some of the earliest examples of turquoise artifacts, dating to around 600 BCE, have been found. The demand for turquoise in Mesoamerica grew over time, and there was an increasing interest among populations across the Southwest in using turquoise and other blue-green stones to fashion beads, pendants, and mosaics.

    Ancestral Pueblo use of turquoise greatly increased with the development of the Chaco regional system centered on Pueblo Bonito and other great house sites. These centers appear to have controlled much of the material originating from sources in the northern portions of the U.S. Southwest.

    With the decline of the Chacoan great house system in the late twelfth century, there was broader use of turquoise within Ancestral Pueblo communities, with certain individuals in most large villages and some in smaller settlements gaining access to this stone. Turquoise, however, apparently was acquired mostly by a limited number of high-status individuals. This is particularly true of mosaic pendants.`.split(
    "\n"
  );

  const imageDescription = "";

  const photos_one = [
    /*    {
      title: ``,
      src: img1,
      caption: "",
    },*/
    {
      title: `Table of possible turquoise sources`,
      src: img2,
      caption: `
      Possible sources of turquoise in ancient times based upon research of Phil C. Weigand and Garman Harbottle, from Vokes and Gregory 2007: Table 17.8.`,
    },
    {
      title: `Turquoise-and-argillite mosaic on shell pendant, C. 1250–1450`,
      src: img3,
      caption: `
            Turquoise, argillite (also called pipestone or claystone), Glycymeris shell
            Salado
            Keystone Ruin, Tonto Basin, Lake Roosevelt, Gila Co., AZ
            Gift of Gila Pueblo Foundation, 1950
            W: 5.9 cm
            GP-9895
            
            Such mosaic pendants were high-status items.`,
    },
    {
      title: `Herculano Montoya, of Cienega, NM, at the Tiffany Turquoise Mine near Turquoise Post`,
      src: img4,
      caption: `
              Cerrillos, NM
              Bill Lippincott, photographer, 1937–1939
              Palace of the Governors Photo Archive, New Mexico History Museum, Santa Fe, NM 005236
              Native Turquoise Mines

              In a version of the Kewa (Santo Domingo) creation story, when their people emerged from the underworld, two other groups of Pueblo peoples came with them. Before they parted to go their individual ways, the Kewa promised to make beads for these other Pueblo groups. Traders from Kewa (Santo Domingo) Pueblo supplied to many neighboring tribes turquoise that they had obtained from the Cerrillos Mine, which was on their traditional lands. It had been mined by Ancestral Pueblo peoples as far back as 900 CE.

              A small collection of rude stone hammers was obtained from the turquois mine in the Cerrillo Mountains, about 25 miles from Santa Fé. The products of this celebrated mine, which were objects of traffic all over New Mexico, as well as contiguous countries, probably formed one inducement which led to the Spanish conquest of this region. The turquoises from this mine have always been valued as ornaments by the Indians of New Mexico, and carried far and wide for sale by them. The mine was worked in a most primitive manner with these rude stone hammers, a number of which were secured (Stevenson 1883:433).

              The mines of Los Cerrillos are not far away, and the Santo Domingo have used them for many years, indeed claiming them as their property (Bedinger 1973:171).

              With the coming of Euroamerican miners to the Cerrillos area in the 1880s, the Pueblo lost its rights to mine for turquoise there. Since the mines at Cerrillos have moved into private hands, there have been struggles on the part of Native people to secure access to the area’s turquoise. Today, many of the turquoise veins are exhausted, but some claims continue to be worked.`,
    },
    {
      title: `Turquoise of the Southwest map, courtesy Winfield Trading Company, 2017`,
      src: img5,
      caption: `
            Over the last 30 years, Native jewelers have obtained much of their turquoise through local traders at posts and in nearby towns. With easier transportation, internet commerce, and access to major expos, such as the Tucson Gem and Mineral Show, many more options are now available for procuring turquoise that originates from all corners of the world.`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img5}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Sources_of_Turquoise;
