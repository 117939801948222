import image from "./heading4/subheading 4-2/4-2_photobox-1-1.jpg";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";
import GalleryComponent from "../../../Components/Gallery/Gallery";

import img1 from "./heading4/subheading 4-2/4-2_photobox-1-1.jpg";
import img2 from "./heading4/subheading 4-2/4-2_photobox-1-2.jpg";
import img3 from "./heading4/subheading 4-2/4-2_photobox-2.jpg";
import img4 from "./heading4/subheading 4-2/4-2_photobox-3-1.jpg";
import img5 from "./heading4/subheading 4-2/4-2_photobox-3-2.jpg";
import img6 from "./heading4/subheading 4-2/4-2_photobox-4.jpg";

const Charles_Loloma = () => {
  const title = "Loloma, Master Modern Artist";

  const details =
    `Charles Loloma (1921–1991) is by far the best-known artist whose work ushered in the modern Native jewelry phenomenon of the mid-twentieth century. Born in Hotvela (Hotevilla), on Third Mesa, on the Hopi Indian Reservation, he first studied with Hopi artist Fred Kabotie. Loloma graduated from the Phoenix Indian School, spent time in the army (1942–1946), and then, with help from the G.I. Bill, he attended art school in New York.

Returning to Arizona, Loloma opened a shop in Scottsdale, where he sold his pottery and began to make jewelry in earnest. After participating in the Southwestern Indian Art Project and going on to teach at the Institute of American Indian Arts, he moved back to Hopi in 1965, and his fame skyrocketed through the 1970s and 1980s. He brought on apprentices to help him keep up with the demand. One of them, his niece Verma Nequatewa, continues his legacy through her fine jewelry creations.

Loloma was among the first Native artists to make gold jewelry and to add diamonds and other precious stones. His distinctive, modern style was appreciated and lauded worldwide. It was also copied, and fake Loloma jewelry has proliferated for decades. He died in 1991.
`.split("\n");

  const imageDescription = `This is a digitized version of raw film footage showing Charles Loloma casting a silver bracelet in his studio near Hotevela, Third Mesa. Joseph Gardner, a University of Arizona student, shot the footage in 1969 for a class in Southwest Indian Art taught by anthropology professor Clara Lee Tanner. Gardner gave it to Mrs. Tanner, who donated it to ASM. There is unfortunately no audio that accompanies the video.

  ASM F-13`;

  const photos_one = [
    {
      title: `Silver cuff with offset turquoise setting, c. 1960
      Charles Loloma (1921–1991), Hopi`,
      src: img1,
      caption: `
      Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
      Gift of Catherine Sease, 2020
      W: 2.7 cm
      2020-426-1

      This early cuff by Charles Loloma was purchased at Tom Bahti’s Indian Arts in Tucson. Bahti was an early promoter of Loloma’s work. The donor’s mother and grandmother often purchased jewelry when visiting Tucson in the 1960s and 1970s.`,
    },
    {
      title: `Charles Loloma`,
      src: img2,
      caption: `
      Charles Loloma, Hopi, with his jewelry exhibit at Dewey-Kofron Gallery
      Santa Fe, NM
      Helga Teiwes, photographer, 1977
      ASM 46193`,
    },
    {
      title: `Cast-silver-and-turquoise pin, c. 1960
      Charles Loloma (1921–1991), Hopi`,
      src: img3,
      caption: `
      Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
      Gift of Catherine Sease, 2020
      H: 6.8 cm
      2020-426-2

      This early pin by Charles Loloma was purchased at Tom Bahti’s Indian Arts in Tucson. Bahti was an early promoter of Loloma’s work. The donor’s mother and grandmother often purchased jewelry when visiting Tucson in the 1960s and 1970s.`,
    },
    {
      title: `Single coral-and-silver earring, 1972
      Charles Loloma (1921–1991), Hopi`,
      src: img4,
      caption: `
      ASM purchase from Tom Bahti Indian Arts
      Arizona Archaeological and Historical Society Fund, 1972
      L: 19.2 cm
      E-9995`,
    },
    {
      title: `Hallmark for single coral-and-silver earring, 1972
      Charles Loloma (1921–1991), Hopi`,
      src: img5,
      caption: `
      ASM purchase from Tom Bahti Indian Arts
      Arizona Archaeological and Historical Society Fund, 1972
      L: 19.2 cm
      E-9995

      In 1972, ASM’s Curator of Collections, Holly Chaffee, acquired this single earring—a hallmark form of Loloma’s—as an example of this artist’s work as he rose to fame.`,
    },
    {
      title: `Cuff bracelet with inlaid coral and rows of turquoise beads, c. 1960
      Charles Loloma (1921–1991), Hopi`,
      src: img6,
      caption: `
      Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
      Courtesy Pat and Kim Messier
      J-22`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      video={"EY_0xgWq3jc"}
      clickableImage={image}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Charles_Loloma;
