import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-1 photobox-01.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-1 photobox-02-1.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-1 photobox-03-1.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-1 photobox-03-2.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-1-1.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-2-1.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-2-2.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-2-3.jpg";
import img9 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-3-1.jpg";
import img10 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-3-2.jpg";
import img11 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-4-1.jpg";
import img12 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-4-2.jpg";
import img13 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-4-3.jpg";
import img14 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-4-4.jpg";
import img15 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-4-5.jpeg";
import img16 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-2 photobox-5.jpg";
import img17 from "./heading3/subheading 3-4/subheading 3-4f/kino-composite_scan.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Oodham_Jewelry = () => {
  const title = "Oodham Jewelry";

  const details =
    `The O’odham people of central and southern Arizona have a long history of making shell jewelry, inherited from their Huhugam ancestors. (Archaeologists refer to the ancient peoples of the Sonoran Desert as “Hohokam.”) In the mid-late 20th century, some O’odham artisans began to work in metal, primarily silver, and the style of “Desert Overlay” emerged.  Other O’odham have revived the art of shell jewelry making over the last decades.
`.split("\n");

  const imageDescription = "";

  const photos = [
    {
      title: `Pimo Women, by Arthur Schott`,
      src: img1,
      caption: `
      Depicted wearing shell necklaces
Image opposite page 117 in Emory (1857) 
LCCN for General Collections copy: 01004135 (Library of Congress)
`,
    },
    {
      title: `Pima girl, c. 1900`,
      src: img2,
      caption: `
      Henry Buehman, photographer 
Arizona Historical Society, Buehman Photo Collection, Subjects-Indians-Akimel O’odham-Women, B89901 

This young woman is wearing a necklace of trade beads, likely what came to be known as “Padre beads.”  Blue was by far the most popular color of trade bead among O’odham.`,
    },
    {
      title: `Composite portrait of Eusebio Francisco Kino (cover image) by Frances O’Brien, from Bolton (1963)`,
      src: img17,
      caption: ``,
    },
    {
      title: `Etched-shell necklace, 2020`,
      src: img3,
      caption: `
Tim Terry Jr., Akimel O’odham 
Gila River Indian Community, AZ 
Chocolate pismo clam shell (Megapitaria squalida), cowrie shell, dove shell (Columbella dysoni), turquoise, glass beads 
ASM purchase, Friends of the ASM Collections funds, 2021 
L: 46.5 cm; width of pendant: 8.3 cm 
2021-152-1

The etched design on this shell pendant is based on Hohokam pottery motifs. 
`,
    },
    {
      title: `Tim Terry Jr. holding etched-shell necklace ASM purchased`,
      src: img4,
      caption: `
      Jannelle Weakly, photographer, 2021 

Tim Terry Jr. is a traditional artist, presenter, storyteller, and singer from Hashan Kehk (Cactus Standing), a village in District 2 of the Gila River Indian Community. Tim creates both traditional and contemporary jewelry, incorporating an array of historic techniques, which he preserves via his work. 
`,
    },
    {
      title: `Desert Overlay`,
      src: img16,
      caption: `
      In the early years of the craft, silverwork did not catch on among O’odham artists as it did elsewhere in the region. In 1976, an O’odham man, Rick Manuel, began making a distinct jewelry style that came to be called Desert Overlay. Manuel’s work and mentorship have inspired many other Tucson-area jewelry artists, such as Kristopher Josè who made this cuff.   
`,
    },
    {
      title: `Baboquivari Peak, Tohono O’odham Nation, AZ`,
      src: img5,
      caption: `
      Helga Teiwes, photographer, 1984 
ASM C-45735 

The Sonoran Desert home of O’odham in Southern Arizona and northern Mexico is frequently depicted in Desert Overlay jewelry.
`,
    },
    {
      title: `Silver overlay double-sided pendant, desert scene, c. 2000 
      O’odham`,
      src: img6,
      caption: `
      Rick Manuel (b. 1950)
Gift of Mark Bahti, 2000 
D: 4.3 cm 
2000-90-1 

Local Indian arts dealer Mark Bahti, a longtime major promoter of Manuel’s unique style of jewelry, has donated several of Manuel’s jewelry pieces to ASM over the years. 
`,
    },
    {
      title: `Silver overlay double-sided pendant, man-in-the-maze, c. 2000 
       O’odham`,
      src: img7,
      caption: `
Rick Manuel (b. 1950)
Gift of Mark Bahti, 2000 
D: 4.3 cm 
2000-90-1 

Local Indian arts dealer Mark Bahti, a longtime major promoter of Manuel’s unique style of jewelry, has donated several of Manuel’s jewelry pieces to ASM over the years. 
`,
    },
    {
      src: img8,
      title: `Hallmark for silver overlay double-sided pendant, man-in-the-maze, c. 2000
       O’odham `,
      caption: `
      Rick Manuel (b. 1950)
    Gift of Mark Bahti, 2000
    D: 4.3 cm
    2000-90-1 `,
    },
    {
      title: `Silver overlay belt buckle with desert scene and F-16 jet, c. 2000 
      O’odham`,
      src: img9,
      caption: `
      Rick Manuel (b. 1950)
Gift of Mark Bahti, 2000 
W: 4.6 cm 
2000-90-2 
 
Rick Manuel told Mark Bahti that he added the F-16 to his scene because he liked the lines of the jet. At the time he made the buckle, the fast jets’ sonic booms were causing complaints from some O’odham villagers and ranchers. 
`,
    },
    {
      title: `Hallmark for silver overlay belt buckle with desert scene and F-16 jet, c. 2000 
       O’odham`,
      src: img10,
      caption: `
      Rick Manuel (b. 1950)
Gift of Mark Bahti, 2000 
W: 4.6 cm 
2000-90-2 
`,
    },
    {
      title: `Serpent Highway, 1999 
       Tohono O’odham/German`,
      src: img11,
      caption: `
James Fendenheim
Silver, gold, and garnets 
ASM purchase, Wilma Kaemlein Memorial Curator’s Choice Award 
ASM Southwest Indian Art Fair, 1999 
W: 3.0 cm 
1999-38-1 

"This cuff depicts the treacherous Ajo Highway [Arizona State Route 86]. The end of the road that the serpent is traveling is the Tohono O’odham reservation. The stones represent different colors you see when you crash, and the crosses on the serpent represent the crosses along the highway that mark where people have died. The piece as a whole represents the powers that lie on Highway 86." - James Fendenheim, 1999 
`,
    },
    {
      title: `Hallmark for Serpent Highway, 1999 
       Tohono O’odham/German`,
      src: img12,
      caption: `
James Fendenheim
Silver, gold, and garnets 
ASM purchase, Wilma Kaemlein Memorial Curator’s Choice Award 
ASM Southwest Indian Art Fair, 1999 
W: 3.0 cm 
1999-38-1 
`,
    },
    {
      title: `James Fendenheim, Tohono O’odham`,
      src: img13,
      caption: `
      Santa Fe Indian Market, Santa Fe, NM
Jannelle Weakly, photographer, 2007 

James Fendenheim, from San Pedro Village, has been making jewelry since 1984. He is the son of a Tohono O’odham mother and a German father. Fendenheim is a graduate of the Institute of American Indian Arts in Santa Fe, New Mexico. His grandmother, master basket weaver Frances Manuel, was his artistic mentor and foremost inspiration. 
`,
    },
    {
      title: `Silver overlay letter opener, c. 2000 
      Tohono O’odham/German`,
      src: img14,
      caption: `
James Fendenheim
Gift of Mark Bahti 
L: 22.0 cm 
2001-190-16 
`,
    },
    {
      src: img15,
      title: `Hallmark for silver overlay letter opener, c. 2000
      Tohono O’odham/German`,
      caption: `
      James Fendenheim
    Gift of Mark Bahti
    L: 22.0 cm
    2001-190-16
    `,
    },
    {
      title: `Silver overlay butterfly cuff bracelet, 1997 
      Tohono O’odham`,
      src: img16,
      caption: `
      Kristopher José
Southern AZ 
Award of Distinction for a Southern Arizona Artist 
ASM Southwest Indian Art Fair, 1997 
ASM purchase from Mark Bahti, 1997 
W: 5.6 cm 
1997-81-1 
 
Kristopher José, who learned silverwork from Rick Manuel, was one of many mentees Manuel has nurtured through the years. José  passed away at a young age not long after he won the award at the Southwest Indian Art Fair. 
`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img9}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Oodham_Jewelry;
