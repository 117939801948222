import React from "react";
import Preview from "../../../Components/Preview/Preview";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-1/91-33-1.jpg";
import img2 from "./heading2/subheading 2-2/2-2_clickable.jpg";
import img3 from "./heading2/subheading 2-3/2-3_photobox-1-1.jpg";
import img4 from "./heading2/subheading 2-4/2-4_photobox-3.jpg";
import img5 from "./heading2/subheading 2-5/2-5_photobox-4.jpg";
import img6 from "./heading2/subheading 2-6/2-6_clickable.jpg";
import img7 from "./heading2/master-pnp-cph-3c00000-3c06000-3c06200-3c06265u.jpg";

const details =
  `Prehispanic exchange networks linked the diverse communities of the U.S. Southwest with cultures existing well beyond its borders, including some in Mesoamerica, the Great Plains, and the west coast of North America. It is along these routes that “exotic” items circulated, including turquoise, copper bells, macaws, and marine shell.

  Marine shell, prized by jewelers for thousands of years, is restricted to the coastal areas along the edges or outside of the region. Thus, it was not equally available to all populations in the U.S. Southwest. As a result, items manufactured from shell in one part of the region may have had greater value in another.

  Turquoise has been incorporated into jewelry for millennia. There are numerous sources of the stone within the U.S. Southwest and Northern Mexico as well as California and Nevada. Trade networks distributed this valuable material within the region and as far away as Mesoamerica, where it was highly valued for its rarity.

  In addition to using turquoise, Indigenous lapidarists (stone cutters) ground beads of soft stones, including argillite (also called pipestone or claystone) and steatite (also known as soapstone).
  
  The range of materials that Native artisans employ today includes turquoise, shell, and stone which are found in the work of their ancestors, going back generations. In addition, artisans have incorporated a host of new materials.`.split(
    "\n"
  );

const previews = [
  {
    image: img1,
    url: "/Turquoise",
    text: "Turquoise",
  },
  {
    image: img2,
    url: "/Shell-and-Coral",
    text: "Shell and Coral",
  },
  {
    image: img3,
    url: "/Stone",
    text: "Stones Ancient—Modern",
  },
  {
    image: img4,
    url: "/Technological-Changes",
    text: "Technological Changes",
  },
  {
    image: img5,
    url: "/Animal-Forms",
    text: "Animal Forms",
  },
  {
    image: img6,
    url: "/Metals",
    text: "Metals",
  },
];

const HeadingFour = () => {

  return (
    <Content
      title={"Materials"}
      details={details}
      clickableImage={img2}
      previews={previews}
    />
  );
};

export default HeadingFour;
