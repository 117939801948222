import React, { useState } from "react";
import "./ClickableImage.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import LazyLoad from "react-lazy-load";
//import YouTube from "react-youtube";

const ClickableImage = ({ image, description, video }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const openLightbox = () => {
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const Skeleton = ({ width, height }) => {
    return (
      <div
        className="skeleton"
        style={{ width: `${width}`, height: `${height}` }}
      />
    );
  };

  const onImageLoad = () => {
    setIsLoaded(true);
  };

  console.log(video);

  return (
    <div className="clickable-image-container">
      {video ? (
        <iframe
          style={{ width: "100%", height: 400 }}
          height="315"
          src={`https://www.youtube.com/embed/${video}`} // replace with your video id
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      ) : (
        <img
          src={image}
          alt={description}
          className="modal-image"
          onClick={openLightbox}
          onLoad={onImageLoad}
        />
      )}

      <p style={{ textAlign: "left" }}>
        {description?.split("\n")?.map((line, i) => (
          <span key={i}>
            {line}
            <br />
          </span>
        ))}
      </p>
      {isOpen && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={closeLightbox}
          imageCaption={description}
        />
      )}
    </div>
  );
};

export default ClickableImage;
