import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-01-1.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-01-2.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-01-3.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-01-4.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-01-5.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-02-1.jpeg";
import img7 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-02-2.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-03-1.jpg";
import img9 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-03-2.jpg";
import img10 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-03-3.jpg";
import img11 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-03-4.jpg";
import img12 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-04-1.jpg";
import img13 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-04-2.jpg";
import img14 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-04-3.jpg";
import img15 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-05-1.jpg";
import img16 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-05-2.jpg";
import img17 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-06-1.jpg";
import img18 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-06-2.jpg";
import img19 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-07-1.jpg";
import img20 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-07-2.jpg";
import img21 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-08-1.jpg";
import img22 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-08-2.jpg";
import img23 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-09-1.jpg";
import img24 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-09-2.jpg";
import img25 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-10-1.jpg";
import img26 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-10-2.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Hopi_Overlay = () => {
  const title = "Hopi Overlay";

  const details =
    `In the late 1930s, as part of a major effort to promote Hopi handicrafts, Harold and Mary- Russell Colton, founders of the Museum of Northern Arizona (MNA), in Flagstaff, encouraged Hopi artisans to develop a unique style. They embraced the silver overlay technique, first suggested by MNA staff member Virgil Hubert, and incorporated traditional Hopi pottery designs and other cultural symbols into their jewelry. Overlay work involves soldering a silver sheet, with patterns cut into it, to a backing that is then textured and oxidized to provide contrast. 
    
    The advent of World War II in December 1941 interrupted silver jewelry production. After the war ended, and using funding from the G.I. Bill, Hopi artist Fred Kabotie and silversmith Paul Saufkie established a craft cooperative, the Hopi Arts and Crafts Guild, to teach silversmithing to veterans. Kabotie provided the designs, many based on ancient Mimbres pottery motifs (c. 1000–1150 CE). Saufkie contributed the technical instruction. They opened a retail shop in 1961.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos = [
    {
      title: `Paul Saufkie and Fred Kabotie with students`,
      src: img1,
      caption: `
      Hopi Indian Reservation, AZ
Milton Snow, photographer, c. 1944–1950
Hopi Cultural Preservation Office, HCPO.PH.2003.1.HC5.59a, SCA Item Number: 29074

J. H. McGibbeny described the scene at the Hopi jewelry cooperative in 1950:

"There is a pleasant tinkling of metals, frequently interspersed with soft-spoken Hopi conversation, as a small group of ex-service men moves about the classroom in the Hopi Indian High School at Oraibi, Arizona. The young veterans are the first enrollees in a silversmithing project organized under the G.I. Bill of Rights. Their instructor, Paul Saufkie, of the village of Shongopovi on Second Mesa, is an accomplished silversmith, an artist at fashioning the beautiful articles of silver and turquoise so greatly prized by white people as well as the Indians of the Southwest" (McGibbeny 1950:18).`,
    },
    {
      title: `Paul Saufkie and silversmithing student`,
      src: img2,
      caption: `
      Hopi Indian Reservation, AZ
Milton Snow, photographer, c. 1944–1950
Hopi Cultural Preservation Office, HCPO.PH.2003.1.HC5.63b, SCA Item Number: 29097`,
    },
    {
      title: `Hopi silversmiths Vernon Mansfield (foreground) and Jerry Honwytewa (background) buff bracelets`,
      src: img3,
      caption: `
      Hopi Silvercraft Cooperative Guild, Second Mesa, Hopi Indian Reservation, AZ 
Helga Teiwes, photographer, 1973
ASM 37764`,
    },
    {
      title: `Hopi silversmith Eldon Siewiyumptewa Kalema, Sr., solders overlay pieces`,
      src: img4,
      caption: `
      Hopicrafts production shop
      Second Mesa, Hopi Indian Reservation, AZ
Helga Teiwes, photographer, 1973
ASM 37766`,
    },
    {
      title: `Hopi silversmith Emory Sekaquaptewa cuts out a buffalo design for silver overlay`,
      src: img5,
      caption: `
      Hopicrafts, Kiqötsmovi (Kykotsmovi; “New Oraibi”), Third Mesa, Hopi Indian Reservation, AZ
Helga Teiwes, photographer, 1973
ASM 37811`,
    },
    {
      title: `Silver cuff bracelet with turquoise-and-jet inlay, c. 1950, Hopi`,
      src: img6,
      caption: `
      Paul Saufkie (1898–1993)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-21`,
    },
    {
      title: `Paul Saufkie and Fred Kabotie with students displaying Hopi clan symbols used in silver designs, Hopi Indian Reservation, AZ`,
      src: img7,
      caption: `
Milton Snow, photographer, c. 1944–1950
Hopi Cultural Preservation Office, HCPO.PH.2003.1.HC5.32, SCA Item Number: 27301`,
    },
    {
      title: `Copper cuff bracelet, 1947–1951, Hopi`,
      src: img8,
      caption: `
      Paul Saufkie
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Formerly in the collection of the Hopi Museum, Second Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-566

When learning to work with metal, students used copper because it was less expensive than silver (Messier and Messier 2014:70). Occasionally the reslting copper pieces would be sold. This piece and the next one were likely kept as examples for posterity.`,
    },
    {
      title: `Hallmark for copper cuff bracelet, 1947–1951, Hopi`,
      src: img9,
      caption: `
      Paul Saufkie 
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Formerly in the collection of the Hopi Museum, Second Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier`,
    },
    {
      title: `Copper pin, 1949–1951, Hopi`,
      src: img10,
      caption: `
      Vernon Talas 
Hopi Indian Reservation, AZ
Formerly in the collection of the Hopi Museum, Second Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-564`,
    },
    {
      title: `Hallmark for copper pin, 1949–1951, Hopi`,
      src: img11,
      caption: `
      Vernon Talas 
Hopi Indian Reservation, AZ
Formerly in the collection of the Hopi Museum, Second Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-564`,
    },
    {
      title: `Ancient Mimbres pottery designs (c. 1000–1150 CE)`,
      src: img12,
      caption: `
      In 1949, Fred Kabotie published a book of ancient Mimbres pottery designs interpreted from a Hopi perspective. Northland Press later republished the book (Kabotie 1982).`,
    },
    {
      title: `Silver overlay pin with Mimbres design from Kabotie’s book, c. 1950
      Hopi`,
      src: img13,
      caption: `
      Douglas Holmes, Hopi Silvercraft Cooperative Guild
Second Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-60`,
    },
    {
      title: `Hallmark for silver overlay pin with Mimbres design from Kabotie’s book, c. 1950, Hopi`,
      src: img14,
      caption: `
      Douglas Holmes, Hopi Silvercraft Cooperative Guild
Second Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-60`,
    },
    {
      title: `Silver overlay pin depicting Kokopölö (Kokopelli), 1969, Hopi`,
      src: img15,
      caption: `
      Victor Coochwytewa 
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
ASM purchase from Tom Bahti Indian Arts, 1969
D: 3.8 cm
E-8219`,
    },
    {
      title: `Hallmark for silver overlay pin depicting Kokopölö (Kokopelli), 1969, Hopi`,
      src: img16,
      caption: `
      Victor Coochwytewa 
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
ASM purchase from Tom Bahti Indian Arts, 1969
D: 3.8 cm
E-8219`,
    },
    {
      title: `Silver cuff bracelet, 1947–1949, Hopi`,
      src: img17,
      caption: `
Arthur Yowytewa (1922–2006) and Paul Saufkie (1904–1998)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-493`,
    },
    {
      title: `Hallmark for silver cuff bracelet, 1947–1949, Hopi`,
      src: img18,
      caption: `
      Arthur Yowytewa (1922–2006) and Paul Saufkie (1904–1998) 
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-493`,
    },
    {
      title: `Silver overlay pin, c. 1960, Hopi`,
      src: img19,
      caption: `
      Douglas Holmes (1925–2005) 
Second Mesa, Hopi Indian Reservation, AZ
Gift of the Estate of Richard Woodbury, 2011
D: 3.2 cm
2011-250-60

Douglas Holmes was in the second class of World War II veterans who were students at the Hopi Silvercraft Cooperative Guild. He worked until 1961. Holmes is known for having favored designs from Mimbres pottery. His hallmark was a butterfly paired with the guild hallmark of the sun katsina.`,
    },
    {
      title: `Hallmark for silver overlay pin, c. 1960, Hopi`,
      src: img20,
      caption: `
      Douglas Holmes (1925–2005)
Second Mesa, Hopi Indian Reservation, AZ
Gift of the Estate of Richard Woodbury, 2011
D: 3.2 cm
2011-250-60`,
    },
    {
      title: `Silver overlay cuff bracelet with wave designs, c. 1953, Hopi`,
      src: img21,
      caption: `
      Victor Coochwytewa (1922–2011)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Gift of E. B. (Ted) Sayles, 1953
Purchased at Desert House Shops, Tucson, Arizona, 1953
Hallmarks of cloud symbol and sun katsina (Hopi Silvercraft Cooperative Guild)
W: 2.1 cm
E-4779`,
    },
    {
      title: `Hallmark for silver overlay cuff bracelet with wave designs, c. 1953, Hopi`,
      src: img22,
      caption: `
      Victor Coochwytewa (1922–2011)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Gift of E. B. (Ted) Sayles, 1953
Purchased at Desert House Shops, Tucson, Arizona, 1953
Hallmarks of cloud symbol and sun katsina (Hopi Silvercraft Cooperative Guild)
W: 2.1 cm
E-4779

Victor Coochwytewa was a highly regarded jewelry artist who learned the craft from Paul Saufkie in the 1940s. After serving in World War II, Coochwytewa took classes at the Fort Wingate Indian School, in New Mexico, participated in the veterans’ silverworking program at Second Mesa, and eventually became a member of the Hopi Silvercraft Cooperative Guild.`,
    },
    {
      title: `Double-sided silver pendant and chain, 1997, Hopi`,
      src: img23,
      caption: `
      Antone Honanie
Third Mesa, Hopi Indian Reservation, AZ
ASM purchase, B. C. Waddell Memorial Acquisition Award for Excellence in Jewelry
ASM Southwest Indian Art Fair, 1997
D: 3.1 cm (pendant)
1997-57-1

In 1997, this pendant was the first winner of the B. C. Waddell Memorial Acquisition Award for Excellence in Jewelry. At the time, Honanie was 25 years old. His father had been adopted by Charles Loloma, making Loloma Antone’s grandfather. Antone learned to make jewelry from his aunt’s husband and then went on to participate in training at the Hopi Silvercraft Cooperative Guild.

Honanie described the scenes depicted on this side of the double-sided pendant at the time ASM purchased it (ASM Accession file 1997-57):

"Flute player to help the plants grow, germination. The little dots in the mound are seeds of different plants the Hopi use. Prayer feathers are placed around the mound as offerings. The dots around the edge of the pendant also depict seeds."`,
    },
    {
      title: `Double-sided silver pendant and chain, 1997, Hopi`,
      src: img24,
      caption: `
      Antone Honanie
Third Mesa, Hopi Indian Reservation, AZ
ASM purchase, B. C. Waddell Memorial Acquisition Award for Excellence in Jewelry
ASM Southwest Indian Art Fair, 1997
D: 3.1 cm (pendant)
1997-57-1

In 1997, this pendant was the first winner of the B. C. Waddell Memorial Acquisition Award for Excellence in Jewelry. At the time, Honanie was 25 years old. His father had been adopted by Charles Loloma, making Loloma Antone’s grandfather. Antone learned to make jewelry from his aunt’s husband and then went on to participate in training at the Hopi Silvercraft Cooperative Guild.

Honanie described the scenes depicted on this side of the double-sided pendant at the time ASM purchased it (ASM Accession file 1997-57):

"Hopi Mesas. Looks like Songòopavi when you are driving toward the village from Winslow, Arizona. This is where my daughter lives. It also has clouds for rain and corn crops. The way the sun’s rays are depicted is sort of my hallmark."`,
    },
    {
      title: `Silver-and-turquoise overlay cuff, c. 1990, Hopi`,
      src: img25,
      caption: `
      Victor Coochwytewa (1922–2011)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
ASM purchase, Friends of the ASM Collections funds
W: 2.3 cm
2020-57-1

This cuff was originally purchased at Kopavi in Sedona, Arizona. Kopavi’s owner, Richard Mehagian, is a major promoter of Coochwytewa’s work. The stone has been identified as Turquoise Mountain turquoise, mined near Kingman, Arizona. For more information about the artist and Kopavi, see Pearlstone (2014).`,
    },
    {
      title: `Hallmark for silver-and-turquoise overlay cuff, c. 1990, Hopi`,
      src: img26,
      caption: `
      Victor Coochwytewa (1922–2011)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
ASM purchase, Friends of the ASM Collections funds
W: 2.3 cm
2020-57-1`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img21}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Hopi_Overlay;
