import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-1 traditional yaqui yoeme necklaces/3-4g photobox-1-2.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-1 traditional yaqui yoeme necklaces/3-4g-1 photobox-1-1.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-1 traditional yaqui yoeme necklaces/3-4g-1 photobox-2-1.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-1 traditional yaqui yoeme necklaces/3-4g-1 photobox-2-2.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-1 traditional yaqui yoeme necklaces/3-4g-1 photobox-2-3.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-1 traditional yaqui yoeme necklaces/3-4g-1 photobox-2-4.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-1 traditional yaqui yoeme necklaces/3-4g-1 photobox-2-5.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-1 traditional yaqui yoeme necklaces/kolaz-1.jpg";
import Content from "../../../Components/Content/Content";

import GalleryComponent from "../../../Components/Gallery/Gallery";

const Yaqui_Yoeme_Necklaces = () => {
  const title = "Yaqui (Yoeme) Necklaces";

  const details = `Pascola and Deer Dancer Necklaces 

Note: Tom Kolaz, former ASM Assistant Collections Curator, contributed the following text. Thanks to Felipe Molina (Yoeme) for his additional insights.

Probably the most recognizable Yoeme necklace is the one worn by pahko’olam (pascolas) and the Maaso (deer dancer) while dancing during ceremonies. Called hopo’oroosim, they are made almost exclusively by men who are often pahko’olam or Maaso themselves. Other men, especially those involved in ceremonial arts such as mask carving, also make them.

Prior to about 1970,  hopo’oroosim were  simple beaded strands with a single pendant cross of wood or abalone shell (Haliotis sp.; known to Yaqui/Yoeme people as veeko). (See photo). Around 1970, new style necklaces appeared with up to eight shell or Brazil wood crosses along with elaborate glass bead strands supporting the crosses. These longer versions, still worn today, hang well down the dancers’ chests. 

Hopo’oroosim are worn only by men during ceremonies. 
`.split("\n");

  const imageDescription = "";

  const photos = [
    {
      title: `Luis Bejorano and Luis Martinez, Yaqui (Yoeme)`,
      src: img2,
      caption: `
      At Fiesta de Promesa of Miguel Vasquez
Old Pascua, Tucson, AZ
David J. Jones Jr., photographer, 1937
ASM 2000-175-0850 
 
Dancers wear simple bead necklaces with single cross pendant.
`,
    },
    {
      title: `Pascola dancer, Rosario Bacaneri Castillo, and deer dancer, Juan Cruz, at the San Ignacio Fiesta`,
      src: img1,
      caption: `
      Pascua Village, Tucson, AZ 
Ray Manley, photographer, 1959 
ASM PIX-353-x-23 
`,
    },
    {
      title: `Pascola and deer dancer necklace, c. 1982
      Yaqui (Yoeme)`,
      src: img3,
      caption: `
      Worn by José Matus 
Guadalupe, AZ 
Glass and plastic beads, abalone shell, metal St. Teresa medal 
ASM purchase, 1982 
L: 44 cm 
1982-36-9 
`,
    },
    {
      title: `Pascola and deer dancer necklace (hopo’oroosim), 2005 
      Yaqui (Yoeme) `,
      src: img4,
      caption: `
      Crisencio Molina
Potam, Sonora, Mexico 
Glass beads, abalone-shell crosses 
Courtesy Tom Kolaz Collection
L: 59.7 cm 
`,
    },
    {
      title: `Pascola and deer dancer necklace (hopo’oroosim), c. 1980 
      Yaqui (Yoeme) `,
      src: img5,
      caption: `
      Luis “Maaso” Cinfuego (deceased)
Rahum Pueblo, Sonora, Mexico
Glass beads, abalone-shell crosses 
Courtesy Tom Kolaz Collection 
L: 46.4 cm `,
    },
    {
      title: `Pascola and deer dancer necklace (hopo’oroosim), c. 1970 
      Yaqui (Yoeme)`,
      src: img6,
      caption: `
      Antonio Bacasewa
Vicam Pueblo, Sonora, Mexico 
Glass beads, abalone-shell and brazil-wood (huchahko) crosses, rhinestones 
Courtesy Tom Kolaz Collection 
L: 34.7 cm 
`,
    },
    {
      title: `Pascola and deer dancer necklace, Hopo’oroosim, 2004 
      Yaqui (Yoeme)`,
      src: img7,
      caption: `
Crisencio Molina
Potam, Sonora, Mexico 
Glass beads, abalone-shell crosses and eagles 
Courtesy Tom Kolaz Collection
L: 46.9 cm 
`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img3}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Yaqui_Yoeme_Necklaces;
