import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-6/subheading 2-6-1/2-6a_photobox-1-1.jpg";
import img2 from "./heading2/subheading 2-6/subheading 2-6-1/2-6a_photobox-1-2.jpg";
import img3 from "./heading2/subheading 2-6/subheading 2-6-1/2-6a_photobox-2-1.jpg";
import img4 from "./heading2/subheading 2-6/subheading 2-6-1/2-6a_photobox-2-2.jpg";
import img5 from "./heading2/subheading 2-6/subheading 2-6-1/2-6a_photobox-3.jpg";
import img6 from "./heading2/subheading 2-6/subheading 2-6-1/2-6a_photobox-4.jpg";
import img7 from "./heading2/subheading 2-6/subheading 2-6-1/2-6a_photobox-5.jpg";

const Silver = () => {
  const title = "Silver";

  const details_one = `Silverworking Technologies Introduced

    In the mid-1800s, Mexican silversmiths introduced silverworking to Native artisans. Sometime around 1890, Native smiths began to pair the white metal with turquoise, creating a host of different jewelry forms and styles that have defined Indian jewelry in the U.S. Southwest while continuing to evolve.

    Atsidi Sani (Old Smith), recorded to be the first Navajo to work in silver, learned blacksmithing around 1850 from a Mexican man named Nakai Tsosi (Thin Mexican) who lived near Mount Taylor, New Mexico, the southern landmark of Dinetah, or Navajo country. Anthropologist John Adair believed that he most likely learned silverworking around 1868. Atsidi Sani then taught his sons and others, including Grey Moustache, who remembered:`.split(
    "\n"
  );

  const quote_one =
    `Atsidi Sani was a great man. He was a leader and spokesman of his people. He was also a medicine man, and he knew the Mountain Chant and the Shooting Chant.`.split(
      "\n"
    );

  const details_two =
    `Regarding whether Navajos learned to work in silver while incarcerated at Bosque Redondo, New Mexico, Adair interviewed Chee Dodge, a Navajo leader who had known Atsidi Sani. Dodge reported:`.split(
      "\n"
    );

  const quote_two =
    `The Navajo didn’t make any silver of their own while they were at Fort Sumner [1864–1868]. How could they? The Navajo were locked up there just like sheep in a corral.`.split(
      "\n"
    );

  const details_three =
    `Diné (Navajo) jeweler Grey Moustache recounted to Adair in 1938:`.split(
      "\n"
    );

  const quote_three =
    `It was not until the Navajo came back from Fort Sumner that [the first silversmith, Atsidi Sani] learned how to make silver jewelry. It is not true that the Navajo learned silversmithing from the Mexicans while they were there. They did wear some silver at that time, mostly rings and bracelets, which they bought from the Mexican smiths, but most of their jewelry was made of copper and brass.`.split(
      "\n"
    );

  const details_four =
    `Diné (Navajo) artisans set up forges and repurposed found-iron objects. They used sections of train rails as anvils, made bellows from hide, created casting molds from local sandstone and other soft, easily carved stones, and fashioned dies from iron bolts for pressing coins into hemispherical depressions cut into horseshoes. With these rudimentary tools, they made rings, bracelets, concha belts, buttons, necklaces, horse bridles, and tobacco canteens for their own use and for exchange with neighboring tribes.

    Stamped designs, first applied in very simple form on copper-wire and brass-wire bracelets, were liberally employed by Diné (Navajo) smiths by 1890. By this time, they had also passed their knowledge on to A:shiwi (Zuni) and Hopi artisans.

    Initially, Native smiths obtained their silver by melting down coins or using silver slugs provided by traders. In the 1920s, with the introduction of commercially produced sheet silver, the fabrication process was simplified considerably. In recent years, a number of jewelry makers have reverted to using coin silver, which produces a warm, retro effect.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Workshop of a Navajo Silversmith`,
      src: img1,
      caption: `
        Plate XVII in Matthews (1883)`,
    },
    {
      title: `Objects in Silver`,
      src: img2,
      caption: `
      Plate XIX in Matthews (1883)
      
      "The moulds in which they cast their ingots, cut in soft sandstone with a home-made chisel, are so easily formed that the smith leaves them behind when he moves his residence. Each mould is cut approximately in the shape of the article which is to be wrought out of the ingot cast in it, and it is greased with suet before the metal is poured in." (Matthews 1883:173).`,
    },
    {
      title: `Sandstone mold set with bracelet casting
      Ambrose Roanhorse (1904–1982), Diné (Navajo)`,
      src: img3,
      caption: `
        Collected in 1942 by Clay Lockett
        Gift of Clay Lockett, 1942
        Mold, L: 21.4 cm
        E-1375, E-1376`,
    },
    {
      title: `Bracelet cast from sandstone mold set
      Ambrose Roanhorse (1904–1982), Diné (Navajo)`,
      src: img4,
      caption: `
        Collected in 1942 by Clay Lockett
        Gift of Clay Lockett, 1942
        Mold, L: 21.4 cm
        E-1377

        In 1939, Diné (Navajo) jeweler Ambrose Roanhorse was hired by the Bureau of Indian Affairs to direct a program at Fort Wingate, New Mexico, to teach and promote Navajo silversmithing. This initial effort led to the establishment of the Navajo Arts and Crafts Guild in 1940–1941. In 1972, its name was changed to the Navajo Arts and Crafts Enterprise, and the Navajo Nation still operates it today.

        This rare sandcasting set by Ambrose Roanhorse would have been collected not long after he began his stint at Fort Wingate.

        For more information about Roanhorse, see https://messieraz.com/tag/ambrose-roanhorse/ as well as Messier (2014)`,
    },
    {
      title: `Postcard of Navajo silversmith, 1930s. Mullarky Photo. The jeweler has been identified as Da Pah.`,
      src: img5,
      caption: `
        From the collection of Pat and Kim Messier.

        The technologies employed in working silver changed over the course of the twentieth century, as metal rollers, commercially produced sheet silver and stamping dies, and other modern tools became available. Mass production of jewelry began in the 1930s. Some shops that produced jewelry in high volumes for the tourist trade employed Native smiths, others only incorporated Native designs and forms. Such products allowed shops to sell inexpensive versions of Native jewelry at the growing number of tourist destinations, but left traditional silversmiths at a competitive disadvantage.`,
    },
    {
      title: `Loop earrings with squash blossoms, c. 1900
      Diné (Navajo), AZ, NM, or UT`,
      src: img6,
      caption: `
          Silver
          ASM purchase, Nelle Dermont Collection, 1919
          L: 5.5 cm
          8265`,
    },
    {
      title: `Silver bridle and wrought-iron bit, c. 1900
      Belonged to Chief Hoskinnini (d. 1912)
      Diné (Navajo), AZ or UT`,
      src: img7,
      caption: `
          Silver, iron, leather
          Gift of E. B. Danson, 1946
          L: 64 cm
          E-2191`,
    },
  ];

  return (
    <Content
      title={title}
      specialRender={
        <>
          {details_one.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <div className="quote">
            {quote_one.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <span style={{ fontStyle: "normal" }}>(Adair 1944:5)</span>{" "}
            <br></br>
            <br></br>
          </div>
          {details_two.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <div className="quote">
            {quote_two.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <span style={{ fontStyle: "normal" }}>(Adair 1944:5)</span>{" "}
            <br></br>
            <br></br>
          </div>
          {details_three.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <div className="quote">
            {quote_three.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <span style={{ fontStyle: "normal" }}>(Adair 1944:4)</span>{" "}
            <br></br>
            <br></br>
          </div>
          {details_four.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </>
      }
      clickableImage={img5}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Silver;
