import image from "./heading4/subheading 4-1/subheading 4-1-5-1/4-1_photobox-5-1.jpg";
import img1a from "./heading4/subheading 4-1/subheading 4-1-1-1/4-1_photobox-1-1.jpg";
import img1b from "./heading4/subheading 4-1/subheading 4-1-1-2/4-1_photobox-1-2.jpg";
import img1c from "./heading4/subheading 4-1/subheading 4-1-1-3/4-1_photobox-1-3.jpg";
import img1d from "./heading4/subheading 4-1/subheading 4-1-1-3/4-1_photobox-1-4.jpg";
import img1e from "./heading4/subheading 4-1/subheading 4-1-1-4/4-1_photobox-1-5.jpg";
import img2 from "./heading4/subheading 4-1/subheading 4-1-2/4-1_photobox-2.jpg";
import img3a from "./heading4/subheading 4-1/subheading 4-1-3-1/4-1_photobox-3-1.jpg";
import img3b from "./heading4/subheading 4-1/subheading 4-1-3-2/4-1_photobox-3-2.jpg";
import img3c from "./heading4/subheading 4-1/subheading 4-1-3-3/4-1_photobox-3-3.jpg";
import img4a from "./heading4/subheading 4-1/subheading 4-1-4-1/4-1_photobox-4-1.jpg";
import img4b from "./heading4/subheading 4-1/subheading 4-1-4-2/4-1_photobox-4-2.jpg";
import img5a from "./heading4/subheading 4-1/subheading 4-1-5-1/4-1_photobox-5-1.jpg";
import img5b from "./heading4/subheading 4-1/subheading 4-1-5-2/4-1_photobox-5-2.jpg";
import img6a from "./heading4/subheading 4-1/subheading 4-1-6-1/4-1_photobox-6-1.jpg";
import img6b from "./heading4/subheading 4-1/subheading 4-1-6-2/4-1_photobox-6-2.jpg";
import img7 from "./heading4/subheading 4-1/subheading 4-1-7/4-1_photobox-7.jpg";
import img8a from "./heading4/subheading 4-1/subheading 4-1-8-1/4-1_photobox-8-1.jpg";
import img8b from "./heading4/subheading 4-1/subheading 4-1-8-2/4-1_photobox-8-2.jpg";
import img8c from "./heading4/subheading 4-1/subheading 4-1-8-3/4-1_photobox-8-3.jpg";
import img9 from "./heading4/subheading 4-1/subheading 4-1-9/4-1_photobox-9.jpg";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Southwestern_Indian_Art_Project = () => {
  const title = "Southwestern Indian Art Project";

  const details1 =
    `In 1959, New York’s Rockefeller Foundation sponsored the Directions in Indian Art conference at the University of Arizona. In the conference report, the organizers stated:
`.split("\n");

  const quote =
    `The purpose of this conference of Indian craftsmen and artists, traders, educators and administrators is to exchange ideas on the present status and the possible future of American Indian Art. Those selected to attend have been chosen by their variously demonstrated interest in the subject, and it is hoped that by forthright discussion a basis may be found on which to build a positive program of economic and cultural value to Indians and to the Nation.
`.split("\n");

  const details2 = `
At this meeting, the Southwestern (sometimes called the Southwest) Indian Art Project—a workshop for promising young Indian students—was born. The project ran during the summers of 1960 through 1962.

Hopi artist Charles Loloma (1921–1991), who was involved in planning the summer program, taught jewelry making; many of the resulting works clearly show his artistic influence. In teaching modern approaches, he nevertheless strongly felt that “an attempt should be made to bring out the dignity and philosophy of Indian background.”
`.split("\n");

  const imageDescription = "";

  const photos1 = [
    {
      title: `Cover of the report on the Southwestern Indian Art Project, 1960`,
      src: img1a,
      caption: `
      Supported by the Rockefeller Foundation.
        On file, accession no. 1291, ASM Registrar’s Office`,
    },
    {
      title: `Chester Kahn (Diné [Navajo]) pictured on the cover of the 1961 University of Arizona Museum of Art exhibition.`,
      src: img1b,
      caption:`
        The 1961 summer program’s itinerary included mounting exhibits of the students’ work at the University of Arizona Museum of Art. Program from the 1961 exhibition.`,
    },
    {
      title: `Exhibition brochure acknowledgments.`,
      src: img1c,
      caption:`
      The 1961 summer program’s itinerary included mounting exhibits of the students’ work at the University of Arizona Museum of Art. Program from the 1961 exhibition. Chester Kahn (Diné [Navajo]) is pictured on the cover. (2/3)`,
    },
    {
      title: `Participants in the 1961 University of Arizona Museum of Art exhibition.`,
      src: img1d,
      caption:`
        The 1961 summer program’s itinerary included mounting exhibits of the students’ work at the University of Arizona Museum of Art. Program from the 1961 exhibition.`,
    },
    {
      title: `Charles Loloma, Hopi, assisting student Christino Peña, San Ildfonso, with his jewelry project`,
      src: img1e,
      caption: `
      Ray Manley, photographer, 1962
        ASM Archives, AP-1291

        "Mr. Charles Loloma of Scottsdale, Indian craftsman of considerable importance, had charge of the Indian phase of the instruction in silver work and was supported by Mr. Maurice Grossman of the University Art Department." (Southwestern Indian Art Project 1960:8).

        Loloma went on to teach at the Institute of American Indian Arts (IAIA), which was founded in Santa Fe, New Mexico, in 1962, in no small part due to the groundwork laid by the Southwestern Indian Art Project. At IAIA, Loloma continued to influence his students’ jewelry styles, and many of them became famous artists themselves. In 1965, he left IAIA to pursue his art full time.`,
    },
    {
      title: `Cast-silver cuff bracelet with turquoise, c. 1962
      Barton Ghahate, A:shiwi (Zuni)`,
      src: img2,
      caption: `
      Pueblo of Zuni, NM
        Southwestern Indian Art Project, 1962
        W: 4.2 cm
        E-5374`,
    },
    {
      title: `Cast-silver cuff bracelet with coral and turquoise, c. 1962
      Manfred Susunkewa (b. 1940), Hopi`,
      src: img3a,
      caption: `
      Second Mesa, Hopi Indian Reservation, AZ
        Southwestern Indian Art Project, 1962
        W: 3.5 cm
        E-5375`,
    },
    {
      title: `Sunburst silver-and-turquoise pendant, 1962
      Manfred Susunkewa (b. 1940), Hopi`,
      src: img3b,
      caption: `
      Second Mesa, Hopi Indian Reservation, AZ
        Southwestern Indian Art Project, 1962
        L: 6.5 cm
        E-5400`,
    },
    {
      title: `Manfred Susunkewa, Hopi`,
      src: img3c,
      caption: `
      Ray Manley, photographer, 1962
        ASM PIX-649-x-19

        Manfred Susunkewa was born on Second Mesa, on the Hopi Indian Reservation. He participated in the Southwestern Indian Art Project from 1960 to 1962 and, along with his summer program classmate, Terence Talaswaima, went on to study art at the University of Arizona. Susunkewa’s artistic career has focused more on katsina doll carving than jewelry making, yet this piece demonstrates that he is a gifted silversmith as well.`,
    },
    {
      title: `Cast-silver cuff bracelet with ironwood and turquoise, 1962
      Roberta Colehay, Ndée (Western Apache)`,
      src: img4a,
      caption: `
      East-central AZ
        Southwestern Indian Art Project, 1962
        W: 3.2 cm
        E-5377

        Several Southwestern Indian Art Project jewelry items include ironwood as a major or minor raw material, an idea introduced by Charles Loloma. According to author and gallery owner Mark Bahti: "I believe [that], initially, the ironwood came from [trader and author] Oscar T. Branson (my dad introduced them, as Oscar was making ironwood boxes and Charles was intrigued) but later it was from a couple of clunky Seri ironwood carvings I gave him."`,
    },
    {
      title: `Roberta Colehay, Ndée (Western Apache)`,
      src: img4b,
      caption: `
      Ray Manley, photographer, 1962
        ASM PIX-494`,
    },
    {
      title: `Cast-silver cuff bracelet with turquoise, coral, and ironwood, 1962
      Henrietta Bobb, Paiute`,
      src: img5a,
      caption: `
      Reno, NV
        Southwestern Indian Art Project, 1962
        W: 7.5 cm
        E-5380`,
    },
    {
      title: `Henrietta Bob, Paiute, works on a design`,
      src: img5b,
      caption: `
      Ray Manley, photographer, 1962
        ASM PIX-649-x-18`,
    },
    {
      title: `Cast-silver-and-turquoise ring, 1962
      Chester Kahn (1936–2019), Diné (Navajo)`,
      src: img6a,
      caption: `
      Pine Springs, AZ
        Southwestern Indian Art Project, 1962
        H: 3.4 cm
        E-5385

        Chester Kahn was a student in the Southwestern Indian Art Project in 1962. He went on to be an award-winning silversmith and painter, going by the name Tso Yazzy. He painted a number of murals on buildings throughout the Southwest, illustrated books, and was an ambassador for Diné (Navajo) culture through a traveling lecture circuit. An adherent of the Baháʼí faith, Kahn was a member of its National Spiritual Assembly. He was honored as an Arizona Living Treasure in 2011.`,
    },
    {
      title: `Chester Kahn, Diné (Navajo)`,
      src: img6b,
      caption: `
      Ray Manley, photographer, 1962
        ASM Archives, AP-1291`,
    },
    {
      title: `Coral mosaic silver cross pendant, 1960
      A. Lesansee, A:shiwi (Zuni)`,
      src: img7,
      caption: `
      Pueblo of Zuni, NM
        Southwestern Indian Art Project, 1960
        H: 9.7 cm
        E-5389`,
    },
    {
      title: `Cast-silver brooch, 1962
      Christino Peña, San Ildefonso`,
      src: img8a,
      caption: `
      San Ildefonso Pueblo, NM
        Southwestern Indian Art Project, 1962
        H: 9.2 cm
        E-5393`,
    },
    {
      title: `Cast-silver cuff bracelet, 1962
      Christino Peña, San Ildefonso`,
      src: img8b,
      caption: `
      NM
        Southwestern Indian Art Project, 1962
        W: 3.5 cm
        E-5371`,
    },
    {
      title: `Christino Peña, San Ildefonso, at work`,
      src: img8c,
      caption: `
      Ray Manley, photographer, 1962
        ASM Archives, AP-1291`,
    },
    {
      title: `Cast naja with turquoise, c. 1960
      Tom Kee Yazzie, Diné (Navajo)`,
      src: img9,
      caption: `
      AZ, NM, or UT
        Southwestern Indian Art Project, 1960
        H: 8 cm
        E-5398`,
    },
  ];

  return (
    <Content
      title={title}
      specialRender={
        <>
          {details1.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <div className="quote">
            {quote.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <span style={{ fontStyle: "normal" }}>
              (Rockefeller Foundation 1959)
            </span>
          </div>
          {details2.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </>
      }
      clickableImage={image}
      imageDescription={imageDescription}
      photos={photos1}
    />
  );
};

export default Southwestern_Indian_Art_Project;
