import React from "react";
import Preview from "../../../Components/Preview/Preview";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-2/subheading 2-2-1/2-2a_clickable.jpg";
import img2 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-2.jpg";
import img3 from "./heading2/subheading 2-2/subheading 2-2-3/2-2c_photobox-5.jpg";
import img4 from "./heading2/subheading 2-2/subheading 2-2-4/2-2d photobox-2.jpg";
import img5 from "./heading2/subheading 2-2/subheading 2-2-5/2-2e_photobox-1.jpg";
import img6 from "./heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-1.jpg";
import img7 from "./heading2/subheading 2-2/subheading 2-2-7/2-2g_photobox-2.jpg";
import img8 from "./heading2/subheading 2-2/2-2_clickable.jpg";
import img9 from "./heading2/subheading 2-2/2-2_photobox-1.jpg";
import img10 from "./heading2/subheading 2-2/2-2_photobox-2.jpg";

const Shell_and_Coral = () => {
  const title = "Shell and Coral";

  const details =
    `Shell was a major raw material used for making jewelry in the ancient U.S. Southwest and Northern Mexico, and it has continued to be worked extensively by artisans from descendant communities. Shell jewelry enjoys an important position in both daily and ceremonial wear within these communities and is also a source of income for its makers.
`.split("\n");

  const photos_one = [
    {
      title: `Diné (Navajo) woman wearing a shell-and-turquoise necklace, Navajo Nation, AZ`,
      src: img9,
      caption: `
        Unknown photographer, Wetherill Collection, c. 1925
        ASM 2004-1447-0026`,
    },
    {
      title: `Kevahewa and niece, Hopi, dressed for butterfly dance, Supawlavi (Shipaulovi)`,
      src: img10,
      caption: `
      Both wear shell-and-turquoise jewelry
      Hopi Indian Reservation, AZ
      A. P. Miller, photographer, 1936
      ASM PIX-2615`,
    },
  ];

  const imageDescription = "";

  const previews = [
    {
      image: img1,
      url: "/Ancient-Shell",
      text: "Ancient Shell",
    },
    {
      image: img2,
      url: "/Hohokam-Shell",
      text: "Hohokam Shell",
    },
    {
      image: img3,
      url: "/Mogollon-Shell",
      text: "Mogollon Shell",
    },
    {
      image: img4,
      url: "/Paquime-Shell",
      text: "Paquime Shell",
    },
    {
      image: img5,
      url: "/Ancestral-Pueblo-Shell",
      text: "Ancestral Pueblo Shell",
    },
    {
      image: img6,
      url: "/Spondylus-Shell",
      text: "Spondylus Shell",
    },
    {
      image: img7,
      url: "/Coral",
      text: "Coral",
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img8}
      imageDescription={imageDescription}
      photos={photos_one}
      previews={previews}
    />
  );
};

export default Shell_and_Coral;
