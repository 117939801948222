import React from "react";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-5/2-5_photobox-1.jpg";
import img2 from "./heading2/subheading 2-5/2-5_photobox-2.jpg";
import img3 from "./heading2/subheading 2-5/2-5_photobox-3.jpg";
import img4 from "./heading2/subheading 2-5/2-5_photobox-4.jpg";
import img5 from "./heading2/subheading 2-5/2-5_photobox-5.jpg";
import img6 from "./heading2/subheading 2-5/2-5_photobox-6.jpg";
import img7 from "./heading2/subheading 2-5/2-5_photobox-7.jpg";
import img8 from "./heading2/subheading 2-5/2-5_photobox-8.jpg";
import img9 from "./heading2/subheading 2-5/2-5_photobox-9.jpg";
import img10 from "./heading2/subheading 2-5/2-5_photobox-10.jpg";
import img11 from "./heading2/subheading 2-5/2-5_photobox-11.jpg";

const Animal_Forms = () => {
  const title = "Animal Forms";

  const details1 =
    `Although the earliest pendants carved by the ancestors of modern artists consisted mostly of geometric forms, by around 700 CE there was a vibrant tradition of carving a wide assortment of shapes, from birds and lizards to dogs and humans. The contexts in which these were sometimes found indicate that they likely served as talismans or amulets that held special meaning for their owners.
`.split("\n");

  const quote =
    `"In our tradition, fetishes are used for things like healing, protection, spiritual guidance, good luck and longevity." Lena Leki Boone, A:shiwi.
`.split("\n");

  const details2 =`
    A:shiwi (Zuni) artists have followed the spiritual and creative traditions of their ancestors in carving animals of shell and stone. Called fetishes, these objects have served as hunting talismans and protective amulets. Common early representations include bears and mountain lions.

    The ancient A:shiwi (Zuni) site of Hawikku was excavated by archaeologists from the Heye Foundation, in New York, from 1917 to 1923. Among the items recovered were carved animals. C. G. Wallace, a trading post proprietor based near Zuni Pueblo, promoted the idea that the excavation’s lead archaeologist, Frederick Webb Hodge, employed Leekya Deyuse, the first well-known carver of fetishes for the commercial market. This story is likely apocryphal, as Deyuse is not listed among the Native men recorded as having worked for Hodge at Hawikku.

    In the 1920s, A:shiwi (Zuni) artisans began to create fetish necklaces, consisting of carved and perforated animal figures interspersed along strands of shell heishi. An increasing number of Diné (Navajo) artisans and individuals from other tribes also joined the ranks of fetish-necklace makers at that time.

    Trader Ruth Kirk, of Gallup, New Mexico, is most often credited with encouraging A:shiwi artisans to string fetishes into necklaces for sale. Traders C. G. Wallace and Edward Kelsey were the first to market them, according to author Mark Bahti (2016:23).

    Fetish necklaces were particularly popular during the Indian jewelry craze of the 1970s. The range of animals depicted by Zuni lapidarists grew substantially. Since the animals carved to be sold are not blessed in the culturally appropriate manner, the A:shiwi do not consider them to be true fetishes.

    Increasingly, Asian producers have made copies of these carved-animal beads for export to U.S. markets. Without an easy way for buyers to distinguish A:shiwi-made fetish beads from knockoffs, the market for fetish necklaces has declined. However, there are still a number of A:shiwi and Diné artisans who continue to carve and string impressive fetish necklaces in both traditional and more inventive styles.
`.split("\n");

  const photos_one = [
    {
      title: `Ram-effigy pendant, C. 1150–1450 CE
      Casas Grandes, Northern Sierra Province`,
      src: img1,
      caption: `
        Ricolite (a form of serpentine)
        Rancho Corralitos, Río Casas Grandes, Chihuahua, Mexico
        Gift of Gila Pueblo Foundation, 1950
        Ledwidge purchase, before 1926
        L: 5.5 cm
        GP4473-X-1`,
    },
    {
      title: `Quadruped-effigy pendant, C. 1150–1450 CE
      Casas Grandes, Northern Sierra Province`,
      src: img2,
      caption: `
      Ricolite (a form of serpentine)
      Rancho Corralitos, Río Casas Grandes, Chihuahua, Mexico
      Gift of Gila Pueblo Foundation, 1950
      Ledwidge purchase, before 1926
      L: 4.0 cm
      GP-4473-X-2`,
    },
    {
      title: `Quadruped-effigy pendant, C. 1150–1450 CE
      Casas Grandes, Northern Sierra Province`,
      src: img3,
      caption: `
        Ricolite (a form of serpentine)
        Rancho Corralitos, Río Casas Grandes, Chihuahua, Mexico
        Gift of Gila Pueblo Foundation, 1950
        Ledwidge purchase, before 1926
        L: 5.6 cm
        GP-4473-X-5`,
    },
    {
      title: `Multiple animal pendants`,
      src: img4,
      caption: `
      From left to right and top to bottom:
      W: 3.1 cm
      1997-194-810
      Flying-bird-effigy pendant
      Laevicardium elatum shell
      Hohokam, New River Province
      C. 950–1150 CE
      Skunk Creek Site, New River, Phoenix Basin, Maricopa Co., AZ
      Desert Hills Mitigation Project, 2000

      L: 1.6 cm
      2000-106-1
      Dog/coyote-effigy pendant
      Laevicardium elatum shell
      Hohokam, Gila Bend Province
      C. 700–1200 CE
      Gila Bend area, Gila River, Maricopa Co., AZ
      Gift of Norton and Ethel Allen, 1997

      L: 2.8 cm
      1997-194-816
      Star-shaped pendant
      Laevicardium elatum shell
      Hohokam, Gila Basin Province
      C. 950–1100 CE
      Grewe Site, Gila River Basin, Pinal Co., AZ
      Grewe Data Recovery Project, 2001

      L: 1.6 cm
      2001-167-252
      Two-headed quadruped
      Chrysoprase (chalcedony)
      Hohokam, Gila Bend Province
      C. 700–1450 CE
      Unknown site
      Gift of Norton and Ethel Allen, 1997
      
      L: 4.3 cm
      1997-194-2205
      Turquoise quadruped pendant
      Low-grade turquoise
      Mogollon, San Simon Province
      Date unknown
      Cave Creek area, near Rodeo, NM
      Gift of E. John Hands, before 1938
      W: 4.8 cm
      5467`,
    },
    {
      title: `Steatite bird figure pendant, C. 775–1150 CE
      Hohokam, Tonto-Roosevelt Province`,
      src: img5,
      caption: `
        Steatite (soapstone)
        Ushklish Ruin, Tonto Basin, Gila Co., AZ
        Highway Salvage Project, 1971
        L: 4.0 cm
        A-40829`,
    },
    {
      title: `Turquoise quadruped pendant, C. 1100–1300 CE
      Hohokam, Tucson Province`,
      src: img6,
      caption: `
      Turquoise
      Steam Pump Ranch Site, Cañada del Oro, Pima Co., AZ
      Gift of Mosa S. Alrobiane, 1969
      W: 2.1 cm
      A-36538`,
    },
    {
      title: `Argillite quadruped (possible dog) pendant, C. 1275–1400 CE
      Highland Mogollon`,
      src: img7,
      caption: `
        Argillite (also called claystone or pipestone; Del Rio source)
        Grasshopper Pueblo, Cibecue area, Fort Apache Indian Reservation, AZ
        UA Archaeological Field School, 1969
        L: 0.5 cm
        A-34043`,
    },
    {
      title: `Serpentine quadruped pendant, C. 1200–1400 CE
      Highland Mogollon`,
      src: img8,
      caption: `
      Serpentine
      Kinishba, Whiteriver, Fort Apache Indian Reservation, AZ
      University Expedition, 1933
      L: 3.5 cm
      21659`,
    },
    {
      title: `Glycymeris frog pendant, C. 1150–1300 CE
      Hohokam, Tucson Province`,
      src: img9,
      caption: `
        Glycymeris gigantea shell
        Hodges Ruin, Santa Cruz River, Tucson, Pima Co., AZ
        Gift of Mr. and Mrs. Wetmore Hodges, 1944
        L: 3.7 cm
        A-7311`,
    },
    {
      title: `Turquoise-and-shell fetish necklace, c. 1996
      Pueblo of Zuni, NM`,
      src: img10,
      caption: `
      A:shiwi (Zuni)
      Turquoise, shell, coral
      Gift of Grace Niemcziek, 2000
      L: 31 cm
      2000-91-61

      Attached to each carved bear on this fetish necklace are single coral, shell, and turquoise beads.`,
    },
    {
      title: `Turquoise-and-shell fetish necklace, c. 1975
      Attributed to Edna Weahkee Leki and Sarah Leekya
      Pueblo of Zuni, NM`,
      src: img11,
      caption: `
      A:shiwi (Zuni)
      Turquoise, shell
      Gift of Thomas and Harriet Lacy, 1982
      L: 41.5 cm
      1982-61-1

      This necklace of well-carved fetishes was sold as the work of “Old Man Leekya” (1889–1966). Leekya Deyuse was arguably the most famous A:shiwi (Zuni) fetish carver of the twentieth century. This renown led to many works being attributed to him. As author Kent McManis (1995:38) wrote, "Because of the value and popularity of Leekya’s work, fakes (usually poorly copied) are not uncommon. Carvings by members of his family are often sold, intentionally or unintentionally, as his."

      Author Mark Bahti has since identified the makers as Edna Weahkee Leki (1923–2003) and Sarah Leekya (1930–2017). Sarah was Leekya Deyuse’s daughter. One feature of her fetish animals is their upturned snouts. Edna’s father was well-known carver Teddy Weahkee. She was likely the first A:shiwi woman to take up fetish carving.

      These two women were known to string Leekya’s fetishes into necklaces that were sold through traders, including C. G. Wallace and the Vander Wagens, and they also carved and strung their own fetish necklaces.`,
    },
  ];

  const imageDescription = "";

  return (
    <Content
      title={title}
      specialRender={
        <>
          {details1.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <div className="quote">
            {quote.map((line, i) => (
              <span key={i}>{line}</span>
            ))}{" "}
            <span style={{ fontStyle: "normal" }}>
              (Zuni; Ostler et al. 1996:24)
            </span>
          </div>
          {details2.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </>
      }
      clickableImage={img4}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Animal_Forms;
