import React, { useState, useEffect } from "react";
import "./About.css";
import { motion } from "framer-motion";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

import img1 from "../../Screens/Pages/Heading-Two/heading2/subheading 2-2/subheading 2-2-6/2-2f_photobox-1.jpg";
import References from "./Suggested_Readings.pdf";

const About = () => {
  const CDH = [
    {
      name: "Dr. Bryan Carter",
      role: "Director",
    },
    {
      name: "Hamad Marhoon",
      role: "Lead Developer",
    },

    {
      name: "Andrew Waugaman",
      role: "Developer",
    },

    {
      name: "Daniel I. Leon",
      role: "Developer",
    },
    {
      name: "Josh Tornquist",
      role: "Developer",
    },
    {
      name: "Bahaa Abdulraheem",
      role: "Developer",
    },
    {
      name: "Nathan Teku",
      role: "Developer",
    },
    {
      name: "Alyssa Yanez",
      role: "Developer",
    },
  ];

  const ASU = [
    {
      name: "Diane Dittemore",
      role: "Co-Curator",
    },
    {
      name: "Arthur Vokes",
      role: "Retired, Co-Curator",
    },
    {
      name: "Dr. Patrick Lyons",
      role: "ASM Director/Contributing Curator",
    },
    {
      name: "Lisa Falk",
      role: "Project Manager/Contributing Curator",
    },
    {
      name: "Jannelle Weakly",
      role: "Manager, Photographic Collections/Contributing Curator",
    },
    {
      name: "Michelle Dillon",
      role: "Photographer",
    },
    {
      name: "Max Mijn",
      role: "Photographer",
    },
    {
      name: "Tobi Lopez Taylor",
      role: "Editor",
    },
    {
      name: "Gail Gibbons",
      role: "Volunteer Researcher",
    },
    {
      name: "Jennalyn Tellman",
      role: "Volunteer Researcher",
    },
  ];

  const counsel = [
    {
      name: "Bahti Indian Arts",
    },
    {
      name: "Jonathan Batkin",
    },
    {
      name: "Pat and Kim Messier",
    },
    {
      name: "Felipe Molina",
    },
    {
      name: "Diana Pardue, Heard Museum",
    },
    {
      name: "Deborah Slaney",
    },
    {
      name: "Tom Kolaz",
    },
    {
      name: "Micky Vander Wagen",
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.1 }}
      className="about-page-container">
      <div className="about-page-header">
        <div className="about-header-text">About</div>
      </div>
      <div className="organizations">
        <div className="organization">
          <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Arizona State Museum
          </p>
          {ASU.map((person) => {
            return (
              <div className="aboutPerson">
                <p className="aboutName">{person.name}</p>
                <p className="aboutRoles">{person.role}</p>
              </div>
            );
          })}
        </div>
        <div className="organization">
          <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Content Advisors
          </p>
          {counsel.map((person) => {
            return (
              <div className="aboutPerson">
                <p className="aboutName" id="counsel">
                  {person.name}
                </p>
              </div>
            );
          })}
          <br />
          <br />
          <p className="aboutName" id="counsel">
            And many thanks to all the artists whose work is included in this
            exhibit.
          </p>
        </div>

        <div className="organization">
          <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Center for Digital Humanities
          </p>
          {CDH.map((person) => {
            return (
              <div className="aboutPerson">
                <p className="aboutName">{person.name}</p>
                <p className="aboutRoles">{person.role}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="references">
        <p
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            textAlign: "left",
          }}>
          References
        </p>
        <div>
          Download readings and references
          <a
            href={References}
            download="Readings and References"
            target="_blank"
            rel="noreferrer">
            {" "}
            here
          </a>
        </div>
        <br />
        <div>
          Unless specified, all images are from the Photograph Collection of the
          Arizona State Museum, University of Arizona. Copyright and publication
          rights for all photographs are retained by the Arizona Board of
          Regents. For reproduction and/or permission rights,{" "}
          <a
            href="https://statemuseum.arizona.edu/collections/photography"
            target="_blank">
            contact the Collection Manager
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
