import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-4/2-4_photobox-1.jpg";
import img2 from "./heading2/subheading 2-4/2-4_photobox-2.jpg";
import img3 from "./heading2/subheading 2-4/2-4_photobox-3.jpg";

const Technological_Changes = () => {
  const title = "Technological Changes";

  const details =
    `The ancient makers of stone and shell jewelry employed tools that included stone abraders and knives as well as stone-tipped wooden pump drills that were especially useful in making beads. They attached mosaic pieces to shell or wooden backings with adhesives that included pinyon and mesquite pitch and insect lac (a resin-like secretion of a number of insect species). Hohokam artisans, over a short period of time, produced distinctive etched shells using cactus juice as the acidic etching substance. Paint from mineral sources was occasionally applied to jewelry. For stringing jewelry, cords of yucca or cotton were braided or twisted. In archaeological contexts, the cords rarely have survived; thus, restrung ancient jewelry may or may not reflect the original order of stringing.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `How beads were made`,
      src: img1,
      caption: `
      (a) Stone with a groove for shaping beads, made of tuff, from Los Muertos (Haury 1945a, Fig. 86). (b) A hand pump; probably this type was used for drilling holes in making beads (Knoblock 1939, pl. 21, No. 1). (c) Some beads were made by wrapping clay about a stem of grass, incising all the way around at regular intervals where the beads would then be broken away after baking, from Los Muertos (Haury 1945a, Fig. 75). Illustration from Tanner (1976:150).

        With the coming of Europeans to the region, Pueblo lapidarists were able to add tools and materials that simplified the stone- and shell-working process. This included metal tips for the drills, metal files, and commercial cotton string and stringing wire. By the 1940s, electric tools and the electricity required to run them became increasingly available.`,
    },
    {
      title: `Tsnahey, A:shiwi (Zuni), making beads with a pump drill, Pueblo of Zuni, NM`,
      src: img2,
      caption: `
        F. H. Maude, photographer, c. 1890 – 1900,
        ASM 1988-99-064`,
    },
    {
      title: `Phyllis Laate, A:shiwi (Zuni), uses an electric motor to grind stones into shape, Pueblo of Zuni, NM`,
      src: img3,
      caption: `
        Helga Teiwes, photographer, 1977
        ASM 46979`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img3}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Technological_Changes;
