import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-01-1.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-01-2.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-02-1.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-02-2.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-03-1.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-03-2.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-04.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-06.jpg";
import img9 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-07.jpg";
import img10 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-08-1.jpg";
import img11 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-08-2.jpg";
import img12 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-08-3.jpg";
import img13 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-08-4.jpg";
import img14 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-08-5.jpg";
import img15 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-09.jpg";
import img16 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-10-1.jpg";
import img17 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-10-2.jpg";
import img18 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-05.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import NoImage from "./heading3/No_image_available.png";

import Content from "../../../Components/Content/Content";

const Dine_Navajo_Jewelry = () => {
  const title = "Diné (Navajo) Jewelry";

  const details =
    `Traditional Diné (Navajo) jewelry is characterized by large settings of turquoise, elaborate stampwork, and use of the sandcasting technique.

Around 1880, Diné smiths began to make silver-bead necklaces whose central pendants are known by the Navajo term naja. Such horseshoe-shaped ornaments, with Spanish-Moorish roots, decorated the bridles worn by the horses of Spanish colonialists, and Diné artisans incorporated them into their jewelry. Not long after 1880, “squash blossom” beads—Navajo interpretations of Spanish pomegranate blossoms—appeared interspersed along the sides of naja pendants. This marriage of squash blossom beads and the naja created one of the most iconic forms of jewelry associated with the U.S. Southwest. 

By 1940, many Native jewelers had moved to population centers in Arizona, New Mexico, and Colorado, where they were employed by traders and curio shop owners. 

Also in the 1940s, some Diné (Navajo) silversmiths began to make A:shiwi (Zuni)-style cluster work, which had become a particularly popular style for Navajos themselves to wear. They were able to purchase sets of cut stones that A:shiwi (Zuni) lapidarists produced, with traders frequently the intermediaries. 

In the 1970s, Native American jewelry experienced an enormous boom in popularity. Hundreds of Diné (Navajo) smiths produced silver-and-turquoise jewelry in great quantity. Distinctive features of jewelry from this period include the addition of silver leaves and dots, along with braided wire, complementing the stamped designs and substantial turquoise settings.This era also marked the blossoming of creativity in design, materials, and experimental technologies that endures to this day. Both seasoned and emerging Diné (Navajo) jewelry artists continue to expand ideas about what constitutes “Native” jewelry. 

`.split("\n");

  const imageDescription = "";

  const photos = [
    {
      title: `Silver-and-turquoise squash blossom necklace, c. 1890, Navajo`,
      src: img1,
      caption: `
      Belonged to Chief Hoskinnini (d. 1912) 
        Navajo Nation, AZ or UT
        ASM purchase from Clay Lockett, 1947 
        Lockett acquired from the Wetherill family
        E-2311 

        This squash blossom necklace is among the oldest examples of silver jewelry in ASM’s collections. Hoskinnini was a Navajo leader who befriended John and Louisa Wetherill when they were operating a trading post at Oljeto, Utah, in the early twentieth century. 
        
        When ASM received this necklace, it was missing an unknown quantity of original beads.`,
    },
    {
      title: `Yalálag cross necklace with pomegranate blossom beads, c. 1938, Zapotec`,
      src: img2,
      caption: `
      Yalálag, Oaxaca, Mexico
        Silver, glass beads 
        Donald and Dorothy Cordry Collection
        Gift of the University of Arizona Foundation, 1979
        L: 31 cm 
        1979-135-1268 
        
        The inspiration for the Navajo “squash blossom” bead shape was Mexican silver artisans’ representations of developing pomegranate fruits, as seen on this Zapotec cross necklace from Oaxaca. Silver pomegranates also served as Mexican trouser decorations, another possible source of this distinctive bead shape.`,
    },
    {
      title: `Silver ring with turquoise setting, c. 1900, Diné (Navajo)`,
      src: img3,
      caption: `
        AZ, NM, or UT
        ASM purchase, Nelle Dermont Collection, 1919
        D: 2.3 cm 
        8268 `,
    },
    {
      title: `The Silversmith’s Daughter, Diné (Navajo)`,
      src: img4,
      caption: `
      Wearing a similar silver-and-turquoise ring 
Near Gallup, NM
Joseph Roy Willis, photographer, 1920
Library of Congress, LC-USZ62-33619 `,
    },
    {
      title: `Diné (Navajo) woman wearing a shell necklace and silver concha belt`,
      src: img5,
      caption: `
      A blouse adorned with silver buttons, and a “broomstick” skirt that had become traditional women’s wear by the 1880s.
Navajo Nation, AZ, NM, or UT 
Unknown photographer, 1925
ASM PIX-1483-x-7 `,
    },
    {
      title: `Louisa Wetherill and Sitsosie, Diné (Navajo)`,
      src: img6,
      caption: `
      Oljeto, UT 
Sitsosie has a leather pouch adorned with silver buttons, which were popular with men at this time. 
Unknown photographer, Wetherill Collection, c. 1906–1910 
ASM 2004-1447-1092 
`,
    },
    {
      title: `Stamped silver cuff bracelet with square turquoise, c. 1930, Diné (Navajo)`,
      src: img7,
      caption: `
      AZ, NM, or UT 
Gift of Mary Cabot Wheelwright, 1942
W: 3.1 cm 
E-1244`,
    },
    {
      src: img18,
      title: `
      Silver-and-turquoise squash blossom necklace, c. 1925, Diné (Navajo)`,
      caption: `AZ, NM, or UT
    Gift of E. John Hands, 1938
    L: 37 cm
    E-80
    `,
    },
    {
      title: `Cast-silver-and-turquoise ketoh (bow guard), c. 1930, Diné (Navajo)`,
      src: img8,
      caption: `
      AZ, NM, or UT
Silver, turquoise, leather 
Pierre Lecomte du Noüy Collection
Gift of Mrs. Lecomte du Noüy, 1968
L: 8.7 cm 
E-7432 
`,
    },
    {
      title: `Silver cuff bracelet with triangular turquoise settings, c. 1940, Diné (Navajo)`,
      src: img9,
      caption: `
      AZ, NM, or UT
Gift of Catherine Sease, 2020
W: 6.4 cm 
2020-426-3  `,
    },
    {
      title: `Cuff bracelet with Persian turquoise, c. 1975, Diné (Navajo)`,
      src: img10,
      caption: `
      Ambrose Lincoln, AZ, NM, or UT 
Robert Tierney and Eino Wiiretjarvi Collection
Gift of the University of Arizona Foundation, 1980
L: 7.8 cm 
1980-47-311 `,
    },
    {
      title: `Hallmark for cuff bracelet with Persian turquoise, c. 1975, Diné (Navajo)`,
      src: img11,
      caption: `
      Ambrose Lincoln, AZ, NM, or UT 
Robert Tierney and Eino Wiiretjarvi Collection
Gift of the University of Arizona Foundation, 1980
L: 7.8 cm 
1980-47-311 `,
    },
    {
      title: `Basket weaver Katherine Sells, Diné (Navajo)`,
      src: img12,
      caption: `
      Wearing a cluster pin and cuff bracelet, Window Rock, Navajo Nation, AZ 
Helga Teiwes, photographer, 1972 
ASM 34513 `,
    },
    {
      title: `Cluster ring with Pilot Mountain turquoise, 1970, Diné (Navajo)`,
      src: img13,
      caption: `
Kathy Abeita, AZ, NM, or UT 
Robert Tierney Collection
Gift of the University of Arizona Foundation, 1980
L: 4.5 cm 
1980-47-143 
`,
    },
    {
      title: `Hallmark for cluster ring with Pilot Mountain turquoise, 1970, Diné (Navajo)`,
      src: img14,
      caption: `
Kathy Abeita, AZ, NM, or UT 
Robert Tierney Collection
Gift of the University of Arizona Foundation, 1980
L: 4.5 cm 
1980-47-143 
`,
    },
    {
      title: `Sand-cast ketoh (bow guard) with carved turquoise snake, c. 1950`,
      src: img15,
      caption: `
Carved snake, A:shiwi (Zuni); silverwork, Diné (Navajo), AZ, NM, or UT 
Gift of the Estate of Oskar Grunow, 1979
L: 9.2 cm 
79-87-9 
`,
    },
    {
      title: `Wilson Teller, Diné (Navajo)`,
      src: img16,
      caption: `
      Hammers a piece of silver, Chinle, Navajo Nation, AZ 
Helga Teiwes, photographer, 1974 
ASM 38834 
`,
    },
    {
      title: `Denet Chee, Diné (Navajo)`,
      src: img17,
      caption: `
      Uses a torch on a silver piece, Chinle, Navajo Nation, AZ 
Helga Teiwes, photographer, 1974 
ASM 38837 
`,
    },
  ];
  return (
    <Content
      title={title}
      details={details}
      clickableImage={img7}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Dine_Navajo_Jewelry;
