import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-1-1.jpeg";
import img2 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-1-2.jpeg";
import img3 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-1-3.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-2-1.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-2-2.jpeg";
import img10 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-2-3.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-3-1.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-3-2.jpeg";
import img11 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-3-3.jpeg";
import img8 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-4-1.jpg";
import img9 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-4-2.jpeg";
import img12 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-4 gerald lomaventema and master apprentices/3-4b-4 photobox-4-3.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Gerald_Lomaventema_and_Master_Apprentices = () => {
  const title = "Gerald Lomaventema and Master-Apprentices";

  const details =
    `The Hopi Guild ceased operations around 2015, yet silverworking continues to be taught in both formal and informal ways. One silversmith, Gerald Lomaventema, who learned the craft as a student at the Hopi Arts and Crafts Guild in the 1980s, teaches apprentices at his shop in the Cultural Center at Second Mesa. He was the recipient of a 2016 Southwest Folklife Alliance Master-Apprentice Artist Award. His apprentices have included Delwyn Spyder, Jerolyn Honwytewa, and Clinessia Lucas.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos = [
    {
      title: `Katsina maiden pendant and chain, 2021
      Hopi`,
      src: img1,
      caption: `
      Gerald Lomaventema (pendant), Yvette Talaswaima (chain)
Hopi Indian Reservation, AZ
Pilot Mountain natural turquoise, silver
ASM purchase, Friends of the ASM Collections funds
L: 31.0 cm
2021-341-1`,
    },
    {
      title: `Hallmark for katsina maiden pendant
      Hopi`,
      src: img2,
      caption: `
      Gerald Lomaventema (pendant), 2021
Hopi Indian Reservation, AZ
Pilot Mountain natural turquoise
ASM purchase, Friends of the ASM Collections funds
L: 31.0 cm
2021-341-1`,
    },
    {
      title: `Gerald Lomaventema, Hopi`,
      src: img3,
      caption: `
      Santa Fe Indian Market, Santa Fe, NM
Jannelle Weakly, photographer, 2021`,
    },
    {
      title: `Silver ring with coral setting, 2020
Hopi`,
      src: img4,
      caption: `
      Clinessia Lucas 
Hopi Indian Reservation, AZ
ASM purchase, Friends of the ASM Collections funds
W: 4.5 cm
2021-241-3

"This represents the male and female to create life, and bring moisture, beauty, and pollination." - Clinessia Lucas, 2020`,
    },
    {
      title: `Hallmark for silver ring with coral setting, 2020
      Hopi`,
      src: img5,
      caption: `
      Clinessia Lucas
Hopi Indian Reservation, AZ
ASM purchase, Friends of the ASM Collections funds
W: 4.5 cm
2021-241-3`,
    },
    {
      src: img10,
      title: `Clinessia Lucas`,
      caption: `
      Photograph courtesy Southwest Traditions, 2018`,
    },
    {
      title: `Silver overlay cuff, 2020
      Hopi`,
      src: img6,
      caption: `
      Delwyn Spyder
Hopi Indian Reservation, AZ
ASM purchase, Friends of the ASM Collections funds
Width: 3.3 cm
2021-241-1`,
    },
    {
      src: img7,
      title: `Hallmark for silver overlay cuff, 2020
      Hopi`,
      caption: `
      Hallmark for silver overlay cuff, 2020
Delwyn Spyder, Hopi
Hopi Indian Reservation, AZ
ASM purchase, Friends of the ASM Collections funds
Width: 3.3 cm
2021-241-1`,
    },
    {
      src: img11,
      title: `Delwyn Spyder`,
      caption: `
      Photo courtesy of the artist, 2022`,
    },

    {
      title: `Silver overlay cuff with Hubei (Chinese) turquoise setting, 2020
      Hopi`,
      src: img8,
      caption: `
      Jerolyn Honwytewa
Hopi Indian Reservation, AZ
ASM purchase, Friends of the ASM Collections funds
W (at stone): 1.2 cm
2021-241-2

"The turquoise represents a flower, with rain clouds and wind designs, along with geometric designs." - Jerolyn Honwytewa, 2020`,
    },
    {
      src: img9,
      title: `Hallmark for silver overlay cuff with Hubei (Chinese) turquoise setting, 2020 
      Hopi`,
      caption: `
      Jerolyn Honwytewa, Hopi
Hopi Indian Reservation, AZ
ASM purchase, Friends of the ASM Collections funds
W (at stone): 1.2 cm
2021-241-2`,
    },
    {
      src: img12,
      title: `Jerolyn Honwytewa`,
      caption: `
      Photograph courtesy of Southwest Traditions, 2018`,
    },
  ];
  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Gerald_Lomaventema_and_Master_Apprentices;
