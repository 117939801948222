import "./Content.css";
import ClickableImage from "../ClickableImage/ClickableImage";
import GalleryComponent from "../Gallery/Gallery";
import Preview from "../Preview/Preview";
import BackButton from "../BackButton/BackButton";

export default function Content({
  title,
  details,
  specialRender,
  clickableImage,
  imageDescription,
  previews,
  photos,
  video,
}) {
  return (
    <div className="headingsContainer">
      <BackButton />
      <div className="heading-content">
        <div className="heading-text">
          <div className="heading-title">
            <h1>{title}</h1>
          </div>
          <div className="heading-description">
            {details?.map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
            {specialRender}
            <div className="scroll-indicator"></div>
          </div>
        </div>
        <div className="heading-right">
          <ClickableImage
            video={video}
            image={clickableImage}
            description={imageDescription}
            exhibitName={title}
          />
        </div>
      </div>
      {previews && (
        <div>
          <div className="preview-header">
            <h2>Related Pages</h2>
            <hr />
          </div>
          <div className="previewContainer">
            {previews.map((preview, i) => (
              <Preview
                key={i}
                image={preview.image}
                url={preview.url}
                text={preview.text}
              />
            ))}
          </div>
        </div>
      )}
      {photos && <GalleryComponent images={photos} />}
    </div>
  );
}
