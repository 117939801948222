import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-1-1.jpg";
import img2 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-1-2.jpg";
import img3 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-2-1.jpg";
import img4 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-2-2.jpg";
import img5 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-2-3.jpg";
import img6 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-3-1.jpg";
import img7 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-3-2.jpg";
import img8 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-4-1.jpg";
import img9 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-4-2.jpeg";
import img10 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-4-3.jpg";

const Gold = () => {
  const title = "Gold";

  const details =
    `Famed Hopi artist Charles Loloma (1921-1991) was among the first Native jewelers to use gold as a raw material, beginning in the 1950s. Others, including Preston Monongye, Mexican/Mission/Hopi; Eddie Beyuka, A:shiwi (Zuni); and Kenneth Begay, Diné (Navajo), followed suit. By the late 1960s, many prominent jewelers were creating works in gold for the high-end market. This trend has continued today, with spectacular results.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Cast-gold ring, c. 1972
      Charles Loloma, Hopi, AZ`,
      src: img1,
      caption: `
        Hotvela, Third Mesa, Hopi Indian Reservation, AZ
        ASM Purchase, Arizona Archaeological and Historical Society Fund
        D: 2.3 cm
        E-9996`,
    },
    {
      title: `Charles Loloma, Hopi, with his jewelry exhibit in Dewey-Kofron Gallery, Santa Fe, NM`,
      src: img2,
      caption: `
      Helga Teiwes, photographer, 1977
      ASM 46193`,
    },
    {
      title: `Gold, coral, opal, and turquoise pin, 2000
      Allenroy Paquin (b. 1954), A:shiwi (Zuni)/Jicarilla Apache`,
      src: img3,
      caption: `
        Albuquerque, NM
        Gold, coral, turquoise, opal
        ASM Purchase, B. C. Waddell Memorial Purchase Award
        ASM Southwest Indian Art Fair, 2000
        L: 6 cm
        2000-42-3`,
    },
    {
      title: `Hallmark for gold, coral, opal, and turquoise pin, 2000
      Allenroy Paquin (b. 1954), A:shiwi (Zuni)/Jicarilla Apache`,
      src: img4,
      caption: `
        Albuquerque, NM
        Gold, coral, turquoise, opal
        ASM Purchase, B. C. Waddell Memorial Purchase Award
        ASM Southwest Indian Art Fair, 2000
        L: 6 cm
        2000-42-3`,
    },
    {
      title: `Allenroy Paquin, A:shiwi (Zuni)/Jicarilla Apache`,
      src: img5,
      caption: `
        Southwest Indian Art Fair, Arizona State
        Emily Kleinkauf, photographer, 2006`,
    },
    {
      title: `Silver and gold bola tie, 2012
      Julius Keyonnie (b. 1964), Navajo`,
      src: img6,
      caption: `
          Winslow, AZ
          Silver, gold, leather
          ASM Purchase, Friends of the ASM Collections Hartman Lomawaima Memorial Acquisition
          Award, ASM Southwest Indian Art Fair, 2012
          L: 57 cm
          2012-113-1
          
          Julius Keyonnie is known for making contemporary-style jewelry, including seed pots in gold-accented silver such the ornament on this bola tie, a Southwest Indian Art Fair acquisition award winner.`,
    },
    {
      title: `Julius Keyonnie, Diné (Navajo), with his award-winning concha belt and cuff bracelet`,
      src: img7,
      caption: `
          Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ
          Jannelle Weakly, photographer, 2008

          Keyonnie began making contemporary-style jewelry in 1990 and has since experimented with a range of new designs and materials. Keyonnie was the featured artists at tASM’s 2009 Southwest Indian Art Fair.

          Keyonnie has also had a successful career in the rodeo ring. One related specialty has been custom-designed rodeo trophy belt buckles.`,
    },
    {
      title: `Pearl, stone, and gold necklace, c. 2000
      Steve LaRance, Hopi/Assiniboine`,
      src: img8,
      caption: `
        Ohkay Owingeh (San Juan) Pueblo, NM
        Loaned by a Friend of the Museum
        AT-2021-2

        A painter and sculptor as well, LaRance was drawn to making tufa cast jewelry in collaboration with his wife Marian Denipah, the technique which became their specialty.`,
    },
    {
      title: `Hallmark for Pearl, stone, and gold necklace, c. 2000`,
      src: img9,
      caption: `
        Steve LaRance, Hopi/Assiniboine
        Ohkay Owingeh (San Juan) Pueblo, NM
        Loaned by a Friend of the Museum
        AT-2021-2`,
    },
    {
      title: `Steve LaRance, Hopi/Assiniboine, at his booth, Santa Fe Indian Market, Santa Fe, NM`,
      src: img10,
      caption: `
        Jannelle Weakly, photographer, 2007

        Steve Wikviya LaRance (b. 1958) was born in Phoenix, and spent much time with his family in the Hopi town of Moencopi where he was immersed in Hopi traditional cultural arts. LaRance collaborates with his wife, Marian Denipah (Diné/San Juan Pueblo), a graduate in Fine Arts of Northern Arizona University.`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Gold;
