import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-04.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4b/3-4b clickable.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-12.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4d/3-4d photobox-1.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4e/3-4e photobox-1-1.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4f/3-4f-1 photobox-01.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4g/3-4g clickable.jpg";
import img25 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-17.jpg";

import GalleryComponent from "../../../Components/Gallery/Gallery";
import Preview from "../../../Components/Preview/Preview";
import Content from "../../../Components/Content/Content";

const Tribal_Styles = () => {
  const title = "Tribal Styles";

  const details =
    `From the 1920s to the 1950s, Hopi, A:shiwi (Zuni), and Diné (Navajo), jewelry styles became increasingly distinct from one another. This was in part due to efforts by traders, and in part a reflection of different tribal cultural values and traditions, and individual innovations. This shift was reflected to a lesser extent among other Pueblos as well. O'odham and Yaqui jewelry aimed to an outside market developed later in the 20th century.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos = [];

  const previews = [
    {
      image: img1,
      url: "/Dine-Navajo-Jewelry",
      text: "Dine Navajo Jewelry",
    },
    {
      image: img2,
      url: "/Hopi-Jewelry",
      text: "Hopi Jewelry",
    },
    {
      image: img3,
      url: "/Ashiwi-Zuni-Jewelry",
      text: "A:shiwi (Zuni) Jewelry",
    },
    {
      image: img4,
      url: "/Kewa-Santo-Domingo-Jewelry",
      text: "Kewa (Santo Domingo) Jewelry",
    },
    {
      image: img5,
      url: "/Cross-Dragonfly-Jewelry",
      text: "Cross Dragonfly Jewelry",
    },
    {
      image: img6,
      url: "/Oodham-Jewelry",
      text: "Oodham Jewelry",
    },
    {
      image: img7,
      url: "/Yaqui-Yoeme-Jewelry",
      text: "Yaqui Yoeme Jewelry",
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img25}
      imageDescription={imageDescription}
      previews={previews}
    />
  );
};

export default Tribal_Styles;
