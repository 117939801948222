import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-1.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-2-1.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-2-2.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-3-1.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-3-2.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-4.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-5-1.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-5-2.jpeg";
import img9 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-6-1.jpg";
import img10 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-6-2.jpg";
import img11 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-7-1.jpg";
import img12 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-7-2.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Hopicrafts = () => {
  const title = "Hopicrafts";

  const details =
    `Around 1960, brothers Wayne and Emory Sekaquaptewa of Hotvela (Hotevilla), on Third Mesa, both World War II veterans, established Hopi Enterprises in Scottsdale, Arizona. They had learned silverwork from Hopi jeweler Harry Sakyesva, who was also living in the Phoenix area. Hopi Enterprises hired Hopi smiths to make jewelry, which they sold through commissions from local businesses and other organizations. In 1962, the brothers moved the operation to the Hopi village of Kiqötsmovi (Kykotsmovi; “New Oraibi”), on Third Mesa, and renamed the business Hopicrafts. They opened a sales room and workshop on Second Mesa in 1963. The business closed in 1983.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos = [
    {
      title: `Emory Sekaquaptewa, Hopi`,
      src: img1,
      caption: `
      Co-founder of Hopi Enterprises/Hopicrafts, demonstrates silver overlay at ASM Open House, Tucson, AZ
Helga Teiwes, photographer, 1980
ASM 52983`,
    },
    {
      title: `Valley National Bank silver bola tie, c. 1960, Hopi`,
      src: img2,
      caption: `
Harry Sakyesva (1922–1969)
Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
W: 5.5 cm
J-667`,
    },
    {
      title: `Hallmark for Valley National Bank silver bola tie, c. 1960, Hopi`,
      src: img3,
      caption: `
Harry Sakyesva (1922–1969)
Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
W: 5.5 cm
J-667`,
    },
    {
      title: `Phoenix City Council bola, early 1960s, Hopi`,
      src: img4,
      caption: `
      Designed by Bernard Dawahoya, Hopicrafts, Phoenix, AZ
Silver, turquoise, coral
Courtesy Pat and Kim Messier
W: 4.5 cm
J-707`,
    },
    {
      title: `Hallmark for Phoenix City Council bola, early 1960s`,
      src: img5,
      caption: `
Designed by Bernard Dawahoya, Hopicrafts, Phoenix, AZ
Silver, turquoise, coral
Courtesy Pat and Kim Messier
W: 4.5 cm
J-707`,
    },
    {
      title: `Barbara Melzer, ASM Shop Manager, selecting jewelry at the Hopicrafts production shop`,
      src: img6,
      caption: `
Kiqötsmovi (Kykotsmovi; “New Oraibi”), Third Mesa, Hopi Indian Reservation, AZ
Helga Teiwes, photographer, 1976
ASM 37129`,
    },
    {
      title: `Silver overlay pendant depicting Hohokam dancers, with chain, c. 1960, Hopi`,
      src: img7,
      caption: `
Hopicrafts, owners Wayne and Emory Sekaquaptewa
Hopi Indian Reservation, AZ
ASM purchase from Tom Bahti Indian Arts, 1969
L: 4.5 cm (pendant)
E-8217

Hopicrafts jewelers drew upon Hohokam pottery motifs, perhaps encouraged by Emory Sekaquaptewa, who lived and worked in Tucson. He graduated from the University of Arizona Law School in 1970 and was an assistant professor in the UA Department of Anthropology from 1972 until his death in 2007.`,
    },
    {
      title: `Hallmark for silver overlay pendant depicting Hohokam dancers c. 1960, Hopi`,
      src: img8,
      caption: `
      Hopicrafts, owners Wayne and Emory Sekaquaptewa
Hopi Indian Reservation, AZ
ASM purchase from Tom Bahti Indian Arts, 1969
L: 4.5 cm (pendant)
E-8217`,
    },
    {
      title: `Silver cuff bracelet with frog designs, c. 1970, Hopi`,
      src: img9,
      caption: `
      Emory Sekaquaptewa (1928–2007)
Third Mesa, Hopi Indian Reservation, AZ
Gift of Pat and Kim Messier, 2014
W: 2.6 cm
2014-600-1`,
    },
    {
      title: `Hallmark for silver cuff bracelet with frog designs, c. 1970, Hopi`,
      src: img10,
      caption: `
      Emory Sekaquaptewa (1928–2007)
Third Mesa, Hopi Indian Reservation, AZ
Gift of Pat and Kim Messier, 2014
W: 2.6 cm
2014-600-1`,
    },
    {
      title: `Man-in-the-maze silver overlay belt buckle, c. 1975, Hopi`,
      src: img11,
      caption: `
      Glenn B. Lucas (1932–1985)
Born in Kiqötsmovi (Kykotsmovi), Third Mesa; lived in Musangnuvi (Mishongnovi), Second Mesa, Hopi Indian Reservation, AZ
Gift of the Estate of Richard Woodbury, 2011
L: 7.6 cm
2011-250-145A

Glenn Lucas was a silversmith with Hopicrafts beginning in the mid-1960s`,
    },
    {
      title: `Hallmark for man-in-the-maze silver overlay belt buckle, c. 1975, Hopi`,
      src: img12,
      caption: `
      Glenn B. Lucas (1932–1985)
Born in Kiqötsmovi (Kykotsmovi), Third Mesa; lived in Musangnuvi (Mishongnovi), Second Mesa, Hopi Indian Reservation, AZ
Gift of the Estate of Richard Woodbury, 2011
L: 7.6 cm
2011-250-145A`,
    },
  ];
  return (
    <Content
      title={title}
      details={details}
      clickableImage={img9}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Hopicrafts;
