import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-1-1.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-1-2.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-1-3.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-1-4.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-2-1.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-2-2.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-2-3.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-3-1.jpg";
import img9 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-3-2.jpeg";
import img10 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-3-3.jpg";
import img11 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-3-4.jpg";
import img12 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-4-1.jpg";
import img13 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-4-2.jpg";
import img14 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-4-3.jpg";
import img15 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-4-4.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Yaqui_Silver_Jewelry_Artists = () => {
  const title = "Yaqui Silver Jewelry Artists";

  const details =
    `Beginning in the 1970s, several Yaquis found success in silver jewelry artistry, achieving acclaim through different paths. 

Brothers Michael Garcia (Na Na Ping) and Anthony Garcia, members of the Pascua Yaqui tribe, are well known for their fine stone mosaic work. They were raised in the Yoeme (Yaqui) community of Guadalupe, south of Phoenix. Other Yoeme silversmiths include Florine Morillo, who learned from O’odham craftsman Rick Manuel, and Danny Romero, who was tutored by Navajo actor and silversmith Ray Tracey. Of these, only Florine Morillo regularly draws on Yaqui iconography in her work. 
`.split("\n");

  const imageDescription = "";

  const photos = [
    {
      title: `Silver-and-turquoise cuff bracelet with petroglyphs, 2008
      Yaqui (Yoeme)`,
      src: img1,
      caption: `
      Danny Romero (1954–2018)
  Born in Douglas, AZ; lived in Santa Fe, NM
  ASM Purchase, Friends of the ASM Collections funds, 2021
  W: 4.5 cm.
  2021-496-1 `,
    },
    {
      title: `Hallmark for silver-and-turquoise cuff bracelet with petroglyphs, 2008
      Yaqui (Yoeme)`,
      src: img2,
      caption: `
      Danny Romero (1954–2018)
    Born in Douglas, AZ; lived in Santa Fe, NM
    ASM Purchase, Friends of the ASM Collections funds, 2021
    W: 4.5 cm.
    2021-496-1
    `,
    },
    {
      title: `Danny Romero, Yaqui (Yoeme)`,
      src: img3,
      caption: `
      Santa Fe, NM
    Louella Romero, photographer, 1995
    Courtesy Louella Romero

    "I was surrounded by relatives who were artists and musicians. I grew up in Douglas, Arizona, 25 miles from the famous Bisbee Turquoise Mine. My dad traded Bisbee turquoise with the Navajo." - Danny Romero, Bischoff Gallery website

    Danny Romero (1954–2018) was an innovative silversmith known for incorporating ancient designs into his work. His father was Yaqui; his mother, Hispanic. Romero began working in silver in 1980 while enrolled in medical school at the University of Arizona. According to his widow, Louella Romero, “He thought a future as an artist was more appealing than as a doctor. His mother was furious.” Romero spent much of his creative life in Santa Fe, New Mexico.

    Romero credited a wide range of Western artists—including Edward S. Curtis, John Nieto, Paul Pletka, and Howard Terpning of Tucson—with inspiring his portrait jewelry and the color palette he employed.
    `,
    },
    {
      title: `Inlay portrait ring
      Yaqui (Yoeme)`,
      src: img4,
      caption: `
      Danny Romero
    Santa Fe, NM
    Courtesy Louella Romero
    Jannelle Weakly, photographer, 2021
    H: 3.8 cm.

    Danny Romero was also known for his jewelry that incorporated multicolored stone-inlay portraiture.
    `,
    },
    {
      title: `Eye of the Pascola, silver-and-sugilite cuff, c. 2018
      Pascua Yaqui Tribe`,
      src: img5,
      caption: `
      Na Na Ping (Michael Garcia)
    Santa Fe, NM
    ASM purchase, Friends of the ASM Collections funds, 2018
    W: 4.3 cm
    2019-6-2

    About this piece, Na Na Ping notes: "I enjoy using stones from all parts of the world. The sugilite in this bracelet is from Africa. The stone is of gem quality and is known as jelly sugilite. It is very rare and expensive, and a challenge to work with, being so hard (about 7 on the 1–10 Mohs scale)."

    "My influences are both ancient and contemporary. I draw inspiration from the Anasazi [Ancestral Pueblo] stone houses to the ancient pyramids of the Mayans of Mexico. Yaqui origin stories tell of Yaquis coming from the stars to live where they are today. So many of my works reference the stars and planets as well." - Na Na Ping (Michael Garcia)
    `,
    },
    {
      title: `Hallmark for Eye of the Pascola, silver-and-sugilite cuff, c. 2018
      Pascua Yaqui Tribe`,
      src: img6,
      caption: `
      Na Na Ping (Michael Garcia)
        Santa Fe, NM
        ASM purchase, Friends of the ASM Collections funds, 2018
        W: 4.3 cm
        2019-6-2
        `,
    },
    {
      title: `Na Na Ping (Michael Garcia), Yaqui (Yoeme)`,
      src: img7,
      caption: `
      Santa Fe Indian Art Market, Santa Fe, NM
    Susan Hamilton, photographer, 2019

    Well-known jewelry artist Na Na Ping (Michael Garcia) was born in Guadalupe, a Yaqui village south of Phoenix, Arizona. He has lived for many years at Nambe Pueblo, having married into the community. The name Na Na Ping was bestowed on him by one of his wife’s grandfathers.

    Na Na Ping learned lapidary work from his uncles; he is particularly known for spectacular stone inlay jewelry. `,
    },
    {
      title: `Cody stone–and–Fox turquoise inlay belt buckle, 2004
      Yaqui (Yoeme)`,
      src: img8,
      caption: `
    Anthony Garcia (b. 1957)
    Phoenix, AZ
    Silver, Cody stone (silver in quartz), Fox chalcosiderite turquoise
    Vice President for Research Powell Purchase Award
    ASM Southwest Indian Art Fair, 2004
    L: buckle, 7.5 cm; bola, 7 cm (ornament)
    2004-352-1 and -2
    `,
    },
    {
      title: `Cody stone–and–Fox turquoise inlay belt buckle and bola, 2004
       Yaqui (Yoeme)`,
      src: img9,
      caption: `
      Anthony Garcia (b. 1957)
    Phoenix, AZ
    Silver, Cody stone (silver in quartz), Fox chalcosiderite turquoise
    Vice President for Research Powell Purchase Award
    ASM Southwest Indian Art Fair, 2004
    L: buckle, 7.5 cm; bola, 7 cm (ornament)
    2004-352-1 and -2
    `,
    },
    {
      title: `Hallmark for Cody stone–and–Fox turquoise inlay belt buckle and bola, 2004
      Yaqui (Yoeme)`,
      src: img10,
      caption: `
      Anthony Garcia (b. 1957)
    Phoenix, AZ
    Silver, Cody stone (silver in quartz), Fox chalcosiderite turquoise
    Vice President for Research Powell Purchase Award
    ASM Southwest Indian Art Fair, 2004
    L: buckle, 7.5 cm; bola, 7 cm (ornament)
    2004-352-1 and -2
    `,
    },
    {
      title: `Anthony Garcia, Yaqui (Yoeme)`,
      src: img11,
      caption: `
      Southwest Indian Art Fair, Arizona State Museum, Tucson, AZ
    Jannelle Weakly, photographer, 2004

    "I have been influenced by my uncles and my older brother [Na Na Ping]. I am truly inspired by both contemporary and ancient forms of art." - Anthony Garcia

    Anthony Garcia is from the Yaqui community of Guadalupe, Arizona, south of Phoenix, and still lives in the greater Phoenix area. He is on the National Registry of Native American Artists, U.S. Department of the Interior. Garcia learned lapidary and silverwork from his brother, Na Na Ping (Michael Garcia).

    The Cody stone (quartz with silver inclusions) in this jewelry is reportedly from the Santa Catalina Mountains north of Tucson. It was named after showman William “Buffalo Bill” Cody, who had gold mines in the Oracle, Arizona, area around 1900.
    `,
    },
    {
      title: `Silver overlay deer dancer earrings, 2003
      Yaqui (Yoeme)`,
      src: img12,
      caption: `
    Florine Morillo
    Tucson, AZ
    Gift of Mark Bahti, 2004
    L: 4 cm
    2004-1781-6

    Florine Morrillo learned silverwork from O’odham jeweler Rick Manuel.`,
    },
    {
      title: `Hallmark for silver overlay deer dancer earrings, 2003
      Yaqui (Yoeme)`,
      src: img13,
      caption: `
      Florine Morillo
    Tucson, AZ
    Gift of Mark Bahti, 2004
    L: 4 cm
    2004-1781-6
    `,
    },
    {
      title: `Silver overlay heart-and-flower belt buckle, 2007
      Yaqui (Yoeme)`,
      src: img14,
      caption: `
    Florine Morillo
    Tucson, AZ
    ASM purchase, 2007
    L: 6.3 cm
    2007-814-2
    `,
    },
    {
      title: `Hallmark for silver overlay heart-and-flower belt buckle, 2007
       Yaqui (Yoeme)`,
      src: img15,
      caption: `
    Florine Morillo
    Tucson, AZ
    ASM purchase, 2007
    L: 6.3 cm
    2007-814-2`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Yaqui_Silver_Jewelry_Artists;
