import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading3/subheading 3-4/subheading 3-4b/3-4b clickable.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4b/3-4b photobox-1.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4b/3-4b photobox-2-1.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4b/3-4b photobox-2-2.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4b/3-4b photobox-3-1.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4b/3-4b photobox-3-2.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4b/3-4b photobox-4-1.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4b/3-4b photobox-4-2.jpg";
import img9 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-1 hopi overlay/3-4b-1 photobox-08-1.jpg";
import img10 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-2 hopi enterprises hopicrafts/3-4b-2 photobox-6-1.jpg";
import img11 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-3 michael kabotie and artists hopid/3-4b-3 photobox-3-1.jpg";
import img12 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-3 michael kabotie and artists hopid/3-4b-3 photobox-1-1.jpg";

import GalleryComponent from "../../../Components/Gallery/Gallery";
import Preview from "../../../Components/Preview/Preview";

const Hopi_Jewelry = () => {
  const title = "Hopi Jewelry";

  const details1 =
    `Early Hopi jewelry resembled that made by the first Diné (Navajo) and A:shiwi (Zuni) artisans, with its heavy silver and large turquoise stones. Silversmithing did not initially take hold at the same scale among Hopis as it did with the Diné and A:shiwi.

One reason for this is that Hopis were able to obtain jewelry through trade for their ceremonially valuable textiles, baskets, and produce, including corn, peaches, and melons. Also, none of the traders operating at Hopi encouraged the craft by providing the raw materials and marketing in the way that those trading post operators on the Diné (Navajo) reservation did. 

Later, in the early 20th century, Hopi smiths came to adopt the overlay technique as their signature style.

The first Hopi silversmith was Sikyatala (Yellow Light) of the First Mesa village of Sitsom’ovi (Sichomovi). Sometime in the 1890s, he learned from La:niyadhi (Lanyadi), an A:shiwi (Zuni) smith who had in turn been taught by the early Diné (Navajo) silver maker Atsidi Chon. In a 1938 interview, the elderly La:niyadhi stated: `.split(
      "\n"
    );
  const quote =
    `Forty years ago [1898]...I made a trip to the Hopi reservation to make silver. I stayed at First Mesa for four months, living with Sikyatala. None of the Hopi men knew how to make silver at that time, and I didn’t let them watch me at my work, because if they learned how, they would no longer buy silver from me. I sold them the silver jewelry I made for money, and sometimes I would trade it for mantas and sashes. I made many beads, belts and bowguards for them (Adair 1944:173).`.split(
      "\n"
    );
  const details2 =
    `Based on this account, it appears that Sikyatala learned by observing his Zuni friend, as opposed to having been actively tutored. `.split(
      "\n"
    );

  const imageDescription = "";

  const photos = [
    {
      title: `Sikyatala, the first Hopi silversmith`,
      src: img2,
      caption: `
      Hopi Indian Reservation, AZ
Jesse Hastings Bratley, photographer, c. 1902
Denver Museum of Nature and Science, br61-235`,
    },
    {
      title: `Silver cuff bracelet with turquoise stones, c. 1930, Hopi`,
      src: img3,
      caption: `
      Grant Jenkins (1903–1933)
Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-292

This cuff bracelet from the early 1930s by Grant Jenkins is an example of Hopi jewelry that predates the development of the distinct Hopi overlay style.`,
    },
    {
      title: `Hallmark for silver cuff bracelet with turquoise stones, c. 1930, Hopi`,
      src: img4,
      caption: `
      Grant Jenkins (1903–1933)
Hotvela (Hotevilla), Third Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-292`,
    },
    {
      title: `Silver-and-turquoise pendant, 1930s, Hopi`,
      src: img5,
      caption: `
      Ralph Tawangyawma (1894–1972)
Orayvi (Oraibi), Third Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-83`,
    },
    {
      title: `Hallmark for silver-and-turquoise pendant, 1930s, Hopi`,
      src: img6,
      caption: `
Ralph Tawangyawma (1894–1972)
Orayvi (Oraibi), Third Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-83`,
    },
    {
      title: `Stamped silver cuff bracelet, 1930s, Hopi`,
      src: img7,
      caption: `
Ralph Tawangyawma (1894–1972)
Orayvi (Oraibi), Third Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-349`,
    },
    {
      title: `Hallmark for stamped silver cuff bracelet, 1930s, Hopi`,
      src: img8,
      caption: `
      Ralph Tawangyawma (1894–1972)
Orayvi (Oraibi), Third Mesa, Hopi Indian Reservation, AZ
Courtesy Pat and Kim Messier
J-349`,
    },
  ];

  const previews = [
    {
      image: img9,
      url: "/Hopi-Overlay",
      text: "Hopi Overlay",
    },
    {
      image: img10,
      url: "/Hopicrafts",
      text: "Hopicrafts",
    },
    {
      image: img11,
      url: "/Michael-Kabotie-And-Artists-Hopid",
      text: "Michael Kabotie And Artists Hopid",
    },
    {
      image: img12,
      url: "/Gerald-Lomaventema-and-Master-Apprentices",
      text: "Gerald Lomaventema and Master Apprentices",
    },
  ];

  return (
    <Content
      title={title}
      specialRender={
        <>
          {details1.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <br />
          <div className="quote">
            {quote.map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
          </div>
          <br />
          {details2.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </>
      }
      clickableImage={img1}
      imageDescription={imageDescription}
      previews={previews}
      photos={photos}
    />
  );
};

export default Hopi_Jewelry;
