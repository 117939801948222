import React from "react";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-1/subheading 2-1-4/2-1d_photobox-1.jpg";
import img2 from "./heading2/subheading 2-1/subheading 2-1-4/2-1d_photobox-2-1.jpg";
import img3 from "./heading2/subheading 2-1/subheading 2-1-4/2-1d_photobox-2-2.jpg";

const Turquoise_Meets_Silver = () => {
  const title = "Turquoise Meets Silver";

  const details =
    `Shortly after Native Southwesterners learned to work in silver, they began to pair the white metal with turquoise, creating a host of different jewelry forms and styles that continue to evolve.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos_one = [
    {
      title: `Cuff bracelet with large turquoise, c. 1950–1970
      Diné (Navajo), AZ, NM, or UT`,
      src: img1,
      caption: `
          Gift of Mrs. Joan Hatten
          H: 10.8 cm.
          1991-33-1

          The source of the large turquoise stone has been suggested to be either Pilot Mountain Mine or Royston Mine in Nevada, or Kingman Mine in Arizona.`,
    },
    {
      title: `Silver-and-turquoise bola tie, c. 2000
      Tommy Singer (1940–2014), Diné (Navajo)`,
      src: img2,
      caption: `
            Dilkon/Winslow, AZ
            Silver, turquoise
            ASM purchase, 2009
            From the collection of Bob Beaudry
            L: 94 cm
            2009-393-8

            Tommy Singer was a very well-regarded silversmith from the small Navajo Nation town of Dilkon, Arizona. He is probably best known for introducing, in the 1960s, the chip-inlay technique, in which small bits of turquoise or other materials are mixed with epoxy and embedded into channels of silver.

            Singer learned silversmithing at a young age from his father, Tsinnigine Hathali, a Diné (Navajo) medicine man. His silverworking career spanned fifty years. In addition to the chip-inlay style, Singer made fine, substantial overlay jewelry, such as this bola tie with custom tips.`,
    },
    {
      title: `Hallmark for silver-and-turquoise bola tie, c. 2000
      Tommy Singer (1940–2014), Diné (Navajo)`,
      src: img3,
      caption: `
            Dilkon/Winslow, AZ
            Silver, turquoise
            ASM purchase, 2009
            From the collection of Bob Beaudry
            L: 94 cm
            2009-393-8`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img2}
      imageDescription={imageDescription}
      photos={photos_one}
    />
  );
};

export default Turquoise_Meets_Silver;
