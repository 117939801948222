import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-3 michael kabotie and artists hopid/3-4b-3 photobox-1-1.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-3 michael kabotie and artists hopid/3-4b-3 photobox-2-1.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-3 michael kabotie and artists hopid/3-4b-3 photobox-2-2.jpeg";
import img5 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-3 michael kabotie and artists hopid/3-4b-3 photobox-3-1.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-3 michael kabotie and artists hopid/3-4b-3 photobox-3-2.jpeg";
import img7 from "./heading3/subheading 3-4/subheading 3-4b/subheading 3-4b-3 michael kabotie and artists hopid/3-4b-3 potobox-1-2.jpg";
import Content from "../../../Components/Content/Content";

import GalleryComponent from "../../../Components/Gallery/Gallery";

const Michael_Kabotie_And_Artists_Hopid = () => {
  const title = "Michael Kabotie and Artists Hopid";

  const details1 =
    `Fred Kabotie’s son, Michael Kabotie (Lomawywesa), was a multitalented Hopi artist, known for his paintings and murals as well as jewelry. He was born at Songòopavi (Shungopavi), on Second Mesa, where his mother Alice was an accomplished basket weaver. 
 
In 1960, Michael Kabotie attended the first session of the Southwestern Indian Art Project at the University of Arizona. One of the youngest students, he reportedly struggled to maintain focus, finding city distractions more engaging than the curriculum. Kabotie did later speak glowingly about one of his professors, the artist Joe Herrera (Cochiti/San Ildefonso): `.split(
      "\n"
    );
  const quote =
    `So when I was in Tucson at this art project, wow! This was great. They had three movie theaters, double-features, and that’s where I spent a lot of my time, down there....And who enters my life at this point? Well, it was an artist from New Mexico named Joe Herrera….And it was amazing how Joe sort of put us under his wings at that point (Heard Museum 1991:95).`.split(
      "\n"
    );

  const details2 =
    `As a youth, Kabotie had learned the silver overlay technique from members of the Hopi Silvercraft Cooperative Guild. After a time spent concentrating on painting, he returned to silversmithing in the late 1970s. His designs drew heavily from motifs on ancient Hopi kiva murals and pottery. 
 
In the early 1970s, Kabotie—with friends Neil David Sr., Milland Lomakema, Delbridge Honanie, and Terrance Talaswaima—founded the arts cooperative called Artists Hopid. Kabotie died in 2009. 
`.split("\n");

  const imageDescription = "";

  const photos = [
    {
      title: `Student Michael Kabotie, Hopi (center) with Joe Herrera, San Ildefonso/Cochiti (left)`,
      src: img1,
      caption: `
      Southwestern Indian Art Project, Department of Art, University of Arizona, Tucson, AZ
Ray Manley, photographer, 1964
ASM pix-649-x-4`,
    },
    {
      title: `Michael Kabotie with students at a painting workshop`,
      src: img7,
      caption: `
      Arizona State Museum, Tucson, AZ
Jannelle Weakly, photographer, 2003`,
    },
    {
      title: `Silver-and-turquoise overlay pendant, c. 1990
      Hopi`,
      src: img5,
      caption: `
      Michael Kabotie (Lomawywesa; 1942–2009)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Loaned by a Friend of the ASM Collections
L: 39 cm
AT-2021-2-1`,
    },
    {
      title: `Hallmark for silver-and-turquoise overlay pendant, c. 1990
      Hopi`,
      src: img4,
      caption: `
      Michael Kabotie (Lomawywesa; 1942–2009)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Loaned by a Friend of the ASM Collections
L: 39 cm
AT-2021-2-1`,
    },
    {
      title: `Silver-and-coral overlay pendant, c. 1990
      Hopi`,
      src: img3,
      caption: `
      Michael Kabotie (Lomawywesa; 1942–2009)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Gift of Linda Staubitz, 2023
L: 32.5 cm
2023-265-1`,
    },
    {
      title: `Hallmark for silver-and-coral overlay pendant, c. 1990
       Hopi`,
      src: img6,
      caption: `
Michael Kabotie (Lomawywesa; 1942–2009)
Songòopavi (Shungopavi), Second Mesa, Hopi Indian Reservation, AZ
Loaned by a Friend of the ASM Collections
L: 32.5 cm
AT-2021-2-3`,
    },
  ];

  return (
    <Content
      title={title}
      specialRender={
        <>
          {details1.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <br />
          <div className="quote">
            {quote.map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
          </div>
          <br />
          {details2.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </>
      }
      clickableImage={img5}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Michael_Kabotie_And_Artists_Hopid;
