import React from "react";
import Preview from "../../../Components/Preview/Preview";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

// These imports have been generate by running command to get all files in headings folder,
// Then ran it through ChatGPT to produce imports
import img1 from "./heading3/subheading 3-1/3-1_clickable.jpg";
import img24 from "./heading3/subheading 3-3/subheading 3-3-1/subheading 3-3-2/3-3 photobox 1-2.jpg";
import img27 from "./heading3/subheading 3-2/subheading 3-2-3/3-2_photobox-3.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4a/3-4a photobox-04.jpg";
import img29 from "./heading3/heading 3 clickable.jpg";

const title = "Developments 1920s-1950s";

const details =
  `From the 1920s to the 1950s, Hopi, A:shiwi (Zuni), and Diné (Navajo), jewelry styles became  increasingly distinct from one another. This was in part due to efforts by traders, and in part a reflection of different tribal cultural values and traditions, and individual innovations. This shift was reflected to a lesser extent among other Pueblos as well. O'odham and Yaqui jewelry aimed to an outside market developed later in the 20th century.  

Beginning in the 1920s, some artisans began to sign their works, a practice also promoted by traders but in many cases at odds with cultural sensibilities regarding individuals calling attention to themselves. The most frequent method was to stamp names, initials, or designs as unique hallmarks on the backs of the pieces.  

Santa Fe's Southwest Indian Fair (now the Santa Fe Indian Market) and the Gallup Inter-Tribal Indian Ceremonial premiered in 1922, and other regional fairs were to follow. Fairs have increasingly served to promote Southwest Native jewelry to a world audience.`.split(
    "\n"
  );

const previews = [
  {
    image: img1,
    url: "/Hallmarks",
    text: "Hallmarks",
  },
  {
    image: img27,
    url: "/Art-Fairs",
    text: "Art Fairs",
  },
  {
    image: img24,
    url: "/Authenticity-Challenges",
    text: "Authenticity Challenges",
  },
  {
    image: img4,
    url: "/Tribal-Styles",
    text: "Tribal Styles",
  },
];

const HeadingThree = () => {
  return (
    <Content
      title={title}
      details={details}
      clickableImage={img29}
      imageDescription={""}
      previews={previews}
    />
  );
};

export default HeadingThree;
