import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-2 rosaries/3-4g-2 photobox-1.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Rosaries = () => {
  const title = "Rosaries";

  const details =
    `Rosary necklaces, referred to most often as rosarios or kuusim, are made and worn during Lent (the 40-day period before the Christian holy day of Easter Sunday), especially Semana Santa (Holy Week).
 
Straight stems of the kuh kuta bush (Lycium andersonii or Phaulothammus spinescens) are cut and carved into rosary beads. A carved cross hangs in the center of the rosary. The wooden beads and cross are boiled in lard to darken and harden them before stringing. Attached to the cross is a thread or yarn tuft, referred to as a seewa (flower). The seewa ania, flower world, is one of the five sacred worlds of the Yaqui. Rosarios are primarily made by Yaqui men, but both men and women wear them. 
 
Chapayekam, the ceremonial masked participants in Lenten rites, always wear kuusim while wearing their masks. Each of them puts the wooden cross that hangs from the necklace between their teeth to remind them not to speak while wearing their masks and to constantly pray.`.split(
      "\n"
    );

  const imageDescription = `Rosary (rosario, kuusim), c. 1980s 
  Yaqui (Yoeme) 
  José Guadalupe Flores
Born in Cocorít, Sonora, Mexico; lived in Barrio Libre/New Pascua Tucson, AZ 
Wood (Lycium andersonii), thread, string 
Courtesy Tom Kolaz Collection 
L: 45.1 cm 
 
Flores was a deer singer, mask carver, and maker of regalia. `;

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={imageDescription}
    />
  );
};

export default Rosaries;
