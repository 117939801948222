import GalleryComponent from "../../../Components/Gallery/Gallery";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-1.jpg";
import img2 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-2.jpg";
import img3 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-3.jpg";
import img4 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-4.jpg";
import img5 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-5.jpg";
import img6 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-6.jpg";
import img7 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-7.jpg";
import img8 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-8-1.jpg";
import img9 from "./heading2/subheading 2-2/subheading 2-2-2/2-2b_photobox-8-2.jpg";

const Hohokam_Shell = () => {
  const title = "Hohokam Shell";

  const details =
    `The word “Hohokam” is used by archaeologists as a name for the groups who inhabited the desert regions of southern Arizona prior to the arrival of Europeans (c. 500–1450 CE). It is a variation of the O’odham term “Huhugam,” which refers to people, such as relatives or ancestors, who are no longer living.

    Among the Hohokam, shell was a relatively common and widely distributed material. Hohokam craftspeople were major fabricators of shell ornaments, including elaborate pendants, bracelets, and mosaics of stone and shell.

    Particularly in the Phoenix Basin “core” of the Hohokam regional system, most villages and even some small, temporarily occupied sites have yielded shell ornaments. In general, relatively simple artifact forms and fewer genera (plural of genus) are recovered from small habitation sites, while larger settlements often show greater diversity in terms of the forms and genera represented. There is clear evidence for the manufacture of shell artifacts throughout the Hohokam sequence, with larger sites generally having greater amounts of production debris present.

    Researchers distinguish between high-value and low-value shell objects. High-value items were rarer and were often recovered from only a limited set of contexts, such as ritual spaces. Examples include etched shell, trumpets, mosaics, and some specific types of pendants. Low- value forms include bracelets, beads, most geometric pendants, and some effigy pendants. These are found in relatively large numbers, in many sites, and occur in many different contexts.`.split(
      "\n"
    );

  const photos_one = [
    {
      title: `Glycymeris bracelets, C. 500 – 1400 CE
      Hohokam, Tucson Province`,
      src: img1,
      caption: `
      A-14919
        Glycymeris gigantea shell
        Tucson Mountain Cache, Pima Co., AZ
        Gift of Everett Thurston, 1960
        L: 7.2–7.4 cm
        A-14919`,
    },
    {
      title: `Carved bird-and-snake bracelet, C. 700–1000 CE
      Hohokam, Tucson Province`,
      src: img2,
      caption: `
      Glycymeris gigantea shell
      Hodges Ruin, Santa Cruz River Valley, Tucson, Pima Co., AZ
      Gift of Mr. and Mrs. Wetmore Hodges, 1944
      L: 5.5 cm
      A-7305`,
    },
    {
      title: `Human-effigy “shellman” pendant, C. 900–1000 CE
      Hohokam, Tucson Province`,
      src: img3,
      caption: `
        Haliotis rufescens (abalone)
        Shellman Site, Santa Cruz River Valley, Pima Co., AZ
        Gift of Robson Homes, 2005
        L: 7.5 cm
        2005-498-3`,
    },
    {
      title: `Frog-effigy pendant, C. 850–1150 CE
      Hohokam, Tucson Province`,
      src: img4,
      caption: `
        Glycymeris shell
        Hodges Ruin, Santa Cruz River Valley, Tucson, Pima Co., AZ
        Gift of Mr. and Mrs. Wetmore Hodges, 1944
        L: 3.7 cm
        A-7311`,
    },
    {
      title: `Barrel-shaped shell-bead necklaces, C. 1150–1450 CE
      Hohokam, Salt-Gila Province`,
      src: img5,
      caption: `
        Olivella shell
        La Ciudad, Salt River Valley, Phoenix, Maricopa Co., AZ
        Gift of Gila Pueblo Foundation, 1950
        L: 44.0 cm (shortest)
        GP-9878-x-1`,
    },
    {
      title: `Abstract lizard-effigy pendants likely used as earrings, C. 1150–1300 CE
      Hohokam, Salt-Gila Province`,
      src: img6,
      caption: `
        Laevicardium elatum shell
        Las Colinas, Salt River Valley, Phoenix, Maricopa Co., AZ
        ASM Highway Salvage Excavation, 1968
        L: 11.6 cm (longest)
        A-37063-X-1, A-37063-X-2`,
    },
    {
      title: `Frog-effigy mosaic, C. 1200–1450 CE
      Hohokam, Gila Bend Province`,
      src: img7,
      caption: `
        Turquoise, argillite (also called pipestone or claystone), Glycymeris gigantea shell
        Homestead Site, Gila River Valley, Maricopa Co., AZ
        Gift of Norton and Ethel Allen, 1997
        L: 7.4 cm
        1997-194-15`,
    },
    {
      title: `Etched-shell necklace, 2020
      Tim Terry Jr., Akimel O’odham Gila River Indian Community, AZ`,
      src: img8,
      caption: `
        Chocolate pismo clam (Megapitaria squalida) and dosinia (Dosinia ponderosa) clam shells from
        Mexico, cowrie shell (Cypraea sp.), turquoise, glass beads
        ASM purchase, Friends of the ASM Collections funds, 2021
        L: 46.5 cm; W: 8.3 cm (pendant)
        2021-152-1

        "I create jewelry, both traditional and contemporary, incorporating a rich history of historic techniques which I preserve via my work." - Tim Terry Jr. (Akimel O’odham), shell-jewelry maker

        Today, several O’odham craftspeople have reintroduced shell etching, a Hohokam technique developed more than 1,000 years ago. Among them is Tim Terry Jr. a traditional artist, presenter, storyteller, and singer from Hashan Kehk (Cactus Standing), a village in District 2 of the Gila River Indian Community. The etched design on this shell pendant is based on Hohokam pottery motifs.

        Etching involves painting a design in pitch (mesquite or creosote) onto the surface of a shell. When the shell is immersed in an acidic solution, the pitch acts as a resist (a substance that prevents a chemical reaction from taking place). In the past, the acidic solution was probably saguaro-wine vinegar. The acidic solution eats away the portions of the shell’s surface that are not protected by the resist. After the shell is taken out of the acid and the pitch is removed, the protected design remains, looking as if it had been created by carving.`,
    },
    {
      title: `Etched-and-painted shell, C. 950–1150 CE
      Hohokam, Tucson Province`,
      src: img9,
      caption: `
        Laevicardium elatum shell, hematite (red) and chrysocolla (green) paint
        Unknown site near the base of the Tucson Mountains, Santa Cruz River Valley, Pima Co., AZ
        Gift of Lloyd G. Lewis, mid-1930s
        L: 12 cm
        6550
        
        A resist, which protected part of this shell’s surface when it was submerged in an acidic solution, created the pattern that a Hohokam artisan later covered with red and green paint.`,
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img2}
      imageDescription={""}
      photos={photos_one}
    />
  );
};

export default Hohokam_Shell;
