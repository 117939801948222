import React from "react";
import Preview from "../../../Components/Preview/Preview";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";

// These imports have been generate by running command to get all files in headings folder,
// Then ran it through ChatGPT to produce imports
import img23 from "./heading4/subheading 4-1/subheading 4-1-5-1/4-1_photobox-5-1.jpg";
import img51 from "./heading4/subheading 4-2/4-2_photobox-1-1.jpg";
import img64 from "./heading4/subheading 4-5/4-5_photobox-1-1.jpg";
import img80 from "./heading4/subheading 4-4/4-4_photobox-1-1.jpg";
import img98 from "./heading4/subheading 4-3/4-3_photobox-1-1.jpg";
import img104 from "./heading4/heading 4 clickable.jpg";
import img107 from "./heading4/4-6_photobox-1-1_preview.jpg";
import Content from "../../../Components/Content/Content";

const details =
  `The mid-twentieth century’s modernism trend in fine and decorative arts spilled over into Native American jewelry design. Artists such as Charles Loloma, Kenneth Begay and Preston Monongye were among those who helped to expand the notion of what Southwest Native jewelry might look like.

The Southwestern Indian Art Project, a summer program for Native students held at the University of Arizona from 1960 to 1962, was a proving ground for innovations in Native arts, including jewelry.`.split(
    "\n"
  );

const previews = [
  {
    image: img23,
    url: "/Southwestern-Indian-Art-Project",
    text: "Southwestern Indian Art Project",
  },
  {
    image: img51,
    url: "/Charles-Loloma",
    text: "Charles Loloma",
  },
  {
    image: img98,
    url: "/White-Hogan-Kenneth-Begay-and-Others",
    text: "White Hogan: Kenneth Begay and Others",
  },
  {
    image: img80,
    url: "/The-Patania-Family-and-the-Thunderbird-Shop-in-Santa-Fe-and-Tucson",
    text: "The Patania Family and the Thunderbird Shop in Santa Fe and Tucson",
  },
  {
    image: img64,
    url: "/Preston-Monongye",
    text: "Preston Monongye",
  },
  {
    image: img107,
    url: "/Thomas-Burnsides",
    text: "Thomas Burnsides",
  },
];

const HeadingFour = () => {
  return (
    <Content
      title={"Early Modernists"}
      details={details}
      previews={previews}
      clickableImage={img104}
      imageDescription={""}
    />
  );
};

export default HeadingFour;
