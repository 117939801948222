import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBIcon,
} from "mdb-react-ui-kit";

import logo from "../Navbar/nav-images/AZ-State-Museum-Logo_PRIMARY_REVERSE.png";

function Footer(props) {
  return (
    <MDBFooter
      color="white"
      style={{ backgroundColor: "#243d3d" }}
      className="text-center">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a
            href="https://www.facebook.com/ArizonaStateMuseum"
            className="me-4 text-reset">
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a
            href="https://twitter.com/azstatemuseum"
            className="me-4 text-reset">
            <MDBIcon fab icon="twitter" />
          </a>

          <a
            href="https://youtube.com/azstatemuseum"
            className="me-4 text-reset">
            <MDBIcon fab icon="youtube" />
          </a>

          <a
            href="https://instagram.com/arizonastatemuseum"
            className="text-reset">
            <MDBIcon fab icon="instagram" />
          </a>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <a href="/">
                <img src={logo} alt="logo" style={{ width: "100%" }} />
              </a>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{ color: "#fff", textAlign: "left" }}>
                Links
              </h6>
              <p style={{ color: "#fff", textAlign: "left" }}>
                <MDBIcon fas icon="external-link-alt" className="me-3" />
                <a
                  href="https://statemuseum.arizona.edu"
                  className="text-reset">
                  Arizona State Museum Website
                </a>
              </p>
              <p style={{ color: "#fff", textAlign: "left" }}>
                <MDBIcon far icon="newspaper" className="me-3" />
                <a
                  href="https://statemuseum.arizona.edu/newsletter-sign"
                  className="text-reset">
                  Newsletter Sign Up
                </a>
              </p>

              <p style={{ color: "#fff", textAlign: "left" }}>
                <MDBIcon far icon="envelope" className="me-3" />
                <a href="mailto: dfl@arizona.edu" className="text-reset">
                  Webmaster
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{ color: "#fff", textAlign: "left" }}>
                Address
              </h6>
              <p
                style={{
                  color: "#fff",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "row",
                }}>
                <MDBIcon icon="home" className="me-3" />
                <p
                  style={{
                    color: "#fff",
                    textAlign: "left",
                  }}>
                  The University of Arizona
                  <br />
                  1013 E University Blvd
                  <br /> PO Box 210026
                  <br /> Tucson, Arizona 85721-0026
                </p>
              </p>

              <p style={{ color: "#fff", textAlign: "left" }}>
                <MDBIcon icon="phone" className="me-3" /> (520) 621-6302
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
        © 2023 Copyright:{" "}
        <a className="text-reset" href="https://arizona.edu/">
          The Arizona Board of Regents on behalf of The University of Arizona
        </a>
      </div>
    </MDBFooter>
  );
}

export default Footer;
