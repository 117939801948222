import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-01-1.jpg";
import img2 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-01-2.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-01-3.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-02.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-03-1.jpg";
import img6 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-03-2.jpg";
import img7 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-03-3.jpg";
import img8 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-04.jpg";
import img9 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-05-1.jpg";
import img10 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-05-2.jpg";
import img11 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-06.jpg";
import img12 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-07-1.jpg";
import img13 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-07-2.jpg";
import img14 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-08.jpg";
import img15 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-09.jpg";
import img16 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-10-1.jpg";
import img17 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-10-2.jpg";
import img18 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-11.jpg";
import img19 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-12.jpg";
import img20 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-14.jpg";
import img21 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-15-1.jpg";
import img22 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-15-2.jpg";
import img23 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-16.jpg";
import img24 from "./heading3/subheading 3-4/subheading 3-4c/3-4c_photobox-13.jpg";
import img25 from "./heading3/subheading 3-4/subheading 3-4c/3-4c photobox-17.jpg";
import NoImage from "./heading3/No_image_available.png";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Ashiwi_Zuni_Jewelry = () => {
  const title = "Ashiwi Zuni Jewelry";

  const details =
    `A:shiwi jewelry artisans are best known today for both their silver-and-turquoise cluster work, in which large numbers of matching stones are arranged in rosettes or rows, and their mosaic inlay work, which employs multiple stone and shell varieties to render cultural icons and countless other images. These techniques, although incorporating new materials, such as silver, harken back to ancient mosaics, with their ordered rows of turquoise, shell, jet, and other materials that possess deep symbolic value.

The A:shiwi have a long tradition of making stone and shell beads, which they shared with their Rio Grande Pueblo trading partners from neighboring Kewa (Santo Domingo) Pueblo. Photographs taken by anthropologists in the 1880s document the tedious process of perforating individual beads with a pump drill. By this time, metal drill points had already replaced hard stone points, facilitating the process to some degree. 

Historic accounts provide evidence that the A:shiwi were working metal by the early 1800s, cutting crosses and other shapes from brass and copper pans obtained through trade. 

The silver conchas (disk-shaped or oval ornaments used to decorate belts), buttons, bracelets, and other jewelry that A:shiwi artisans first manufactured did not differ from those made by the Diné. Turquoise settings appeared in the 1890s, marking a transition to forms in which silver increasingly played a supporting role and stones and shell predominated.

Zuni silversmith Keneshde recalled traveling to Kewa (Santo Domingo) Pueblo to get turquoise for the first time: 
`.split("\n");

  const quote =
    `I brought this turquoise back to Zuñi. It was at that time [c. 1890] that I thought that turquoise would look nice on the silver. So I took four pieces of the stone, which I had polished, and I put them on the silver...I sold this bracelet for ten dollars cash to a Navajo that lived near Lupton `.split(
      "\n"
    );

  const imageDescription = "";

  const photos = [
    {
      title: `La:niyadhe, A:shiwi (Zuni)`,
      src: img1,
      caption: `
      Adair Collection, c. 1940
Courtesy Wheelwright Museum of the American Indian, Santa Fe, NM      

The first A:shiwi (Zuni) silversmith, La:niyadhe, learned from the Navajo smith Atsidi Chon around 1872, not long after local Mexican smiths had taught Chon silverworking techniques. La:niyadhe recalled in 1938: "When I was a young man about thirty years old [1872], a Navajo came to Zuñi who knew how to make silver. This man’s Navajo name was Atsidi Chon....We became good friends, this man and I, and he came over to my house and lived...so he let me watch him make silver...[I] told him that I would give him a good horse if he would teach me how to work with the silver." 

(Adair 1944:122).`,
    },
    {
      title: `Shop of Silversmith
      Plate XCV in Stevenson (1904)`,
      src: img2,
      caption: ``,
    },
    {
      title: `Bead Making
Plate CXVIa in Stevenson (1904)`,
      src: img3,
      caption: ``,
    },
    {
      title: `Della Casi (1887–1978), A:shiwi (Zuni) silversmith, NM`,
      src: img4,
      caption: `
      Gallup
Burton Frasher, photographer, 1932
Frashers Fotos and Pomona Public Library, A5012

Della Casi was the first A:shiwi woman documented as an independent silversmith, beginning in the mid-1920s. Previously, women had worked alongside their husbands, doing the more- detailed work, such as stone setting.

For decades, A:shiwi smiths have had at their disposal the full range of lapidary and silverwork tools as well as the ability to obtain a wide range of raw materials, through traders as well as via direct purchases at gem shows and outlets.`,
    },
    {
      title: `Phyllis Laate, A:shiwi (Zuni)`,
      src: img5,
      caption: `
      Uses an electric motor to grind stones into shape, Pueblo of Zuni, NM
Helga Teiwes, photographer, 1977
ASM 46979

In the early twentieth century, finer lapidary work became technically possible with the introduction of tools such as grinding wheels, wax, and lapidary sticks, which were adhered to small pieces of raw material to facilitate their shaping.`,
    },
    {
      title: `Cut turquoise adhered to sticks before grinding
      Pueblo of Zuni, NM`,
      src: img6,
      caption: `
      Helga Teiwes, photographer, 1977
ASM 46982`,
    },
    {
      title: `Odell Benketewa, A:shiwi (Zuni)`,
      src: img7,
      caption: `
      Sets stones into silver, Pueblo of Zuni, NM
Helga Teiwes, photographer, 1977
ASM 46989`,
    },
    {
      title: `Turquoise-and-silver earrings with wire loops, c. 1920
      A:shiwi (Zuni) or Diné (Navajo),`,
      src: img8,
      caption: `
      AZ, NM, or UT
Gift of Mary Cabot Wheelwright, 1942
L: 2.9 cm
E-1247, E-1248

These earrings have settings of turquoise tab pendants. The donor, who in 1937 founded the Museum of Navajo Ceremonial Art (now the Wheelwright Museum of the American Indian) in Santa Fe, New Mexico, identified this pair as Diné. The wire pendant loops, however, are more typical of A:shiwi earrings of that period.`,
    },
    {
      title: `Bracelet with Lone Mountain turquoise, late 1930s
      A:shiwi (Zuni)`,
      src: img9,
      caption: `
      Juan De Dios (1882–1944)
US Zuni 1 (trader C. S. Wallace’s stamp)
Courtesy Pat and Kim Messier
J-198

The popularity of Native jewelry in the 1930s led some companies to mass-produce jewelry using machines that sped up the process. These companies did not always employ Native Americans. Considering this unfair competition, the United Indian Traders Association (UITA), founded in 1931, attempted to address this issue by doing battle over the definition of “Indian- made jewelry.” In 1946, the UITA started a program of stamping jewelry with the maker’s tribal affiliation and a unique code for the trading post from which the jewelry originated. Trader C. S. Wallace, for example, received the code US Zuni 1.`,
    },
    {
      title: `Trader stamp on bracelet with Lone Mountain turquoise, late 1930s
      A:shiwi (Zuni)`,
      src: img10,
      caption: `
      Juan De Dios (1882–1944)
US Zuni 1 (trader C. S. Wallace’s stamp)
Courtesy Pat and Kim Messier
J-198`,
    },
    {
      title: `Fetish necklace, 1995
      A:shiwi (Zuni)`,
      src: img11,
      caption: `
      Julie Garcia
Pueblo of Zuni, NM
Turquoise, jet, jasper, serpentine, shell
Gift of Grace Niemcziek, 2000
L: 73 cm
2000-91-57`,
    },
    {
      title: `Inlay pin depicting a bird, c. 1970
      A:shiwi (Zuni)`,
      src: img12,
      caption: `
      Dennis Edaakie (1931–2008)
Pueblo of Zuni, NM
Silver, abalone shell, coral, tortoiseshell
Gift of Clyde Stone, 2004
D: 5.75 cm
2004-1069-1

Dennis Edaakie was known for his intricate inlay jewelry. Among his specialties were birds, which he rendered in great detail, employing a graphic style reminiscent of the work of nineteenth-century American ornithologist John James Audubon`,
    },
    {
      title: `Hallmark for inlay pin depicting a bird, c. 1970
       A:shiwi (Zuni)`,
      src: img13,
      caption: `
      Dennis Edaakie (1931–2008)
Pueblo of Zuni, NM
Silver, abalone shell, coral, tortoiseshell
Gift of Clyde Stone, 2004
D: 5.75 cm
2004-1069-1`,
    },
    {
      title: `Inlay bola tie depicting a bird, c. 1980
      A:shiwi (Zuni)`,
      src: img14,
      caption: `
      Pueblo of Zuni, NM
Silver, turquoise
ASM purchase, Friends of the ASM Collections funds
From the collection of Bob Beaudry
H: 11.4 cm (ornament)
2009-393-2`,
    },
    {
      title: `Inlay bola tie depicting a horse, c. 1980
      A:shiwi (Zuni)`,
      src: img15,
      caption: `
      Pueblo of Zuni, NM
Silver, jet, turquoise, shell
ASM purchase, Friends of the ASM Collections funds
From the collection of Bob Beaudry
H: 6 cm (ornament)
2009-393-5`,
    },
    {
      title: `Inlay cartoon characters, c. 1970–1980
      A:shiwi (Zuni)`,
      src: img16,
      caption: `
      Pueblo of Zuni, NM
Gift of Rieka Long, 2011
From the collection of Florence Hawley Ellis

Jiminy Cricket ring
Shell, jet, turquoise, silver
H: 4.8 cm
2011-421-3

Snoopy ring
Shell, jet, turquoise, silver
H: 3.7 cm
2011-421-4

Otto (from the Beetle Bailey comic strip) ring
Shell, silver
H: 4.2 cm
2011-421-6

A:shiwi jewelry makers have created representations of pop-culture icons, including cartoon characters, for at least 50 years. These are examples that ASM received as a gift from Rieka Long, granddaughter of anthropologist Florence Hawley Ellis (1906–1991), of Albuquerque, New Mexico. Some, if not all, of them came to Ellis through anthropologist Ted Frisbee, who researched and wrote extensively about A:shiwi culture.`,
    },
    {
      title: `Minnie Mouse bracelet, c. 1970–1980
      A:shiwi (Zuni) (or possibly Diné/Navajo)`,
      src: img17,
      caption: `
      Gift of Rieka Long, 2011
      From the collection of Florence Hawley Ellis
      Shell, turquoise, coral, jet, silver
H: 5.2 cm
2011-421-7`,
    },
    {
      title: `Petit point silver-and-turquoise squash blossom necklace and earrings, c. 1940
      A:shiwi (Zuni)`,
      src: img18,
      caption: `
      Pueblo of Zuni, NM
Gift of anonymous donors, 1963
Necklace L: 33.5 cm; earrings D: 2.0 cm
E-5480, E-5481`,
    },
    {
      title: `Turquoise-and-shell inlay rainbow guardian necklace, 1953
       A:shiwi (Zuni)`,
      src: img19,
      caption: `
      Theodore Edaakie
Pueblo of Zuni, NM
Gift of Mrs. William P. Van Eps, 1966
L: 39.5 cm
E-6541

This necklace was a first prize winner at the 1953 Gallup Inter-Tribal Indian Ceremonial. Rainbow Man is associated with summer rains and the beautiful colors of the heavens (Ostler et al. 1996:88).`,
    },
    {
      title: `Turquoise-and-silver squash blossom necklace, c. 1925
      A:shiwi (Zuni)`,
      src: img24,
      caption: `
      Turquoise-and-silver squash blossom necklace, c. 1925
    A:shiwi (Zuni)
    Pueblo of Zuni, NM
    Blue Gem turquoise, silver
    Pierre Lecomte du Noüy Collection
    Gift of Mrs. Lecomte du Noüy, 1968
    L:29.5 cm`,
    },
    {
      title: `Knife-Wing pin with shell-and-stone inlay, c. 1925
      A:shiwi (Zuni)`,
      src: img20,
      caption: `
      Pueblo of Zuni, NM
Silver, turquoise, Spondylus shell, jet
Pierre Lecomte du Noüy Collection
Gift of Mrs. Lecomte du Noüy, 1968
W: 10.5 cm
E-7443`,
    },
    {
      title: `Knife-Wing pin with shell-and-stone inlay, c. 1925
      A:shiwi (Zuni)`,
      src: img21,
      caption: `
      Pueblo of Zuni, NM
Silver, turquoise, Spondylus shell, jet
Pierre Lecomte du Noüy Collection
Gift of Mrs. Lecomte du Noüy, 1968
H: 9.1 cm
E-7433

Knife-Wing (Achiyalatopa), a supernatural being with wing and tail “feathers” made of stone blades, is associated with the sky, war, and fertility.`,
    },
    {
      title: `Image of a shield, used by the A:shiwi Priesthood of the Bow, depicting Knife-Wing
Plate X in Cushing (1883)`,
      src: img22,
      caption: ``,
    },
    {
      title: `Silver channel inlay bracelet with turquoise, Spondylus shell, and jet, c. 1950
      A:shiwi (Zuni)`,
      src: img23,
      caption: `
      Pueblo of Zuni, NM
Gift of anonymous Tucsonan, 1970
W: 6.44 cm
E-9369`,
    },
    {
      title: `Five-piece silver, tortoise shell, and inlaid turquoise jewelry set, c. 1970`,
      src: img25,
      caption: `
      Necklace, cuff bracelet, earrings, ring, and stick pin
      Attributed to Donna Peywa, A:shiwi (Zuni)
Pueblo of Zuni, NM
Gift of Lawrence Dickey in memory of his wife Randee Mur Dickey, 2023
L: 38.5 cm (necklace)
2023-8-1 through -5`,
    },
  ];

  return (
    <Content
      title={title}
      specialRender={
        <>
          {details.map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
          <div className="quote">
            {quote.map((line, i) => (
              <span key={i}>{line}</span>
            ))}
          </div>
          <br />
          Adair 1944:129–130.
        </>
      }
      clickableImage={img19}
      imageDescription={imageDescription}
      photos={photos}
    />
  );
};

export default Ashiwi_Zuni_Jewelry;
