import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./BackButton.css";

const navigation = [
  {
    name: "Homepage",
    path: "/",
    children: [
      {
        name: "Ancient Modern",
        path: "/Wearing-Jewelry",
      },
      {
        name: "Materials",
        path: "/Materials",
        children: [
          {
            name: "Animal Forms",
            path: "/Animal-Forms",
          },
          {
            name: "Metals",
            path: "/Metals",
            children: [
              {
                name: "Silver",
                path: "/Silver",
              },
              {
                name: "Gold",
                path: "/Gold",
              },
              {
                name: "Copper and Brass",
                path: "/Copper-and-Brass",
              },
            ],
          },
          {
            name: "Stone",
            path: "/Stone",
          },
          {
            name: "Turquoise",
            path: "/Turquoise",
            children: [
              {
                name: "Stabilized Turquoise",
                path: "/Stabilized-Turquoise",
              },
              {
                name: "Turquoise Beads Pendants",
                path: "/Turquoise-Beads-Pendants",
              },
              {
                name: "Turquoise Mosaics",
                path: "/Turquoise-Mosaics",
              },
              {
                name: "Turquoise Substitutes",
                path: "/Turquoise-Substitutes",
              },
              {
                name: "Sources of Turquoise",
                path: "/Sources-of-Turquoise",
              },
              {
                name: "Meaning of Turquoise",
                path: "/Meaning-of-Turquoise",
              },
              {
                name: "Turquoise Meets Silver",
                path: "/Turquoise-Meets-Silver",
              },
            ],
          },
          {
            name: "Shell and Coral",
            path: "/Shell-and-Coral",
            children: [
              {
                name: "Ancient Shell",
                path: "/Ancient-Shell",
              },
              {
                name: "Ancestral Pueblo Shell",
                path: "/Ancestral-Pueblo-Shell",
              },
              {
                name: "Coral",
                path: "/Coral",
              },
              {
                name: "Paquime Shell",
                path: "/Paquime-Shell",
              },
              {
                name: "Mogollon Shell",
                path: "/Mogollon-Shell",
              },

              {
                name: "Hohokam Shell",
                path: "/Hohokam-Shell",
              },

              {
                name: "Spondylus Shell",
                path: "/Spondylus-Shell",
              },
            ],
          },
          {
            name: "Technological Changes",
            path: "/Technological-Changes",
          },
        ],
      },

      {
        name: "Developments 1920s-1950s",
        path: "/Developments-1920s-1950s",
        children: [
          {
            name: "Hallmarks",
            path: "/Hallmarks",
          },
          {
            name: "Authenticity Challenges",
            path: "/Authenticity-Challenges",
          },
          {
            name: "Art Fairs",
            path: "/Art-Fairs",
          },
          {
            name: "Tribal Styles",
            path: "/Tribal-Styles",
            children: [
              {
                name: "Kewa Santo Domingo Jewelry",
                path: "/Kewa-Santo-Domingo-Jewelry",
              },
              {
                name: "Hopi Jewelry",
                path: "/Hopi-Jewelry",
                children: [
                  {
                    name: "Michael Kabotie And Artists Hopid",
                    path: "/Michael-Kabotie-And-Artists-Hopid",
                  },
                  {
                    name: "Hopi Overlay",
                    path: "/Hopi-Overlay",
                  },
                  {
                    name: "Hopicrafts",
                    path: "/Hopicrafts",
                  },
                  {
                    name: "Gerald Lomaventema and Master Apprentices",
                    path: "/Gerald-Lomaventema-and-Master-Apprentices",
                  },
                ],
              },
              {
                name: "Ashiwi Zuni Jewelry",
                path: "/Ashiwi-Zuni-Jewelry",
              },
              {
                name: "Cross Dragonfly Jewelry",
                path: "/Cross-Dragonfly-Jewelry",
              },
              {
                name: "Dine Navajo Jewelry",
                path: "/Dine-Navajo-Jewelry",
              },
              {
                name: "Yaqui Yoeme Jewelry",
                path: "/Yaqui-Yoeme-Jewelry",
                children: [
                  {
                    name: "Yaqui Yoeme Necklaces",
                    path: "/Yaqui-Yoeme-Necklaces",
                  },

                  {
                    name: "Beaded Jewelry For Sale",
                    path: "/Beaded-Jewelry-For-Sale",
                  },
                  {
                    name: "Rosaries",
                    path: "/Rosaries",
                  },
                  {
                    name: "Yaqui Silver Jewelry Artists",
                    path: "/Yaqui-Silver-Jewelry-Artists",
                  },
                ],
              },
              {
                name: "Oodham Jewelry",
                path: "/Oodham-Jewelry",
              },
            ],
          },
        ],
      },
      {
        name: "Early Modernists",
        path: "/Early-Modernists",
        children: [
          {
            name: "Preston Monongye",
            path: "/Preston-Monongye",
          },
          {
            name: "White Hogan Kenneth Begay and Others",
            path: "/White-Hogan-Kenneth-Begay-and-Others",
          },
          {
            name: "Southwestern Indian Art Project",
            path: "/Southwestern-Indian-Art-Project",
          },
          {
            name: "Thomas Burnsides",
            path: "/Thomas-Burnsides",
          },
          {
            name: "Charles Loloma",
            path: "/Charles-Loloma",
          },
          {
            name: "The Patania Family and the Thunderbird Shop in Santa Fe and Tucson",
            path: "/The-Patania-Family-and-the-Thunderbird-Shop-in-Santa-Fe-and-Tucson",
          },
        ],
      },
      {
        name: "Modern Southwestern Jewelry",
        path: "/Modern-Southwestern-Jewelry",
      },
    ],
  },
];

const findParents = (navigation, currentPath, parents = []) => {
  for (let item of navigation) {
    if (item.path === currentPath) {
      return parents; // Return the parents stack
    }
    // If this item has children, go deeper while keeping track of current item in the parents stack
    if (item.children) {
      let found = findParents(item.children, currentPath, [...parents, item]);
      if (found) return found;
    }
  }
  return null;
};

export default function BackButton() {
  const location = useLocation();
  const parents = findParents(navigation, location.pathname);

  if (!parents || parents.length === 0) {
    return null;
  }

  if (parents.length === 1) {
    return (
      <Link to={parents[0].path} className="back-button-container">
        <ChevronLeftIcon />
      </Link>
    );
  }

  return (
    <div className="back-button-container">
      <ChevronLeftIcon htmlColor="white" />
      <div className="dropdown">
        {parents.reverse().map((parent, i) => (
          <Link key={i} to={parent.path} className="dropdown-item">
            Back to {parent.name}
          </Link>
        ))}
      </div>
    </div>
  );
}
