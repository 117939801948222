import React from "react";
import Preview from "../../../Components/Preview/Preview";
import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import Content from "../../../Components/Content/Content";

import img1 from "./heading2/subheading 2-6/subheading 2-6-1/2-6a_photobox-3.jpg";
import img2 from "./heading2/subheading 2-6/subheading 2-6-2/2-6b_photobox-1-1.jpg";
import img3 from "./heading2/subheading 2-6/subheading 2-6-3/2-6c_photobox-1-1.jpg";
import img4 from "./heading2/subheading 2-6/2-6_clickable.jpg";

const Metals = () => {
  const title = "Metals";

  const details =
    `In the Western Hemisphere, metalworking, particularly casting metal, developed first among the cultures of western South America. The understanding of the processes, particularly the ability to create lost-wax castings (cire perdue) spread north along the west coast of Central America as a result of cultural contact, possibly linked to the trade in Spondylus shell.

    The only worked metal found in the ancient U.S. Southwest and Northern Mexico consists of copper bells imported from farther south, most likely from the area known as West Mexico, comprising the modern states of Nayarit, Jalisco, Colima, Michoacan, and Guerrero. Historic accounts indicate that Pueblo people were working metal by the early 1800s, cutting crosses and other shapes from brass and copper pans obtained through trade.
    
    When Diné (Navajo) artisans learned silversmithing in the mid-nineteenth century, a Native American jewelry style was born. The region’s Native jewelers have since added other metals to their repertoire, including gold, platinum, aluminum, and steel, and have mastered techniques appropriate for these new materials.
`.split("\n");

  const imageDescription = "";

  const previews = [
    {
      image: img1,
      url: "/Silver",
      text: "Silver",
    },
    {
      image: img2,
      url: "/Copper-and-Brass",
      text: "Copper and Brass",
    },
    {
      image: img3,
      url: "/Gold",
      text: "Gold",
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img4}
      imageDescription={imageDescription}
      previews={previews}
    />
  );
};

export default Metals;
