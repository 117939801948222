import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4g/3-4g clickable.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import img2 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-1 traditional yaqui yoeme necklaces/3-4g-1 photobox-2-1.jpg";
import img3 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-2 rosaries/3-4g-2 photobox-1.jpg";
import img4 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-3 jewelry for sale/3-4g-3 photobox-1.jpg";
import img5 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-4 yaqui silver jewelry artists/3-4g-4 photobox-1-1.jpg";

import Preview from "../../../Components/Preview/Preview";
import Content from "../../../Components/Content/Content";

const Yaqui_Yoeme_Jewelry = () => {
  const title = "Yaqui Yoeme Jewelry";

  const details =
    `The traditional jewelry of Yaqui/Yoeme (two tribal names used by different individuals and communities) is made of shell, and beads of glass, wood, increasingly, plastic. Necklaces are worn primarily in ceremonial contexts.   In the mid-late 20th century, several Yaqui artisans in the U.S. began to work in silver, arriving on this creative path through different routes.`.split(
      "\n"
    );

  const imageDescription = "";

  const photos = [];

  const previews = [
    {
      url: "/Yaqui-Yoeme-Necklaces",
      image: img2,
      text: "Yaqui Yoeme Necklaces",
    },
    {
      url: "/Rosaries",
      image: img3,
      text: "Rosaries",
    },
    {
      url: "/Beaded-Jewelry-For-Sale",
      image: img4,
      text: "Beaded Jewelry For Sale",
    },
    {
      url: "/Yaqui-Silver-Jewelry-Artists",
      image: img5,
      text: "Yaqui Silver Jewelry Artists",
    },
  ];

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={imageDescription}
      previews={previews}
    />
  );
};

export default Yaqui_Yoeme_Jewelry;
