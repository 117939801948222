import ClickableImage from "../../../Components/ClickableImage/ClickableImage";
import img1 from "./heading3/subheading 3-4/subheading 3-4g/subheading 3-4g-3 jewelry for sale/3-4g-3 photobox-1.jpg";
import GalleryComponent from "../../../Components/Gallery/Gallery";
import Content from "../../../Components/Content/Content";

const Beaded_Jewelry_For_Sale = () => {
  const title = "Beaded Jewelry For Sale";

  const details =
    `Increasingly, over the last 20 or more years, various forms of jewelry have been offered for sale by Yaqui men and women at pahkom (spiritual/religious ceremonies), craft fairs, and Native art stores, as well as on websites.

In the early 1990s, at a fiesta at Old Pascua, a Yaqui woman displayed various glass-bead items she had made for sale. Among them were small, beaded figures of deer dancers attached to metal earring hooks, which she sold in pairs. She also made deer dancer brooches (see image no. 9). 
 
More recently, during Lent and Holy Week (Bwe Taewaim, meaning Big Days), several craftspeople sell jewelry ranging from Yaqui flag-beaded necklaces to other items associated with Yaqui identity and culture. 
`.split("\n");

  const imageDescription = `Beaded deer dancer earrings, early 1990s
      Yaqui (Yoeme)
      Old Pascua, Tucson, AZ 
Glass beads, metal findings 
Barrio Libre/New Pascua 
L: 4.3 cm`;

  return (
    <Content
      title={title}
      details={details}
      clickableImage={img1}
      imageDescription={imageDescription}
    />
  );
};

export default Beaded_Jewelry_For_Sale;
